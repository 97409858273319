import useSWR from "swr";
import axios, { api } from "../lib/axios";
import { useEffect } from "react";
import { useRouter } from "next/router";
import { deleteCookie, setCookie } from "cookies-next";
import { signOut } from "@/components/Login/FirebaseAuth";

const makeAuthHook = (authApiPath: string) => {
  return ({ middleware, redirectIfAuthenticated }: { middleware?: string; redirectIfAuthenticated?: string } = {}) => {
    const router = useRouter();

    const { data: user, error, mutate } = useSWR("/api/user", () => api.getUser().then((res) => res.data));

    const login = async ({ ...props }) => {
      return axios.post(authApiPath, props).then((res) => {
        setCookie("_token", res.data.data.token);
        localStorage.setItem("_token", res.data.data.token);
        mutate();
      });
    };

    const logout = async () => {
      if (!error) {
        localStorage.clear();
        sessionStorage.clear();
        deleteCookie("_token");
        signOut();
        mutate();
      }

      window.location.pathname = "/login";
    };

    useEffect(() => {
      if (middleware === "guest" && redirectIfAuthenticated && user) router.push(redirectIfAuthenticated);
      if (middleware === "auth" && error) logout();
    }, [user, error]);

    return {
      user,
      login,
      logout,
    };
  };
};

export const usePasswordAuth = makeAuthHook("/api/auth/login");
export const useFirebaseAuth = makeAuthHook("/api/auth/firebase");
export const useAuth = useFirebaseAuth; // 互換性の維持
