/* tslint:disable */
/* eslint-disable */
/**
 * Swish Backend API
 * Swish Backend API Specification
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AdminApiAuthLoginPostRequest
 */
export interface AdminApiAuthLoginPostRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminApiAuthLoginPostRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof AdminApiAuthLoginPostRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface AdminApiFurnitureCategoryGet200Response
 */
export interface AdminApiFurnitureCategoryGet200Response {
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureCategoryGet200Response
     */
    'current_page': number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureCategoryGet200Response
     */
    'from': number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureCategoryGet200Response
     */
    'to': number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureCategoryGet200Response
     */
    'total': number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureCategoryGet200Response
     */
    'last_page': number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureCategoryGet200Response
     */
    'per_page': number | null;
    /**
     * 
     * @type {string}
     * @memberof AdminApiFurnitureCategoryGet200Response
     */
    'first_page_url': string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminApiFurnitureCategoryGet200Response
     */
    'last_page_url': string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminApiFurnitureCategoryGet200Response
     */
    'next_page_url': string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminApiFurnitureCategoryGet200Response
     */
    'prev_page_url': string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminApiFurnitureCategoryGet200Response
     */
    'path': string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminApiFurnitureCategoryGet200Response
     */
    'links': Array<string> | null;
    /**
     * 
     * @type {Array<FurnitureCategory>}
     * @memberof AdminApiFurnitureCategoryGet200Response
     */
    'data': Array<FurnitureCategory>;
}
/**
 * 
 * @export
 * @interface AdminApiFurnitureOptionCreatePost200Response
 */
export interface AdminApiFurnitureOptionCreatePost200Response {
    /**
     * レスポンスメッセージ
     * @type {string}
     * @memberof AdminApiFurnitureOptionCreatePost200Response
     */
    'msg'?: string;
    /**
     * レスポンスステータス
     * @type {boolean}
     * @memberof AdminApiFurnitureOptionCreatePost200Response
     * @deprecated
     */
    'status'?: boolean;
    /**
     * 
     * @type {object}
     * @memberof AdminApiFurnitureOptionCreatePost200Response
     */
    'data': object;
}
/**
 * 
 * @export
 * @interface AdminApiFurnitureOptionCreatePostRequest
 */
export interface AdminApiFurnitureOptionCreatePostRequest {
    /**
     * 家具シリーズID
     * @type {number}
     * @memberof AdminApiFurnitureOptionCreatePostRequest
     */
    'furniture_series_id': number;
    /**
     * 
     * @type {Array<AdminApiFurnitureOptionCreatePostRequestValuesInner>}
     * @memberof AdminApiFurnitureOptionCreatePostRequest
     */
    'values': Array<AdminApiFurnitureOptionCreatePostRequestValuesInner>;
}
/**
 * 
 * @export
 * @interface AdminApiFurnitureOptionCreatePostRequestValuesInner
 */
export interface AdminApiFurnitureOptionCreatePostRequestValuesInner {
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureOptionCreatePostRequestValuesInner
     */
    'manufacture_id': number;
    /**
     * 階層の位置
     * @type {number}
     * @memberof AdminApiFurnitureOptionCreatePostRequestValuesInner
     */
    'option_place_num': number;
    /**
     * 位置の名前
     * @type {string}
     * @memberof AdminApiFurnitureOptionCreatePostRequestValuesInner
     */
    'option_name': string;
    /**
     * 部分品番
     * @type {string}
     * @memberof AdminApiFurnitureOptionCreatePostRequestValuesInner
     */
    'option_code': string | null;
    /**
     * 選択肢名称
     * @type {string}
     * @memberof AdminApiFurnitureOptionCreatePostRequestValuesInner
     */
    'option_detail': string;
    /**
     * 価格
     * @type {number}
     * @memberof AdminApiFurnitureOptionCreatePostRequestValuesInner
     */
    'option_price': number | null;
    /**
     * 家具シリーズID
     * @type {number}
     * @memberof AdminApiFurnitureOptionCreatePostRequestValuesInner
     */
    'furniture_series_id': number;
    /**
     * メニーオプションセットID
     * @type {number}
     * @memberof AdminApiFurnitureOptionCreatePostRequestValuesInner
     */
    'furniture_many_option_set_id': number | null;
    /**
     * 品番生成時の部分品番の位置
     * @type {number}
     * @memberof AdminApiFurnitureOptionCreatePostRequestValuesInner
     */
    'disassembled_parts_number_position': number;
}
/**
 * 
 * @export
 * @interface AdminApiFurnitureSeriesFurnitureSeriesIdImagePost200Response
 */
export interface AdminApiFurnitureSeriesFurnitureSeriesIdImagePost200Response {
    /**
     * レスポンスメッセージ
     * @type {string}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdImagePost200Response
     */
    'msg'?: string;
    /**
     * レスポンスステータス
     * @type {boolean}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdImagePost200Response
     * @deprecated
     */
    'status'?: boolean;
    /**
     * 
     * @type {AdminApiFurnitureSeriesFurnitureSeriesIdImagePost200ResponseAllOfData}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdImagePost200Response
     */
    'data': AdminApiFurnitureSeriesFurnitureSeriesIdImagePost200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface AdminApiFurnitureSeriesFurnitureSeriesIdImagePost200ResponseAllOfData
 */
export interface AdminApiFurnitureSeriesFurnitureSeriesIdImagePost200ResponseAllOfData {
    /**
     * アップロードされたファイルパス
     * @type {string}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdImagePost200ResponseAllOfData
     */
    'path': string;
}
/**
 * 
 * @export
 * @interface AdminApiFurnitureSeriesFurnitureSeriesIdPatch200Response
 */
export interface AdminApiFurnitureSeriesFurnitureSeriesIdPatch200Response {
    /**
     * レスポンスメッセージ
     * @type {string}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatch200Response
     */
    'msg'?: string;
    /**
     * レスポンスステータス
     * @type {boolean}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatch200Response
     * @deprecated
     */
    'status'?: boolean;
    /**
     * 
     * @type {AdminApiFurnitureSeriesFurnitureSeriesIdPatch200ResponseAllOfData}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatch200Response
     */
    'data': AdminApiFurnitureSeriesFurnitureSeriesIdPatch200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface AdminApiFurnitureSeriesFurnitureSeriesIdPatch200ResponseAllOfData
 */
export interface AdminApiFurnitureSeriesFurnitureSeriesIdPatch200ResponseAllOfData {
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatch200ResponseAllOfData
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatch200ResponseAllOfData
     */
    'product_series_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatch200ResponseAllOfData
     */
    'additional_search_word': string | null;
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatch200ResponseAllOfData
     */
    'min_price': number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatch200ResponseAllOfData
     */
    'max_price': number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatch200ResponseAllOfData
     */
    'manufacture_id': number;
    /**
     * 
     * @type {string}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatch200ResponseAllOfData
     */
    'main_image_file_path': string | null;
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatch200ResponseAllOfData
     */
    'min_depth': number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatch200ResponseAllOfData
     */
    'max_depth': number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatch200ResponseAllOfData
     */
    'min_width': number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatch200ResponseAllOfData
     */
    'max_width': number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatch200ResponseAllOfData
     */
    'min_height': number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatch200ResponseAllOfData
     */
    'max_height': number | null;
    /**
     * 
     * @type {string}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatch200ResponseAllOfData
     */
    'catalog_page_url': string | null;
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatch200ResponseAllOfData
     */
    'furniture_order': number | null;
    /**
     * 
     * @type {string}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatch200ResponseAllOfData
     */
    'parts_number_head': string | null;
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatch200ResponseAllOfData
     */
    'is_searchable': AdminApiFurnitureSeriesFurnitureSeriesIdPatch200ResponseAllOfDataIsSearchableEnum;
    /**
     * 
     * @type {Manufacture}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatch200ResponseAllOfData
     */
    'manufacture'?: Manufacture;
    /**
     * 
     * @type {AdminApiFurnitureSeriesGet200ResponseAllOfDataInnerAllOfCategory}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatch200ResponseAllOfData
     */
    'category': AdminApiFurnitureSeriesGet200ResponseAllOfDataInnerAllOfCategory | null;
    /**
     * 
     * @type {AdminApiFurnitureSeriesFurnitureSeriesIdPatch200ResponseAllOfDataAllOfManufacturer}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatch200ResponseAllOfData
     */
    'manufacturer': AdminApiFurnitureSeriesFurnitureSeriesIdPatch200ResponseAllOfDataAllOfManufacturer | null;
}

export const AdminApiFurnitureSeriesFurnitureSeriesIdPatch200ResponseAllOfDataIsSearchableEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type AdminApiFurnitureSeriesFurnitureSeriesIdPatch200ResponseAllOfDataIsSearchableEnum = typeof AdminApiFurnitureSeriesFurnitureSeriesIdPatch200ResponseAllOfDataIsSearchableEnum[keyof typeof AdminApiFurnitureSeriesFurnitureSeriesIdPatch200ResponseAllOfDataIsSearchableEnum];

/**
 * 
 * @export
 * @interface AdminApiFurnitureSeriesFurnitureSeriesIdPatch200ResponseAllOfDataAllOfManufacturer
 */
export interface AdminApiFurnitureSeriesFurnitureSeriesIdPatch200ResponseAllOfDataAllOfManufacturer {
    /**
     * メーカーID
     * @type {number}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatch200ResponseAllOfDataAllOfManufacturer
     */
    'id': number;
    /**
     * メーカー名
     * @type {string}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatch200ResponseAllOfDataAllOfManufacturer
     */
    'name': string;
    /**
     * 追加検索ワード
     * @type {string}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatch200ResponseAllOfDataAllOfManufacturer
     */
    'additional_search_word': string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatch200ResponseAllOfDataAllOfManufacturer
     */
    'code': string | null;
}
/**
 * 
 * @export
 * @interface AdminApiFurnitureSeriesFurnitureSeriesIdPatchRequest
 */
export interface AdminApiFurnitureSeriesFurnitureSeriesIdPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatchRequest
     */
    'product_series_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatchRequest
     */
    'additional_search_word'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatchRequest
     */
    'main_image_file_path'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatchRequest
     */
    'min_price'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatchRequest
     */
    'max_price'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatchRequest
     */
    'manufacture_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatchRequest
     */
    'min_depth'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatchRequest
     */
    'max_depth'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatchRequest
     */
    'min_width'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatchRequest
     */
    'max_width'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatchRequest
     */
    'min_height'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatchRequest
     */
    'max_height'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatchRequest
     */
    'catalog_page_url'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatchRequest
     */
    'furniture_order'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatchRequest
     */
    'parts_number_head'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatchRequest
     */
    'is_searchable'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof AdminApiFurnitureSeriesFurnitureSeriesIdPatchRequest
     */
    'furniture_sub_category_ids'?: Array<number>;
}
/**
 * 
 * @export
 * @interface AdminApiFurnitureSeriesGet200Response
 */
export interface AdminApiFurnitureSeriesGet200Response {
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureSeriesGet200Response
     */
    'current_page': number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureSeriesGet200Response
     */
    'from': number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureSeriesGet200Response
     */
    'to': number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureSeriesGet200Response
     */
    'total': number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureSeriesGet200Response
     */
    'last_page': number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureSeriesGet200Response
     */
    'per_page': number | null;
    /**
     * 
     * @type {string}
     * @memberof AdminApiFurnitureSeriesGet200Response
     */
    'first_page_url': string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminApiFurnitureSeriesGet200Response
     */
    'last_page_url': string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminApiFurnitureSeriesGet200Response
     */
    'next_page_url': string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminApiFurnitureSeriesGet200Response
     */
    'prev_page_url': string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminApiFurnitureSeriesGet200Response
     */
    'path': string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminApiFurnitureSeriesGet200Response
     */
    'links': Array<string> | null;
    /**
     * 
     * @type {Array<AdminApiFurnitureSeriesGet200ResponseAllOfDataInner>}
     * @memberof AdminApiFurnitureSeriesGet200Response
     */
    'data'?: Array<AdminApiFurnitureSeriesGet200ResponseAllOfDataInner>;
}
/**
 * 
 * @export
 * @interface AdminApiFurnitureSeriesGet200ResponseAllOfDataInner
 */
export interface AdminApiFurnitureSeriesGet200ResponseAllOfDataInner {
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureSeriesGet200ResponseAllOfDataInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AdminApiFurnitureSeriesGet200ResponseAllOfDataInner
     */
    'product_series_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminApiFurnitureSeriesGet200ResponseAllOfDataInner
     */
    'additional_search_word': string | null;
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureSeriesGet200ResponseAllOfDataInner
     */
    'min_price': number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureSeriesGet200ResponseAllOfDataInner
     */
    'max_price': number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureSeriesGet200ResponseAllOfDataInner
     */
    'manufacture_id': number;
    /**
     * 
     * @type {string}
     * @memberof AdminApiFurnitureSeriesGet200ResponseAllOfDataInner
     */
    'main_image_file_path': string | null;
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureSeriesGet200ResponseAllOfDataInner
     */
    'min_depth': number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureSeriesGet200ResponseAllOfDataInner
     */
    'max_depth': number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureSeriesGet200ResponseAllOfDataInner
     */
    'min_width': number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureSeriesGet200ResponseAllOfDataInner
     */
    'max_width': number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureSeriesGet200ResponseAllOfDataInner
     */
    'min_height': number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureSeriesGet200ResponseAllOfDataInner
     */
    'max_height': number | null;
    /**
     * 
     * @type {string}
     * @memberof AdminApiFurnitureSeriesGet200ResponseAllOfDataInner
     */
    'catalog_page_url': string | null;
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureSeriesGet200ResponseAllOfDataInner
     */
    'furniture_order': number | null;
    /**
     * 
     * @type {string}
     * @memberof AdminApiFurnitureSeriesGet200ResponseAllOfDataInner
     */
    'parts_number_head': string | null;
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureSeriesGet200ResponseAllOfDataInner
     */
    'is_searchable': AdminApiFurnitureSeriesGet200ResponseAllOfDataInnerIsSearchableEnum;
    /**
     * 
     * @type {Manufacture}
     * @memberof AdminApiFurnitureSeriesGet200ResponseAllOfDataInner
     */
    'manufacture'?: Manufacture;
    /**
     * 
     * @type {AdminApiFurnitureSeriesGet200ResponseAllOfDataInnerAllOfCategory}
     * @memberof AdminApiFurnitureSeriesGet200ResponseAllOfDataInner
     */
    'category': AdminApiFurnitureSeriesGet200ResponseAllOfDataInnerAllOfCategory | null;
    /**
     * 
     * @type {Array<FurnitureSubCategory>}
     * @memberof AdminApiFurnitureSeriesGet200ResponseAllOfDataInner
     */
    'furniture_sub_categories': Array<FurnitureSubCategory>;
}

export const AdminApiFurnitureSeriesGet200ResponseAllOfDataInnerIsSearchableEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type AdminApiFurnitureSeriesGet200ResponseAllOfDataInnerIsSearchableEnum = typeof AdminApiFurnitureSeriesGet200ResponseAllOfDataInnerIsSearchableEnum[keyof typeof AdminApiFurnitureSeriesGet200ResponseAllOfDataInnerIsSearchableEnum];

/**
 * 
 * @export
 * @interface AdminApiFurnitureSeriesGet200ResponseAllOfDataInnerAllOfCategory
 */
export interface AdminApiFurnitureSeriesGet200ResponseAllOfDataInnerAllOfCategory {
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureSeriesGet200ResponseAllOfDataInnerAllOfCategory
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AdminApiFurnitureSeriesGet200ResponseAllOfDataInnerAllOfCategory
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof AdminApiFurnitureSeriesGet200ResponseAllOfDataInnerAllOfCategory
     */
    'sort_order': number;
    /**
     * 
     * @type {string}
     * @memberof AdminApiFurnitureSeriesGet200ResponseAllOfDataInnerAllOfCategory
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof AdminApiFurnitureSeriesGet200ResponseAllOfDataInnerAllOfCategory
     */
    'updated_at': string;
    /**
     * 
     * @type {Array<FurnitureSubCategory>}
     * @memberof AdminApiFurnitureSeriesGet200ResponseAllOfDataInnerAllOfCategory
     */
    'sub_categories'?: Array<FurnitureSubCategory>;
}
/**
 * 
 * @export
 * @interface AllAvailableParts
 */
export interface AllAvailableParts {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AllAvailableParts
     */
    'disassembled_parts_number': { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof AllAvailableParts
     */
    'parts_number': string;
    /**
     * 
     * @type {number}
     * @memberof AllAvailableParts
     */
    'quantity': number;
}
/**
 * 
 * @export
 * @interface ApiAreaFurnitureAreaFurnitureIdDuplicatePost201Response
 */
export interface ApiAreaFurnitureAreaFurnitureIdDuplicatePost201Response {
    /**
     * 
     * @type {AreaFurniture}
     * @memberof ApiAreaFurnitureAreaFurnitureIdDuplicatePost201Response
     */
    'data'?: AreaFurniture;
}
/**
 * 
 * @export
 * @interface ApiCategoriesGet200Response
 */
export interface ApiCategoriesGet200Response {
    /**
     * レスポンスメッセージ
     * @type {string}
     * @memberof ApiCategoriesGet200Response
     */
    'msg'?: string;
    /**
     * レスポンスステータス
     * @type {boolean}
     * @memberof ApiCategoriesGet200Response
     * @deprecated
     */
    'status'?: boolean;
    /**
     * 
     * @type {Array<FurnitureCategory>}
     * @memberof ApiCategoriesGet200Response
     */
    'data'?: Array<FurnitureCategory>;
}
/**
 * 
 * @export
 * @interface ApiCustomFurnitureCreatePost200Response
 */
export interface ApiCustomFurnitureCreatePost200Response {
    /**
     * 
     * @type {string}
     * @memberof ApiCustomFurnitureCreatePost200Response
     */
    'msg'?: string;
    /**
     * レスポンスステータス
     * @type {boolean}
     * @memberof ApiCustomFurnitureCreatePost200Response
     * @deprecated
     */
    'status'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiCustomFurnitureCreatePostRequest
 */
export interface ApiCustomFurnitureCreatePostRequest {
    /**
     * 
     * @type {Array<ApiCustomFurnitureCreatePostRequestCustomFurnituresInner>}
     * @memberof ApiCustomFurnitureCreatePostRequest
     */
    'customFurnitures'?: Array<ApiCustomFurnitureCreatePostRequestCustomFurnituresInner>;
}
/**
 * 
 * @export
 * @interface ApiCustomFurnitureCreatePostRequestCustomFurnituresInner
 */
export interface ApiCustomFurnitureCreatePostRequestCustomFurnituresInner {
    /**
     * エリアID
     * @type {string}
     * @memberof ApiCustomFurnitureCreatePostRequestCustomFurnituresInner
     */
    'area_id': string;
    /**
     * CustomFurniture.id
     * @type {number}
     * @memberof ApiCustomFurnitureCreatePostRequestCustomFurnituresInner
     */
    'id': number;
    /**
     * 売価
     * @type {number}
     * @memberof ApiCustomFurnitureCreatePostRequestCustomFurnituresInner
     */
    'price'?: number | null;
    /**
     * 数量
     * @type {number}
     * @memberof ApiCustomFurnitureCreatePostRequestCustomFurnituresInner
     */
    'quantity'?: number | null;
    /**
     * 原価
     * @type {number}
     * @memberof ApiCustomFurnitureCreatePostRequestCustomFurnituresInner
     */
    'cost_excluding_tax'?: number | null;
    /**
     * 掛け率
     * @type {number}
     * @memberof ApiCustomFurnitureCreatePostRequestCustomFurnituresInner
     */
    'betting_rate'?: number | null;
    /**
     * 備考
     * @type {string}
     * @memberof ApiCustomFurnitureCreatePostRequestCustomFurnituresInner
     */
    'notes'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiCustomFurnitureCreatePostRequestCustomFurnituresInner
     */
    'construction_point'?: number;
    /**
     * 
     * @type {ApiCustomFurnitureCreatePostRequestCustomFurnituresInnerSpec}
     * @memberof ApiCustomFurnitureCreatePostRequestCustomFurnituresInner
     */
    'spec'?: ApiCustomFurnitureCreatePostRequestCustomFurnituresInnerSpec | null;
}
/**
 * CustomFurnitureSpecモデル
 * @export
 * @interface ApiCustomFurnitureCreatePostRequestCustomFurnituresInnerSpec
 */
export interface ApiCustomFurnitureCreatePostRequestCustomFurnituresInnerSpec {
    /**
     * 品名
     * @type {string}
     * @memberof ApiCustomFurnitureCreatePostRequestCustomFurnituresInnerSpec
     */
    'product_series_name'?: string | null;
    /**
     * 品番
     * @type {string}
     * @memberof ApiCustomFurnitureCreatePostRequestCustomFurnituresInnerSpec
     */
    'parts_number'?: string | null;
    /**
     * 注文コード
     * @type {string}
     * @memberof ApiCustomFurnitureCreatePostRequestCustomFurnituresInnerSpec
     */
    'order_code'?: string | null;
    /**
     * ページ
     * @type {string}
     * @memberof ApiCustomFurnitureCreatePostRequestCustomFurnituresInnerSpec
     */
    'catalog_page_num'?: string | null;
    /**
     * W寸法
     * @type {number}
     * @memberof ApiCustomFurnitureCreatePostRequestCustomFurnituresInnerSpec
     */
    'width'?: number | null;
    /**
     * H寸法
     * @type {number}
     * @memberof ApiCustomFurnitureCreatePostRequestCustomFurnituresInnerSpec
     */
    'height'?: number | null;
    /**
     * D寸法
     * @type {number}
     * @memberof ApiCustomFurnitureCreatePostRequestCustomFurnituresInnerSpec
     */
    'depth'?: number | null;
    /**
     * 単位
     * @type {string}
     * @memberof ApiCustomFurnitureCreatePostRequestCustomFurnituresInnerSpec
     */
    'unit_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiCustomFurnitureCreatePostRequestCustomFurnituresInnerSpec
     */
    'option_name1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiCustomFurnitureCreatePostRequestCustomFurnituresInnerSpec
     */
    'option_name2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiCustomFurnitureCreatePostRequestCustomFurnituresInnerSpec
     */
    'option_name3'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiCustomFurnitureCustomFurnitureIdDuplicatePost201Response
 */
export interface ApiCustomFurnitureCustomFurnitureIdDuplicatePost201Response {
    /**
     * 
     * @type {CustomFurniture}
     * @memberof ApiCustomFurnitureCustomFurnitureIdDuplicatePost201Response
     */
    'data'?: CustomFurniture;
}
/**
 * 
 * @export
 * @interface ApiFavoriteGet200Response
 */
export interface ApiFavoriteGet200Response {
    /**
     * レスポンスメッセージ
     * @type {string}
     * @memberof ApiFavoriteGet200Response
     */
    'msg'?: string;
    /**
     * レスポンスステータス
     * @type {boolean}
     * @memberof ApiFavoriteGet200Response
     * @deprecated
     */
    'status'?: boolean;
    /**
     * 
     * @type {Array<UserFavoriteFurniture>}
     * @memberof ApiFavoriteGet200Response
     */
    'data'?: Array<UserFavoriteFurniture>;
}
/**
 * 
 * @export
 * @interface ApiFavoritePostRequest
 */
export interface ApiFavoritePostRequest {
    /**
     * 
     * @type {number}
     * @memberof ApiFavoritePostRequest
     */
    'manufacture_id': number;
    /**
     * 
     * @type {string}
     * @memberof ApiFavoritePostRequest
     */
    'parts_number': string;
    /**
     * 
     * @type {Array<SelectedFurnitureManyOption>}
     * @memberof ApiFavoritePostRequest
     */
    'furniture_many_options': Array<SelectedFurnitureManyOption> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ApiFavoritePostRequest
     */
    'favorite_furniture_set_ids': Array<number>;
}
/**
 * 
 * @export
 * @interface ApiFurnitureAreaIdUpdateAreaFurnitureIdPatch200Response
 */
export interface ApiFurnitureAreaIdUpdateAreaFurnitureIdPatch200Response {
    /**
     * 
     * @type {string}
     * @memberof ApiFurnitureAreaIdUpdateAreaFurnitureIdPatch200Response
     */
    'msg'?: string;
    /**
     * レスポンスステータス
     * @type {boolean}
     * @memberof ApiFurnitureAreaIdUpdateAreaFurnitureIdPatch200Response
     * @deprecated
     */
    'status'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApiFurnitureAreaIdUpdateAreaFurnitureIdPatch200Response
     */
    'data'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequest
 */
export interface ApiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequest {
    /**
     * 
     * @type {number}
     * @memberof ApiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequest
     */
    'manufacture_id'?: number | null;
    /**
     * 品番
     * @type {string}
     * @memberof ApiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequest
     */
    'parts_number'?: string | null;
    /**
     * 売価
     * @type {number}
     * @memberof ApiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequest
     */
    'price'?: number | null;
    /**
     * 定価
     * @type {number}
     * @memberof ApiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequest
     */
    'standard_retail_price_excluding_tax'?: number | null;
    /**
     * 数量
     * @type {number}
     * @memberof ApiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequest
     */
    'quantity'?: number;
    /**
     * 掛け率（1〜100）
     * @type {number}
     * @memberof ApiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequest
     */
    'betting_rate'?: number | null;
    /**
     * スタンプカラー
     * @type {string}
     * @memberof ApiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequest
     */
    'stamp_color'?: string | null;
    /**
     * 備考
     * @type {string}
     * @memberof ApiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequest
     */
    'notes'?: string | null;
    /**
     * 
     * @type {ApiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequestSpec}
     * @memberof ApiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequest
     */
    'spec'?: ApiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequestSpec | null;
}
/**
 * AreaFurnitureSpecモデル
 * @export
 * @interface ApiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequestSpec
 */
export interface ApiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequestSpec {
    /**
     * 任意メーカーID
     * @type {number}
     * @memberof ApiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequestSpec
     */
    'manufacture_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequestSpec
     */
    'parts_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequestSpec
     */
    'parts_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequestSpec
     */
    'order_code'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequestSpec
     */
    'width_mm'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequestSpec
     */
    'depth_mm'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequestSpec
     */
    'height_mm'?: number | null;
    /**
     * 仕様1
     * @type {string}
     * @memberof ApiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequestSpec
     */
    'option_name1'?: string | null;
    /**
     * 仕様2
     * @type {string}
     * @memberof ApiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequestSpec
     */
    'option_name2'?: string | null;
    /**
     * 仕様3
     * @type {string}
     * @memberof ApiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequestSpec
     */
    'option_name3'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiFurnitureAreaPost200Response
 */
export interface ApiFurnitureAreaPost200Response {
    /**
     * レスポンスメッセージ
     * @type {string}
     * @memberof ApiFurnitureAreaPost200Response
     */
    'msg'?: string;
    /**
     * レスポンスステータス
     * @type {boolean}
     * @memberof ApiFurnitureAreaPost200Response
     * @deprecated
     */
    'status'?: boolean;
    /**
     * 
     * @type {{ [key: string]: ApiFurnitureAreaPost200ResponseAllOfDataValue; }}
     * @memberof ApiFurnitureAreaPost200Response
     */
    'data'?: { [key: string]: ApiFurnitureAreaPost200ResponseAllOfDataValue; };
}
/**
 * 
 * @export
 * @interface ApiFurnitureAreaPost200ResponseAllOfDataValue
 */
export interface ApiFurnitureAreaPost200ResponseAllOfDataValue {
    /**
     * 
     * @type {string}
     * @memberof ApiFurnitureAreaPost200ResponseAllOfDataValue
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiFurnitureAreaPost200ResponseAllOfDataValue
     */
    'floor_quotation_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiFurnitureAreaPost200ResponseAllOfDataValue
     */
    'name'?: string | null;
    /**
     * 
     * @type {AreaDefinition}
     * @memberof ApiFurnitureAreaPost200ResponseAllOfDataValue
     */
    'definition'?: AreaDefinition;
    /**
     * 
     * @type {string}
     * @memberof ApiFurnitureAreaPost200ResponseAllOfDataValue
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiFurnitureAreaPost200ResponseAllOfDataValue
     */
    'canvas_scale'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiFurnitureAreaPost200ResponseAllOfDataValue
     */
    'sort_order'?: number | null;
    /**
     * 
     * @type {Array<CustomFurniture>}
     * @memberof ApiFurnitureAreaPost200ResponseAllOfDataValue
     */
    'custom_furnitures'?: Array<CustomFurniture>;
    /**
     * 
     * @type {Array<AreaFurniture>}
     * @memberof ApiFurnitureAreaPost200ResponseAllOfDataValue
     */
    'area_furnitures'?: Array<AreaFurniture>;
}
/**
 * 
 * @export
 * @interface ApiFurnitureAreaPostRequest
 */
export interface ApiFurnitureAreaPostRequest {
    /**
     * 見積ID
     * @type {string}
     * @memberof ApiFurnitureAreaPostRequest
     */
    'quotation': string;
}
/**
 * 
 * @export
 * @interface ApiFurniturePartNumberSearchPost200Response
 */
export interface ApiFurniturePartNumberSearchPost200Response {
    /**
     * レスポンスメッセージ
     * @type {string}
     * @memberof ApiFurniturePartNumberSearchPost200Response
     */
    'msg'?: string;
    /**
     * レスポンスステータス
     * @type {boolean}
     * @memberof ApiFurniturePartNumberSearchPost200Response
     * @deprecated
     */
    'status'?: boolean;
    /**
     * 
     * @type {ApiFurniturePartNumberSearchPost200ResponseAllOfData}
     * @memberof ApiFurniturePartNumberSearchPost200Response
     */
    'data': ApiFurniturePartNumberSearchPost200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface ApiFurniturePartNumberSearchPost200ResponseAllOfData
 */
export interface ApiFurniturePartNumberSearchPost200ResponseAllOfData {
    /**
     * 
     * @type {Array<PartNumberSearchFurniture>}
     * @memberof ApiFurniturePartNumberSearchPost200ResponseAllOfData
     */
    'data': Array<PartNumberSearchFurniture>;
    /**
     * 
     * @type {number}
     * @memberof ApiFurniturePartNumberSearchPost200ResponseAllOfData
     */
    'current_page': number | null;
    /**
     * 
     * @type {number}
     * @memberof ApiFurniturePartNumberSearchPost200ResponseAllOfData
     */
    'from': number | null;
    /**
     * 
     * @type {number}
     * @memberof ApiFurniturePartNumberSearchPost200ResponseAllOfData
     */
    'to': number | null;
    /**
     * 
     * @type {number}
     * @memberof ApiFurniturePartNumberSearchPost200ResponseAllOfData
     */
    'total': number | null;
    /**
     * 
     * @type {number}
     * @memberof ApiFurniturePartNumberSearchPost200ResponseAllOfData
     */
    'last_page': number | null;
    /**
     * 
     * @type {number}
     * @memberof ApiFurniturePartNumberSearchPost200ResponseAllOfData
     */
    'per_page': number | null;
    /**
     * 
     * @type {string}
     * @memberof ApiFurniturePartNumberSearchPost200ResponseAllOfData
     */
    'first_page_url': string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiFurniturePartNumberSearchPost200ResponseAllOfData
     */
    'last_page_url': string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiFurniturePartNumberSearchPost200ResponseAllOfData
     */
    'next_page_url': string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiFurniturePartNumberSearchPost200ResponseAllOfData
     */
    'prev_page_url': string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiFurniturePartNumberSearchPost200ResponseAllOfData
     */
    'path': string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiFurniturePartNumberSearchPost200ResponseAllOfData
     */
    'links': Array<string> | null;
}
/**
 * 
 * @export
 * @interface ApiFurniturePartNumberSearchPostRequest
 */
export interface ApiFurniturePartNumberSearchPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiFurniturePartNumberSearchPostRequest
     */
    'code'?: string | null;
    /**
     * 完全一致検索をするかどうか
     * @type {boolean}
     * @memberof ApiFurniturePartNumberSearchPostRequest
     */
    'exact_match'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiFurnitureSearchFurnituresPost200Response
 */
export interface ApiFurnitureSearchFurnituresPost200Response {
    /**
     * レスポンスメッセージ
     * @type {string}
     * @memberof ApiFurnitureSearchFurnituresPost200Response
     */
    'msg'?: string;
    /**
     * レスポンスステータス
     * @type {boolean}
     * @memberof ApiFurnitureSearchFurnituresPost200Response
     * @deprecated
     */
    'status'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApiFurnitureSearchFurnituresPost200Response
     */
    'current_page': number | null;
    /**
     * 
     * @type {number}
     * @memberof ApiFurnitureSearchFurnituresPost200Response
     */
    'from': number | null;
    /**
     * 
     * @type {number}
     * @memberof ApiFurnitureSearchFurnituresPost200Response
     */
    'to': number | null;
    /**
     * 
     * @type {number}
     * @memberof ApiFurnitureSearchFurnituresPost200Response
     */
    'total': number | null;
    /**
     * 
     * @type {number}
     * @memberof ApiFurnitureSearchFurnituresPost200Response
     */
    'last_page': number | null;
    /**
     * 
     * @type {number}
     * @memberof ApiFurnitureSearchFurnituresPost200Response
     */
    'per_page': number | null;
    /**
     * 
     * @type {string}
     * @memberof ApiFurnitureSearchFurnituresPost200Response
     */
    'first_page_url': string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiFurnitureSearchFurnituresPost200Response
     */
    'last_page_url': string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiFurnitureSearchFurnituresPost200Response
     */
    'next_page_url': string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiFurnitureSearchFurnituresPost200Response
     */
    'prev_page_url': string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiFurnitureSearchFurnituresPost200Response
     */
    'path': string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiFurnitureSearchFurnituresPost200Response
     */
    'links': Array<string> | null;
    /**
     * 
     * @type {Array<SearchFurniture>}
     * @memberof ApiFurnitureSearchFurnituresPost200Response
     */
    'data'?: Array<SearchFurniture>;
}
/**
 * 
 * @export
 * @interface ApiFurnitureSearchFurnituresPostRequest
 */
export interface ApiFurnitureSearchFurnituresPostRequest {
    /**
     * ソート順
     * @type {string}
     * @memberof ApiFurnitureSearchFurnituresPostRequest
     */
    'sort_order'?: ApiFurnitureSearchFurnituresPostRequestSortOrderEnum;
    /**
     * 
     * @type {number}
     * @memberof ApiFurnitureSearchFurnituresPostRequest
     */
    'min_width'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApiFurnitureSearchFurnituresPostRequest
     */
    'max_width'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApiFurnitureSearchFurnituresPostRequest
     */
    'min_depth'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApiFurnitureSearchFurnituresPostRequest
     */
    'max_depth'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApiFurnitureSearchFurnituresPostRequest
     */
    'min_height'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApiFurnitureSearchFurnituresPostRequest
     */
    'max_height'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApiFurnitureSearchFurnituresPostRequest
     */
    'min_price'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApiFurnitureSearchFurnituresPostRequest
     */
    'max_price'?: number | null;
    /**
     * カテゴリID
     * @type {number}
     * @memberof ApiFurnitureSearchFurnituresPostRequest
     */
    'large_category_id'?: number | null;
    /**
     * サブカテゴリID一覧
     * @type {Array<number>}
     * @memberof ApiFurnitureSearchFurnituresPostRequest
     */
    'sub_category_ids'?: Array<number> | null;
    /**
     * メーカーID一覧
     * @type {Array<number>}
     * @memberof ApiFurnitureSearchFurnituresPostRequest
     */
    'manufacture_ids'?: Array<number> | null;
    /**
     * フリーテキスト検索
     * @type {string}
     * @memberof ApiFurnitureSearchFurnituresPostRequest
     */
    'search'?: string | null;
    /**
     * ページ番号
     * @type {number}
     * @memberof ApiFurnitureSearchFurnituresPostRequest
     */
    'page'?: number | null;
    /**
     * 納期(日)
     * @type {number}
     * @memberof ApiFurnitureSearchFurnituresPostRequest
     */
    'estimated_delivery_date'?: number | null;
    /**
     * タグID一覧
     * @type {Array<number>}
     * @memberof ApiFurnitureSearchFurnituresPostRequest
     */
    'tag_ids'?: Array<number> | null;
}

export const ApiFurnitureSearchFurnituresPostRequestSortOrderEnum = {
    Asc: 'asc',
    Desc: 'desc',
    All: 'all'
} as const;

export type ApiFurnitureSearchFurnituresPostRequestSortOrderEnum = typeof ApiFurnitureSearchFurnituresPostRequestSortOrderEnum[keyof typeof ApiFurnitureSearchFurnituresPostRequestSortOrderEnum];

/**
 * 
 * @export
 * @interface ApiFurnitureSeriesFurnitureSeriesIdGet200Response
 */
export interface ApiFurnitureSeriesFurnitureSeriesIdGet200Response {
    /**
     * レスポンスメッセージ
     * @type {string}
     * @memberof ApiFurnitureSeriesFurnitureSeriesIdGet200Response
     */
    'msg'?: string;
    /**
     * レスポンスステータス
     * @type {boolean}
     * @memberof ApiFurnitureSeriesFurnitureSeriesIdGet200Response
     * @deprecated
     */
    'status'?: boolean;
    /**
     * 
     * @type {GetFurnitureSeriesResponse}
     * @memberof ApiFurnitureSeriesFurnitureSeriesIdGet200Response
     */
    'data'?: GetFurnitureSeriesResponse;
}
/**
 * 
 * @export
 * @interface ApiFurnitureSeriesFurnitureSeriesIdRelatedFurnituresGet200Response
 */
export interface ApiFurnitureSeriesFurnitureSeriesIdRelatedFurnituresGet200Response {
    /**
     * レスポンスメッセージ
     * @type {string}
     * @memberof ApiFurnitureSeriesFurnitureSeriesIdRelatedFurnituresGet200Response
     */
    'msg'?: string;
    /**
     * レスポンスステータス
     * @type {boolean}
     * @memberof ApiFurnitureSeriesFurnitureSeriesIdRelatedFurnituresGet200Response
     * @deprecated
     */
    'status'?: boolean;
    /**
     * 
     * @type {Array<FurnitureSeries>}
     * @memberof ApiFurnitureSeriesFurnitureSeriesIdRelatedFurnituresGet200Response
     */
    'data'?: Array<FurnitureSeries>;
}
/**
 * 
 * @export
 * @interface ApiQuotationAllExpensesGet200Response
 */
export interface ApiQuotationAllExpensesGet200Response {
    /**
     * レスポンスメッセージ
     * @type {string}
     * @memberof ApiQuotationAllExpensesGet200Response
     */
    'msg'?: string;
    /**
     * レスポンスステータス
     * @type {boolean}
     * @memberof ApiQuotationAllExpensesGet200Response
     * @deprecated
     */
    'status'?: boolean;
    /**
     * 
     * @type {Array<ApiQuotationAllExpensesGet200ResponseAllOfDataInner>}
     * @memberof ApiQuotationAllExpensesGet200Response
     */
    'data': Array<ApiQuotationAllExpensesGet200ResponseAllOfDataInner>;
}
/**
 * 
 * @export
 * @interface ApiQuotationAllExpensesGet200ResponseAllOfDataInner
 */
export interface ApiQuotationAllExpensesGet200ResponseAllOfDataInner {
    /**
     * 
     * @type {string}
     * @memberof ApiQuotationAllExpensesGet200ResponseAllOfDataInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ApiQuotationAllExpensesGet200ResponseAllOfDataInner
     */
    'floor_quotation_id': string;
    /**
     * 
     * @type {string}
     * @memberof ApiQuotationAllExpensesGet200ResponseAllOfDataInner
     */
    'floor_quotation_name': string;
    /**
     * 提出経費名
     * @type {string}
     * @memberof ApiQuotationAllExpensesGet200ResponseAllOfDataInner
     */
    'name': string | null;
    /**
     * 品番
     * @type {string}
     * @memberof ApiQuotationAllExpensesGet200ResponseAllOfDataInner
     */
    'parts_number': string | null;
    /**
     * 備考
     * @type {string}
     * @memberof ApiQuotationAllExpensesGet200ResponseAllOfDataInner
     */
    'notes': string | null;
    /**
     * 単価
     * @type {number}
     * @memberof ApiQuotationAllExpensesGet200ResponseAllOfDataInner
     */
    'amount': number | null;
    /**
     * 原価
     * @type {number}
     * @memberof ApiQuotationAllExpensesGet200ResponseAllOfDataInner
     */
    'cost_excluding_tax': number | null;
    /**
     * 数量
     * @type {number}
     * @memberof ApiQuotationAllExpensesGet200ResponseAllOfDataInner
     */
    'qty': number;
    /**
     * 単位
     * @type {string}
     * @memberof ApiQuotationAllExpensesGet200ResponseAllOfDataInner
     */
    'unit': string;
    /**
     * ソート順序
     * @type {number}
     * @memberof ApiQuotationAllExpensesGet200ResponseAllOfDataInner
     */
    'sort_order': number | null;
    /**
     * 定価
     * @type {number}
     * @memberof ApiQuotationAllExpensesGet200ResponseAllOfDataInner
     */
    'standard_retail_price_excluding_tax': number | null;
    /**
     * 
     * @type {string}
     * @memberof ApiQuotationAllExpensesGet200ResponseAllOfDataInner
     * @deprecated
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof ApiQuotationAllExpensesGet200ResponseAllOfDataInner
     * @deprecated
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof ApiQuotationAllExpensesGet200ResponseAllOfDataInner
     * @deprecated
     */
    'deleted_at': string | null;
}
/**
 * 
 * @export
 * @interface ApiQuotationExpensesQuotationExpensesIdPatch200Response
 */
export interface ApiQuotationExpensesQuotationExpensesIdPatch200Response {
    /**
     * レスポンスメッセージ
     * @type {string}
     * @memberof ApiQuotationExpensesQuotationExpensesIdPatch200Response
     */
    'msg'?: string;
    /**
     * レスポンスステータス
     * @type {boolean}
     * @memberof ApiQuotationExpensesQuotationExpensesIdPatch200Response
     * @deprecated
     */
    'status'?: boolean;
    /**
     * 
     * @type {Array<QuotationExpense>}
     * @memberof ApiQuotationExpensesQuotationExpensesIdPatch200Response
     */
    'data'?: Array<QuotationExpense>;
}
/**
 * 
 * @export
 * @interface ApiQuotationExpensesQuotationExpensesIdPatchRequest
 */
export interface ApiQuotationExpensesQuotationExpensesIdPatchRequest {
    /**
     * 提出経費名
     * @type {string}
     * @memberof ApiQuotationExpensesQuotationExpensesIdPatchRequest
     */
    'name': string;
    /**
     * 単価
     * @type {number}
     * @memberof ApiQuotationExpensesQuotationExpensesIdPatchRequest
     */
    'amount': number;
    /**
     * 定価
     * @type {number}
     * @memberof ApiQuotationExpensesQuotationExpensesIdPatchRequest
     */
    'standard_retail_price_excluding_tax'?: number | null;
}
/**
 * 
 * @export
 * @interface ApiQuotationQuotationIdManufactureBettingRatesGet200Response
 */
export interface ApiQuotationQuotationIdManufactureBettingRatesGet200Response {
    /**
     * 
     * @type {Array<ManufactureBettingRate>}
     * @memberof ApiQuotationQuotationIdManufactureBettingRatesGet200Response
     */
    'data': Array<ManufactureBettingRate>;
}
/**
 * 
 * @export
 * @interface ApiQuotationQuotationIdUpdatePatchRequest
 */
export interface ApiQuotationQuotationIdUpdatePatchRequest {
    /**
     * 
     * @type {number}
     * @memberof ApiQuotationQuotationIdUpdatePatchRequest
     */
    'rounding_type': number;
    /**
     * 
     * @type {number}
     * @memberof ApiQuotationQuotationIdUpdatePatchRequest
     */
    'rounding_unit': number;
    /**
     * 
     * @type {number}
     * @memberof ApiQuotationQuotationIdUpdatePatchRequest
     */
    'betting_rate': number;
    /**
     * 掛け率設定タイプ - `all` 一括設定 - `manufacture` 仕入先ごとに掛け率設定 
     * @type {string}
     * @memberof ApiQuotationQuotationIdUpdatePatchRequest
     */
    'betting_rate_type': ApiQuotationQuotationIdUpdatePatchRequestBettingRateTypeEnum;
    /**
     * 仕入先ごとの掛け率設定。 
     * @type {Array<ApiQuotationQuotationIdUpdatePatchRequestManufactureBettingRatesInner>}
     * @memberof ApiQuotationQuotationIdUpdatePatchRequest
     */
    'manufacture_betting_rates'?: Array<ApiQuotationQuotationIdUpdatePatchRequestManufactureBettingRatesInner> | null;
}

export const ApiQuotationQuotationIdUpdatePatchRequestBettingRateTypeEnum = {
    All: 'all',
    Manufacture: 'manufacture'
} as const;

export type ApiQuotationQuotationIdUpdatePatchRequestBettingRateTypeEnum = typeof ApiQuotationQuotationIdUpdatePatchRequestBettingRateTypeEnum[keyof typeof ApiQuotationQuotationIdUpdatePatchRequestBettingRateTypeEnum];

/**
 * 
 * @export
 * @interface ApiQuotationQuotationIdUpdatePatchRequestManufactureBettingRatesInner
 */
export interface ApiQuotationQuotationIdUpdatePatchRequestManufactureBettingRatesInner {
    /**
     * 
     * @type {number}
     * @memberof ApiQuotationQuotationIdUpdatePatchRequestManufactureBettingRatesInner
     */
    'manufacture_id': number;
    /**
     * 
     * @type {number}
     * @memberof ApiQuotationQuotationIdUpdatePatchRequestManufactureBettingRatesInner
     */
    'betting_rate': number | null;
    /**
     * 
     * @type {number}
     * @memberof ApiQuotationQuotationIdUpdatePatchRequestManufactureBettingRatesInner
     */
    'rounding_type': ApiQuotationQuotationIdUpdatePatchRequestManufactureBettingRatesInnerRoundingTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ApiQuotationQuotationIdUpdatePatchRequestManufactureBettingRatesInner
     */
    'rounding_unit': ApiQuotationQuotationIdUpdatePatchRequestManufactureBettingRatesInnerRoundingUnitEnum;
}

export const ApiQuotationQuotationIdUpdatePatchRequestManufactureBettingRatesInnerRoundingTypeEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
} as const;

export type ApiQuotationQuotationIdUpdatePatchRequestManufactureBettingRatesInnerRoundingTypeEnum = typeof ApiQuotationQuotationIdUpdatePatchRequestManufactureBettingRatesInnerRoundingTypeEnum[keyof typeof ApiQuotationQuotationIdUpdatePatchRequestManufactureBettingRatesInnerRoundingTypeEnum];
export const ApiQuotationQuotationIdUpdatePatchRequestManufactureBettingRatesInnerRoundingUnitEnum = {
    NUMBER_1: 1,
    NUMBER_10: 10,
    NUMBER_100: 100,
    NUMBER_1000: 1000
} as const;

export type ApiQuotationQuotationIdUpdatePatchRequestManufactureBettingRatesInnerRoundingUnitEnum = typeof ApiQuotationQuotationIdUpdatePatchRequestManufactureBettingRatesInnerRoundingUnitEnum[keyof typeof ApiQuotationQuotationIdUpdatePatchRequestManufactureBettingRatesInnerRoundingUnitEnum];

/**
 * 
 * @export
 * @interface ApiUploadFilePost200Response
 */
export interface ApiUploadFilePost200Response {
    /**
     * レスポンスメッセージ
     * @type {string}
     * @memberof ApiUploadFilePost200Response
     */
    'msg'?: string;
    /**
     * レスポンスステータス
     * @type {boolean}
     * @memberof ApiUploadFilePost200Response
     * @deprecated
     */
    'status'?: boolean;
    /**
     * 
     * @type {ApiUploadFilePost200ResponseAllOfData}
     * @memberof ApiUploadFilePost200Response
     */
    'data': ApiUploadFilePost200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface ApiUploadFilePost200ResponseAllOfData
 */
export interface ApiUploadFilePost200ResponseAllOfData {
    /**
     * アップロードされたファイルのパス
     * @type {string}
     * @memberof ApiUploadFilePost200ResponseAllOfData
     */
    'path': string;
}
/**
 * 
 * @export
 * @interface Area
 */
export interface Area {
    /**
     * 
     * @type {string}
     * @memberof Area
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Area
     */
    'floor_quotation_id': string;
    /**
     * 
     * @type {string}
     * @memberof Area
     */
    'name': string;
    /**
     * 
     * @type {AreaDefinition}
     * @memberof Area
     */
    'definition': AreaDefinition;
    /**
     * 
     * @type {AreaType}
     * @memberof Area
     */
    'type': AreaType;
    /**
     * 
     * @type {number}
     * @memberof Area
     */
    'sort_order': number | null;
    /**
     * 
     * @type {string}
     * @memberof Area
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof Area
     */
    'updated_at': string;
    /**
     * 
     * @type {Array<FurnitureStamp>}
     * @memberof Area
     */
    'furniture_stamps': Array<FurnitureStamp>;
}


/**
 * エリア定義
 * @export
 * @interface AreaDefinition
 */
export interface AreaDefinition {
    /**
     * 
     * @type {string}
     * @memberof AreaDefinition
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof AreaDefinition
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof AreaDefinition
     */
    'y': number;
    /**
     * 
     * @type {number}
     * @memberof AreaDefinition
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof AreaDefinition
     */
    'height': number;
    /**
     * 
     * @type {boolean}
     * @memberof AreaDefinition
     */
    'visible'?: boolean;
}
/**
 * 
 * @export
 * @interface AreaFurniture
 */
export interface AreaFurniture {
    /**
     * 
     * @type {number}
     * @memberof AreaFurniture
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AreaFurniture
     */
    'floor_quotation_id': string;
    /**
     * 
     * @type {string}
     * @memberof AreaFurniture
     */
    'area_id': string;
    /**
     * 
     * @type {string}
     * @memberof AreaFurniture
     */
    'furniture_code': string;
    /**
     * 数量
     * @type {number}
     * @memberof AreaFurniture
     */
    'quantity': number;
    /**
     * 個別単価(定価×掛け率)
     * @type {number}
     * @memberof AreaFurniture
     */
    'price': number | null;
    /**
     * 
     * @type {number}
     * @memberof AreaFurniture
     */
    'betting_rate': number | null;
    /**
     * 
     * @type {string}
     * @memberof AreaFurniture
     */
    'notes': string | null;
    /**
     * 
     * @type {number}
     * @memberof AreaFurniture
     */
    'sort_order': number | null;
    /**
     * 
     * @type {string}
     * @memberof AreaFurniture
     */
    'parts_number': string;
    /**
     * メーカーID(任意家具の場合にnull)
     * @type {number}
     * @memberof AreaFurniture
     */
    'manufacture_id': number | null;
    /**
     * メーカー名(任意家具の場合にnull)
     * @type {string}
     * @memberof AreaFurniture
     */
    'manufacture_name': string | null;
    /**
     * 定価
     * @type {number}
     * @memberof AreaFurniture
     */
    'standard_retail_price_excluding_tax': number | null;
    /**
     * 
     * @type {string}
     * @memberof AreaFurniture
     */
    'stamp_color': string | null;
    /**
     * 原価
     * @type {number}
     * @memberof AreaFurniture
     */
    'cost_excluding_tax': number | null;
    /**
     * 合計金額
     * @type {number}
     * @memberof AreaFurniture
     */
    'total_value': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof AreaFurniture
     */
    'selected_options': Array<string>;
    /**
     * 最終単価(定価×掛け率×端数処理)  `定価×掛け率`の部分は、`price`カラムに値があればそちらを利用する 
     * @type {number}
     * @memberof AreaFurniture
     */
    'last_price': number;
    /**
     * 
     * @type {Array<SelectedFurnitureManyOption>}
     * @memberof AreaFurniture
     */
    'furniture_many_options': Array<SelectedFurnitureManyOption>;
    /**
     * 
     * @type {AreaFurnitureFurniture}
     * @memberof AreaFurniture
     * @deprecated
     */
    'furniture': AreaFurnitureFurniture | null;
    /**
     * 
     * @type {AreaFurnitureSpec}
     * @memberof AreaFurniture
     */
    'spec': AreaFurnitureSpec;
    /**
     * 
     * @type {FurnitureDisplay}
     * @memberof AreaFurniture
     */
    'display': FurnitureDisplay;
}
/**
 * エリア家具の作成元となった家具情報  NOTE: 運用上、取得できなくなっている場合もあるため廃止予定 
 * @export
 * @interface AreaFurnitureFurniture
 */
export interface AreaFurnitureFurniture {
    /**
     * 
     * @type {number}
     * @memberof AreaFurnitureFurniture
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof AreaFurnitureFurniture
     */
    'furniture_series_id': number;
    /**
     * 
     * @type {number}
     * @memberof AreaFurnitureFurniture
     */
    'manufacture_id': number;
    /**
     * 
     * @type {string}
     * @memberof AreaFurnitureFurniture
     */
    'manufacture_name': string;
    /**
     * 
     * @type {string}
     * @memberof AreaFurnitureFurniture
     */
    'parts_number': string;
    /**
     * 
     * @type {number}
     * @memberof AreaFurnitureFurniture
     */
    'standard_retail_price_excluding_tax': number | null;
    /**
     * 
     * @type {number}
     * @memberof AreaFurnitureFurniture
     */
    'construction_point': number | null;
    /**
     * 
     * @type {number}
     * @memberof AreaFurnitureFurniture
     */
    'quantity': number | null;
    /**
     * 
     * @type {FurnitureSeries}
     * @memberof AreaFurnitureFurniture
     */
    'series': FurnitureSeries;
    /**
     * 
     * @type {Array<FurnitureOption>}
     * @memberof AreaFurnitureFurniture
     */
    'options': Array<FurnitureOption>;
}
/**
 * 
 * @export
 * @interface AreaFurnitureSpec
 */
export interface AreaFurnitureSpec {
    /**
     * 任意選択メーカーID
     * @type {number}
     * @memberof AreaFurnitureSpec
     */
    'manufacture_id': number | null;
    /**
     * 任意選択メーカー名
     * @type {string}
     * @memberof AreaFurnitureSpec
     */
    'manufacture_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof AreaFurnitureSpec
     */
    'parts_number': string | null;
    /**
     * 
     * @type {string}
     * @memberof AreaFurnitureSpec
     */
    'parts_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof AreaFurnitureSpec
     */
    'unit_name': string | null;
    /**
     * 
     * @type {number}
     * @memberof AreaFurnitureSpec
     */
    'width_mm': number | null;
    /**
     * 
     * @type {number}
     * @memberof AreaFurnitureSpec
     */
    'depth_mm': number | null;
    /**
     * 
     * @type {number}
     * @memberof AreaFurnitureSpec
     */
    'height_mm': number | null;
    /**
     * 
     * @type {number}
     * @memberof AreaFurnitureSpec
     */
    'seat_height_mm': number | null;
    /**
     * 
     * @type {string}
     * @memberof AreaFurnitureSpec
     */
    'estimated_delivery_date': string | null;
    /**
     * 
     * @type {string}
     * @memberof AreaFurnitureSpec
     */
    'catalog_page_num': string | null;
    /**
     * 
     * @type {string}
     * @memberof AreaFurnitureSpec
     */
    'catalog_page_url': string | null;
    /**
     * 
     * @type {number}
     * @memberof AreaFurnitureSpec
     */
    'sai': number | null;
    /**
     * 
     * @type {string}
     * @memberof AreaFurnitureSpec
     */
    'product_description_text': string | null;
    /**
     * 
     * @type {string}
     * @memberof AreaFurnitureSpec
     */
    'order_code': string | null;
    /**
     * 
     * @type {string}
     * @memberof AreaFurnitureSpec
     */
    'series_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof AreaFurnitureSpec
     */
    'main_image_file_path': string | null;
    /**
     * 
     * @type {string}
     * @memberof AreaFurnitureSpec
     */
    'option_image_file_path1': string | null;
    /**
     * 
     * @type {string}
     * @memberof AreaFurnitureSpec
     */
    'option_image_file_path2': string | null;
    /**
     * 
     * @type {string}
     * @memberof AreaFurnitureSpec
     */
    'option_image_file_path3': string | null;
    /**
     * 
     * @type {string}
     * @memberof AreaFurnitureSpec
     */
    'option_name1': string | null;
    /**
     * 
     * @type {string}
     * @memberof AreaFurnitureSpec
     */
    'option_name2': string | null;
    /**
     * 
     * @type {string}
     * @memberof AreaFurnitureSpec
     */
    'option_name3': string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AreaType = {
    Rect: 'rect',
    Polygon: 'polygon',
    Circle: 'circle'
} as const;

export type AreaType = typeof AreaType[keyof typeof AreaType];


/**
 * 
 * @export
 * @interface AttachFavoriteFurnitureSetRequest
 */
export interface AttachFavoriteFurnitureSetRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof AttachFavoriteFurnitureSetRequest
     */
    'favorite_furniture_set_ids': Array<number>;
}
/**
 * 
 * @export
 * @interface AvailableOption
 */
export interface AvailableOption {
    /**
     * 
     * @type {number}
     * @memberof AvailableOption
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof AvailableOption
     */
    'option_place_num': number;
    /**
     * 
     * @type {string}
     * @memberof AvailableOption
     */
    'option_name': string;
    /**
     * 
     * @type {string}
     * @memberof AvailableOption
     */
    'option_code': string;
    /**
     * 
     * @type {string}
     * @memberof AvailableOption
     */
    'option_detail': string;
    /**
     * 
     * @type {string}
     * @memberof AvailableOption
     */
    'image_file_path'?: string;
    /**
     * 
     * @type {number}
     * @memberof AvailableOption
     */
    'manufacture_id': number;
    /**
     * 
     * @type {string}
     * @memberof AvailableOption
     */
    'prepend_part_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvailableOption
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof AvailableOption
     */
    'updated_at': string;
    /**
     * 
     * @type {number}
     * @memberof AvailableOption
     */
    'furniture_series_id': number;
    /**
     * 
     * @type {string}
     * @memberof AvailableOption
     */
    'option_price': string;
    /**
     * 
     * @type {number}
     * @memberof AvailableOption
     */
    'furniture_many_option_set_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AvailableOption
     */
    'disassembled_parts_number_position': number;
}
/**
 * 
 * @export
 * @interface BaseResponse
 */
export interface BaseResponse {
    /**
     * レスポンスメッセージ
     * @type {string}
     * @memberof BaseResponse
     */
    'msg'?: string;
    /**
     * レスポンスステータス
     * @type {boolean}
     * @memberof BaseResponse
     * @deprecated
     */
    'status'?: boolean;
}
/**
 * 会社情報
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * ID
     * @type {number}
     * @memberof Company
     */
    'id': number;
    /**
     * ユーザーID
     * @type {number}
     * @memberof Company
     */
    'user_id': number;
    /**
     * 会社名
     * @type {string}
     * @memberof Company
     */
    'company_name': string;
    /**
     * 郵便番号
     * @type {string}
     * @memberof Company
     */
    'postal_code': string;
    /**
     * 会社所在地
     * @type {string}
     * @memberof Company
     */
    'company_location': string;
    /**
     * 建物名・部屋番号
     * @type {string}
     * @memberof Company
     */
    'company_building': string | null;
    /**
     * 電話番号
     * @type {string}
     * @memberof Company
     */
    'company_telphone_number': string;
    /**
     * FAX番号
     * @type {string}
     * @memberof Company
     */
    'company_fax_number': string | null;
    /**
     * 支店名
     * @type {string}
     * @memberof Company
     */
    'company_branch_name': string | null;
    /**
     * 担当者名
     * @type {string}
     * @memberof Company
     */
    'manager_name': string;
}
/**
 * 
 * @export
 * @interface CreateAreaFurnitureRequest
 */
export interface CreateAreaFurnitureRequest {
    /**
     * カスタム家具かどうか
     * @type {boolean}
     * @memberof CreateAreaFurnitureRequest
     */
    'is_custom_furniture': boolean;
    /**
     * エリアID
     * @type {string}
     * @memberof CreateAreaFurnitureRequest
     */
    'area_id': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAreaFurnitureRequest
     */
    'main_image_file_path'?: string | null;
    /**
     * 注文コード
     * @type {string}
     * @memberof CreateAreaFurnitureRequest
     */
    'order_code'?: string | null;
    /**
     * 定価
     * @type {number}
     * @memberof CreateAreaFurnitureRequest
     */
    'standard_retail_price_excluding_tax'?: number | null;
    /**
     * W寸法
     * @type {number}
     * @memberof CreateAreaFurnitureRequest
     */
    'width'?: number | null;
    /**
     * D寸法
     * @type {number}
     * @memberof CreateAreaFurnitureRequest
     */
    'depth'?: number | null;
    /**
     * H寸法
     * @type {number}
     * @memberof CreateAreaFurnitureRequest
     */
    'height'?: number | null;
    /**
     * 掛率
     * @type {number}
     * @memberof CreateAreaFurnitureRequest
     */
    'betting_rate'?: number | null;
    /**
     * 売価
     * @type {number}
     * @memberof CreateAreaFurnitureRequest
     */
    'price'?: number | null;
    /**
     * 数量
     * @type {number}
     * @memberof CreateAreaFurnitureRequest
     */
    'quantity'?: number | null;
    /**
     * 単位
     * @type {string}
     * @memberof CreateAreaFurnitureRequest
     */
    'unit_name'?: string | null;
    /**
     * 原価
     * @type {number}
     * @memberof CreateAreaFurnitureRequest
     */
    'cost_excluding_tax'?: number | null;
    /**
     * 備考
     * @type {string}
     * @memberof CreateAreaFurnitureRequest
     */
    'notes'?: string | null;
    /**
     * 並び順
     * @type {number}
     * @memberof CreateAreaFurnitureRequest
     */
    'sort_order'?: number | null;
    /**
     * 任意メーカーID
     * @type {number}
     * @memberof CreateAreaFurnitureRequest
     */
    'custom_manufacture_id'?: number | null;
    /**
     * メーカーID
     * @type {number}
     * @memberof CreateAreaFurnitureRequest
     */
    'manufacture_id': number | null;
    /**
     * 品番
     * @type {string}
     * @memberof CreateAreaFurnitureRequest
     */
    'parts_number': string | null;
}
/**
 * 
 * @export
 * @interface CreateAreaRequest
 */
export interface CreateAreaRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateAreaRequest
     */
    'quotation_id': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAreaRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreateAreaRequest
     */
    'canvas_scale': number;
    /**
     * 
     * @type {AreaType}
     * @memberof CreateAreaRequest
     */
    'type': AreaType;
    /**
     * 
     * @type {CreateAreaRequestDefinition}
     * @memberof CreateAreaRequest
     */
    'definition': CreateAreaRequestDefinition;
}


/**
 * 
 * @export
 * @interface CreateAreaRequestDefinition
 */
export interface CreateAreaRequestDefinition {
    /**
     * 
     * @type {number}
     * @memberof CreateAreaRequestDefinition
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateAreaRequestDefinition
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateAreaRequestDefinition
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateAreaRequestDefinition
     */
    'y'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAreaRequestDefinition
     */
    'visible'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAreaRequestDefinition
     */
    'isNew'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateCustomFurnitureRequest
 */
export interface CreateCustomFurnitureRequest {
    /**
     * カスタム家具かどうか
     * @type {boolean}
     * @memberof CreateCustomFurnitureRequest
     */
    'is_custom_furniture': boolean;
    /**
     * エリアID
     * @type {string}
     * @memberof CreateCustomFurnitureRequest
     */
    'area_id': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomFurnitureRequest
     */
    'main_image_file_path'?: string | null;
    /**
     * 注文コード
     * @type {string}
     * @memberof CreateCustomFurnitureRequest
     */
    'order_code'?: string | null;
    /**
     * 定価
     * @type {number}
     * @memberof CreateCustomFurnitureRequest
     */
    'standard_retail_price_excluding_tax'?: number | null;
    /**
     * W寸法
     * @type {number}
     * @memberof CreateCustomFurnitureRequest
     */
    'width'?: number | null;
    /**
     * D寸法
     * @type {number}
     * @memberof CreateCustomFurnitureRequest
     */
    'depth'?: number | null;
    /**
     * H寸法
     * @type {number}
     * @memberof CreateCustomFurnitureRequest
     */
    'height'?: number | null;
    /**
     * 掛率
     * @type {number}
     * @memberof CreateCustomFurnitureRequest
     */
    'betting_rate'?: number | null;
    /**
     * 売価
     * @type {number}
     * @memberof CreateCustomFurnitureRequest
     */
    'price'?: number | null;
    /**
     * 数量
     * @type {number}
     * @memberof CreateCustomFurnitureRequest
     */
    'quantity'?: number | null;
    /**
     * 単位
     * @type {string}
     * @memberof CreateCustomFurnitureRequest
     */
    'unit_name'?: string | null;
    /**
     * 原価
     * @type {number}
     * @memberof CreateCustomFurnitureRequest
     */
    'cost_excluding_tax'?: number | null;
    /**
     * 備考
     * @type {string}
     * @memberof CreateCustomFurnitureRequest
     */
    'notes'?: string | null;
    /**
     * 並び順
     * @type {number}
     * @memberof CreateCustomFurnitureRequest
     */
    'sort_order'?: number | null;
    /**
     * 任意メーカーID
     * @type {number}
     * @memberof CreateCustomFurnitureRequest
     */
    'custom_manufacture_id'?: number | null;
    /**
     * 品番
     * @type {string}
     * @memberof CreateCustomFurnitureRequest
     */
    'parts_number'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateFavoriteFurnitureSet201Response
 */
export interface CreateFavoriteFurnitureSet201Response {
    /**
     * 
     * @type {FavoriteFurnitureSet}
     * @memberof CreateFavoriteFurnitureSet201Response
     */
    'data': FavoriteFurnitureSet;
}
/**
 * 
 * @export
 * @interface CreateFavoriteFurnitureSetRequest
 */
export interface CreateFavoriteFurnitureSetRequest {
    /**
     * お気に入りセット名
     * @type {string}
     * @memberof CreateFavoriteFurnitureSetRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CreateOrganization200Response
 */
export interface CreateOrganization200Response {
    /**
     * 
     * @type {Organization}
     * @memberof CreateOrganization200Response
     */
    'data': Organization;
}
/**
 * 
 * @export
 * @interface CreateOrganizationRequest
 */
export interface CreateOrganizationRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationRequest
     */
    'domain': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateOrganizationRequest
     */
    'manufacture_ids': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationRequest
     */
    'plan'?: CreateOrganizationRequestPlanEnum;
}

export const CreateOrganizationRequestPlanEnum = {
    MeasuredRate: 'measured_rate'
} as const;

export type CreateOrganizationRequestPlanEnum = typeof CreateOrganizationRequestPlanEnum[keyof typeof CreateOrganizationRequestPlanEnum];

/**
 * 
 * @export
 * @interface CreateUserRequest
 */
export interface CreateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    'email'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateUserRequest
     */
    'organization_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    'display_name'?: string;
}
/**
 * 
 * @export
 * @interface CustomFurniture
 */
export interface CustomFurniture {
    /**
     * CustomFurniture.id
     * @type {number}
     * @memberof CustomFurniture
     */
    'id': number;
    /**
     * エリアID
     * @type {string}
     * @memberof CustomFurniture
     */
    'area_id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomFurniture
     */
    'floor_quotation_id': string;
    /**
     * 定価
     * @type {number}
     * @memberof CustomFurniture
     */
    'standard_retail_price_excluding_tax': number | null;
    /**
     * 原価
     * @type {number}
     * @memberof CustomFurniture
     */
    'cost_excluding_tax': number | null;
    /**
     * 
     * @type {number}
     * @memberof CustomFurniture
     */
    'betting_rate': number | null;
    /**
     * 
     * @type {string}
     * @memberof CustomFurniture
     */
    'notes': string | null;
    /**
     * 
     * @type {number}
     * @memberof CustomFurniture
     */
    'is_custom_furniture': number;
    /**
     * 
     * @type {number}
     * @memberof CustomFurniture
     */
    'sort_order': number | null;
    /**
     * 
     * @type {string}
     * @memberof CustomFurniture
     */
    'option': string | null;
    /**
     * 施工ポイント
     * @type {number}
     * @memberof CustomFurniture
     */
    'construction_point': number;
    /**
     * 個別単価(定価×掛け率)
     * @type {number}
     * @memberof CustomFurniture
     */
    'price': number | null;
    /**
     * 最終単価(定価×掛け率×端数処理)  `定価×掛け率`の部分は、`price`カラムに値があればそちらを利用する 
     * @type {number}
     * @memberof CustomFurniture
     */
    'last_price'?: number;
    /**
     * 合計金額
     * @type {number}
     * @memberof CustomFurniture
     */
    'total_value': number;
    /**
     * 
     * @type {CustomFurnitureSpec}
     * @memberof CustomFurniture
     */
    'spec': CustomFurnitureSpec;
    /**
     * 
     * @type {FurnitureDisplay}
     * @memberof CustomFurniture
     */
    'display': FurnitureDisplay;
}
/**
 * 
 * @export
 * @interface CustomFurnitureSpec
 */
export interface CustomFurnitureSpec {
    /**
     * 品名
     * @type {string}
     * @memberof CustomFurnitureSpec
     */
    'product_series_name': string | null;
    /**
     * 品番
     * @type {string}
     * @memberof CustomFurnitureSpec
     */
    'parts_number'?: string | null;
    /**
     * 注文コード
     * @type {string}
     * @memberof CustomFurnitureSpec
     */
    'order_code': string | null;
    /**
     * ページ
     * @type {string}
     * @memberof CustomFurnitureSpec
     */
    'catalog_page_num': string | null;
    /**
     * W寸法
     * @type {number}
     * @memberof CustomFurnitureSpec
     */
    'width': number | null;
    /**
     * H寸法
     * @type {number}
     * @memberof CustomFurnitureSpec
     */
    'height': number | null;
    /**
     * D寸法
     * @type {number}
     * @memberof CustomFurnitureSpec
     */
    'depth': number | null;
    /**
     * 単位
     * @type {string}
     * @memberof CustomFurnitureSpec
     */
    'unit_name': string | null;
    /**
     * 画像ファイルパス
     * @type {string}
     * @memberof CustomFurnitureSpec
     */
    'main_image_file_path': string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomFurnitureSpec
     */
    'option_image_file_path1': string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomFurnitureSpec
     */
    'option_image_file_path2': string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomFurnitureSpec
     */
    'option_image_file_path3': string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomFurnitureSpec
     */
    'option_name1': string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomFurnitureSpec
     */
    'option_name2': string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomFurnitureSpec
     */
    'option_name3': string | null;
}
/**
 * 
 * @export
 * @interface DeleteFurnituresRequest
 */
export interface DeleteFurnituresRequest {
    /**
     * 
     * @type {Array<DeleteFurnituresRequestFurnituresInner>}
     * @memberof DeleteFurnituresRequest
     */
    'furnitures': Array<DeleteFurnituresRequestFurnituresInner>;
}
/**
 * 
 * @export
 * @interface DeleteFurnituresRequestFurnituresInner
 */
export interface DeleteFurnituresRequestFurnituresInner {
    /**
     * カスタム家具かどうか
     * @type {boolean}
     * @memberof DeleteFurnituresRequestFurnituresInner
     */
    'is_custom_furniture': boolean;
    /**
     * エリア家具ID or カスタム家具ID
     * @type {number}
     * @memberof DeleteFurnituresRequestFurnituresInner
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface DeleteQuotation200Response
 */
export interface DeleteQuotation200Response {
    /**
     * レスポンスメッセージ
     * @type {string}
     * @memberof DeleteQuotation200Response
     */
    'msg'?: string;
    /**
     * レスポンスステータス
     * @type {boolean}
     * @memberof DeleteQuotation200Response
     * @deprecated
     */
    'status'?: boolean;
    /**
     * 
     * @type {Array<FloorQuotationModel>}
     * @memberof DeleteQuotation200Response
     */
    'data': Array<FloorQuotationModel>;
}
/**
 * 
 * @export
 * @interface DeleteQuotationRequest
 */
export interface DeleteQuotationRequest {
    /**
     * 案件ID
     * @type {string}
     * @memberof DeleteQuotationRequest
     */
    'projectId': string;
}
/**
 * 経費セット
 * @export
 * @interface ExpenseSet
 */
export interface ExpenseSet {
    /**
     * 経費セットID
     * @type {number}
     * @memberof ExpenseSet
     */
    'id': number;
    /**
     * ユーザーID
     * @type {string}
     * @memberof ExpenseSet
     */
    'user_id': string;
    /**
     * 経費セット名
     * @type {string}
     * @memberof ExpenseSet
     */
    'name': string;
    /**
     * 経費セット内アイテム一覧
     * @type {Array<ExpenseSetItem>}
     * @memberof ExpenseSet
     */
    'expenses': Array<ExpenseSetItem>;
}
/**
 * 経費セット内アイテム
 * @export
 * @interface ExpenseSetItem
 */
export interface ExpenseSetItem {
    /**
     * 経費セット内アイテムID
     * @type {number}
     * @memberof ExpenseSetItem
     */
    'id': number;
    /**
     * 経費セットID
     * @type {number}
     * @memberof ExpenseSetItem
     */
    'expense_group_id': number;
    /**
     * 経費種類
     * @type {string}
     * @memberof ExpenseSetItem
     */
    'name': string;
    /**
     * 品番
     * @type {string}
     * @memberof ExpenseSetItem
     */
    'parts_number': string;
    /**
     * 備考
     * @type {string}
     * @memberof ExpenseSetItem
     */
    'notes': string;
    /**
     * 単価
     * @type {number}
     * @memberof ExpenseSetItem
     */
    'amount': number;
    /**
     * 原価
     * @type {number}
     * @memberof ExpenseSetItem
     */
    'cost_excluding_tax': number;
    /**
     * 数量
     * @type {number}
     * @memberof ExpenseSetItem
     */
    'qty': number;
    /**
     * 単位
     * @type {string}
     * @memberof ExpenseSetItem
     */
    'unit': string;
}
/**
 * 
 * @export
 * @interface ExtractPlanImage200Response
 */
export interface ExtractPlanImage200Response {
    /**
     * レスポンスメッセージ
     * @type {string}
     * @memberof ExtractPlanImage200Response
     */
    'msg'?: string;
    /**
     * レスポンスステータス
     * @type {boolean}
     * @memberof ExtractPlanImage200Response
     * @deprecated
     */
    'status'?: boolean;
    /**
     * 展開されたイメージ一覧
     * @type {Array<QuotationPlanImage>}
     * @memberof ExtractPlanImage200Response
     */
    'data': Array<QuotationPlanImage>;
}
/**
 * 
 * @export
 * @interface FavoriteFurnitureSet
 */
export interface FavoriteFurnitureSet {
    /**
     * 
     * @type {number}
     * @memberof FavoriteFurnitureSet
     */
    'id': number;
    /**
     * お気に入りセット名
     * @type {string}
     * @memberof FavoriteFurnitureSet
     */
    'name': string;
    /**
     * お気に入りセット詳細APIのみ返却する
     * @type {Array<UserFavoriteFurniture>}
     * @memberof FavoriteFurnitureSet
     */
    'favorite_furnitures'?: Array<UserFavoriteFurniture>;
}
/**
 * 
 * @export
 * @interface FloorQuotation
 */
export interface FloorQuotation {
    /**
     * 見積ID
     * @type {string}
     * @memberof FloorQuotation
     */
    'id': string;
    /**
     * 案件ID
     * @type {string}
     * @memberof FloorQuotation
     */
    'project_id': string;
    /**
     * 見積名
     * @type {string}
     * @memberof FloorQuotation
     */
    'name': string;
    /**
     * 端数処理種別  - 1: 設定なし - 2: 切り上げ - 3: 切り捨て - 4: 四捨五入 
     * @type {number}
     * @memberof FloorQuotation
     */
    'rounding_type': FloorQuotationRoundingTypeEnum;
    /**
     * 端数処理単位
     * @type {number}
     * @memberof FloorQuotation
     */
    'rounding_unit': FloorQuotationRoundingUnitEnum;
    /**
     * 掛け率
     * @type {number}
     * @memberof FloorQuotation
     */
    'betting_rate': number | null;
    /**
     * 顧客名
     * @type {string}
     * @memberof FloorQuotation
     */
    'client_name': string | null;
    /**
     * 納品先（受け渡し住所）
     * @type {string}
     * @memberof FloorQuotation
     */
    'client_company_address': string | null;
    /**
     * 支払い条件
     * @type {string}
     * @memberof FloorQuotation
     */
    'method_of_payment': string | null;
    /**
     * 見積有効期限
     * @type {string}
     * @memberof FloorQuotation
     */
    'quotation_expiration_date': string | null;
    /**
     * 見積書番号
     * @type {string}
     * @memberof FloorQuotation
     */
    'quotation_number': string | null;
    /**
     * 備考
     * @type {string}
     * @memberof FloorQuotation
     */
    'others': string | null;
    /**
     * 最終顧客名
     * @type {string}
     * @memberof FloorQuotation
     */
    'end_customer_name': string | null;
    /**
     * 想定納期
     * @type {string}
     * @memberof FloorQuotation
     */
    'expected_delivery_date': string | null;
    /**
     * 掛け率設定タイプ - `all` 一括設定 - `manufacture` 仕入先ごとに掛け率設定 
     * @type {string}
     * @memberof FloorQuotation
     */
    'betting_rate_type': FloorQuotationBettingRateTypeEnum;
    /**
     * 見積図面PDF
     * @type {string}
     * @memberof FloorQuotation
     */
    'image_pdf': string;
    /**
     * 経費
     * @type {Array<QuotationExpense>}
     * @memberof FloorQuotation
     */
    'expenses': Array<QuotationExpense>;
    /**
     * 
     * @type {FloorQuotationPlan}
     * @memberof FloorQuotation
     */
    'plan': FloorQuotationPlan;
    /**
     * 仕入先ごとの掛け率設定
     * @type {Array<ManufactureBettingRate>}
     * @memberof FloorQuotation
     */
    'manufacture_betting_rates': Array<ManufactureBettingRate>;
}

export const FloorQuotationRoundingTypeEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
} as const;

export type FloorQuotationRoundingTypeEnum = typeof FloorQuotationRoundingTypeEnum[keyof typeof FloorQuotationRoundingTypeEnum];
export const FloorQuotationRoundingUnitEnum = {
    NUMBER_1: 1,
    NUMBER_10: 10,
    NUMBER_100: 100,
    NUMBER_1000: 1000
} as const;

export type FloorQuotationRoundingUnitEnum = typeof FloorQuotationRoundingUnitEnum[keyof typeof FloorQuotationRoundingUnitEnum];
export const FloorQuotationBettingRateTypeEnum = {
    All: 'all',
    Manufacture: 'manufacture'
} as const;

export type FloorQuotationBettingRateTypeEnum = typeof FloorQuotationBettingRateTypeEnum[keyof typeof FloorQuotationBettingRateTypeEnum];

/**
 * 
 * @export
 * @interface FloorQuotationModel
 */
export interface FloorQuotationModel {
    /**
     * 見積ID
     * @type {string}
     * @memberof FloorQuotationModel
     */
    'id': string;
    /**
     * 案件ID
     * @type {string}
     * @memberof FloorQuotationModel
     */
    'project_id': string;
    /**
     * 見積名
     * @type {string}
     * @memberof FloorQuotationModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FloorQuotationModel
     * @deprecated
     */
    'floor_plan': string | null;
    /**
     * 
     * @type {string}
     * @memberof FloorQuotationModel
     */
    'created_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof FloorQuotationModel
     */
    'updated_at': string | null;
    /**
     * 端数処理種別  - 1: 設定なし - 2: 切り上げ - 3: 切り捨て - 4: 四捨五入 
     * @type {number}
     * @memberof FloorQuotationModel
     */
    'rounding_type': FloorQuotationModelRoundingTypeEnum;
    /**
     * 端数処理単位
     * @type {number}
     * @memberof FloorQuotationModel
     */
    'rounding_unit': FloorQuotationModelRoundingUnitEnum;
    /**
     * 掛け率
     * @type {number}
     * @memberof FloorQuotationModel
     */
    'betting_rate': number | null;
    /**
     * 顧客名
     * @type {string}
     * @memberof FloorQuotationModel
     */
    'client_name': string | null;
    /**
     * 納品先（受け渡し住所）
     * @type {string}
     * @memberof FloorQuotationModel
     */
    'client_company_address': string | null;
    /**
     * 支払い条件
     * @type {string}
     * @memberof FloorQuotationModel
     */
    'method_of_payment': string | null;
    /**
     * 見積有効期限
     * @type {string}
     * @memberof FloorQuotationModel
     */
    'quotation_expiration_date': string | null;
    /**
     * 見積書番号
     * @type {string}
     * @memberof FloorQuotationModel
     */
    'quotation_number': string | null;
    /**
     * 備考
     * @type {string}
     * @memberof FloorQuotationModel
     */
    'others': string | null;
    /**
     * 最終顧客名
     * @type {string}
     * @memberof FloorQuotationModel
     */
    'end_customer_name': string | null;
    /**
     * 想定納期
     * @type {string}
     * @memberof FloorQuotationModel
     */
    'expected_delivery_date': string | null;
    /**
     * 
     * @type {string}
     * @memberof FloorQuotationModel
     */
    'deleted_at': string | null;
    /**
     * 掛け率設定タイプ - `all` 一括設定 - `manufacture` 仕入先ごとに掛け率設定 
     * @type {string}
     * @memberof FloorQuotationModel
     */
    'betting_rate_type': FloorQuotationModelBettingRateTypeEnum;
    /**
     * 見積図面PDF
     * @type {string}
     * @memberof FloorQuotationModel
     */
    'image_pdf': string;
    /**
     * 経費
     * @type {Array<FloorQuotationModelExpensesInner>}
     * @memberof FloorQuotationModel
     */
    'expenses': Array<FloorQuotationModelExpensesInner>;
    /**
     * 
     * @type {FloorQuotationModelPlan}
     * @memberof FloorQuotationModel
     */
    'plan': FloorQuotationModelPlan;
}

export const FloorQuotationModelRoundingTypeEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
} as const;

export type FloorQuotationModelRoundingTypeEnum = typeof FloorQuotationModelRoundingTypeEnum[keyof typeof FloorQuotationModelRoundingTypeEnum];
export const FloorQuotationModelRoundingUnitEnum = {
    NUMBER_1: 1,
    NUMBER_10: 10,
    NUMBER_100: 100,
    NUMBER_1000: 1000
} as const;

export type FloorQuotationModelRoundingUnitEnum = typeof FloorQuotationModelRoundingUnitEnum[keyof typeof FloorQuotationModelRoundingUnitEnum];
export const FloorQuotationModelBettingRateTypeEnum = {
    All: 'all',
    Manufacture: 'manufacture'
} as const;

export type FloorQuotationModelBettingRateTypeEnum = typeof FloorQuotationModelBettingRateTypeEnum[keyof typeof FloorQuotationModelBettingRateTypeEnum];

/**
 * 
 * @export
 * @interface FloorQuotationModelExpensesInner
 */
export interface FloorQuotationModelExpensesInner {
    /**
     * 
     * @type {string}
     * @memberof FloorQuotationModelExpensesInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FloorQuotationModelExpensesInner
     */
    'floor_quotation_id': string;
    /**
     * 提出経費名
     * @type {string}
     * @memberof FloorQuotationModelExpensesInner
     */
    'name': string | null;
    /**
     * 品番
     * @type {string}
     * @memberof FloorQuotationModelExpensesInner
     */
    'parts_number': string | null;
    /**
     * 備考
     * @type {string}
     * @memberof FloorQuotationModelExpensesInner
     */
    'notes': string | null;
    /**
     * 単価
     * @type {number}
     * @memberof FloorQuotationModelExpensesInner
     */
    'amount': number | null;
    /**
     * 数量
     * @type {number}
     * @memberof FloorQuotationModelExpensesInner
     */
    'qty': number;
    /**
     * 単位
     * @type {string}
     * @memberof FloorQuotationModelExpensesInner
     */
    'unit': string;
    /**
     * 
     * @type {string}
     * @memberof FloorQuotationModelExpensesInner
     */
    'created_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof FloorQuotationModelExpensesInner
     */
    'updated_at': string | null;
    /**
     * ソート順序
     * @type {number}
     * @memberof FloorQuotationModelExpensesInner
     */
    'sort_order': number | null;
    /**
     * 定価
     * @type {number}
     * @memberof FloorQuotationModelExpensesInner
     */
    'standard_retail_price_excluding_tax': number | null;
    /**
     * 
     * @type {string}
     * @memberof FloorQuotationModelExpensesInner
     */
    'deleted_at': string | null;
    /**
     * 合計金額
     * @type {number}
     * @memberof FloorQuotationModelExpensesInner
     */
    'total_value': number;
}
/**
 * 見積図面
 * @export
 * @interface FloorQuotationModelPlan
 */
export interface FloorQuotationModelPlan {
    /**
     * 
     * @type {number}
     * @memberof FloorQuotationModelPlan
     */
    'id': number | null;
    /**
     * 
     * @type {string}
     * @memberof FloorQuotationModelPlan
     */
    'floor_quotation_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof FloorQuotationModelPlan
     */
    'file_name': string | null;
    /**
     * 
     * @type {number}
     * @memberof FloorQuotationModelPlan
     */
    'is_selected': number;
    /**
     * 
     * @type {string}
     * @memberof FloorQuotationModelPlan
     */
    'created_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof FloorQuotationModelPlan
     */
    'updated_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof FloorQuotationModelPlan
     */
    'file_path': string | null;
    /**
     * 
     * @type {string}
     * @memberof FloorQuotationModelPlan
     */
    'pdf_file_path': string | null;
}
/**
 * 見積図面
 * @export
 * @interface FloorQuotationPlan
 */
export interface FloorQuotationPlan {
    /**
     * 
     * @type {number}
     * @memberof FloorQuotationPlan
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FloorQuotationPlan
     */
    'floor_quotation_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FloorQuotationPlan
     */
    'file_name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FloorQuotationPlan
     */
    'is_selected'?: number;
    /**
     * 
     * @type {string}
     * @memberof FloorQuotationPlan
     */
    'created_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FloorQuotationPlan
     */
    'updated_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FloorQuotationPlan
     */
    'file_path'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FloorQuotationPlan
     */
    'pdf_file_path'?: string | null;
}
/**
 * 
 * @export
 * @interface Furniture
 */
export interface Furniture {
    /**
     * 
     * @type {number}
     * @memberof Furniture
     */
    'id': number;
    /**
     * 品名
     * @type {string}
     * @memberof Furniture
     */
    'product_series_name': string;
    /**
     * ページ
     * @type {string}
     * @memberof Furniture
     */
    'catalog_page_num': string;
    /**
     * 品番
     * @type {string}
     * @memberof Furniture
     */
    'parts_number': string;
    /**
     * 
     * @type {string}
     * @memberof Furniture
     */
    'order_code': string;
    /**
     * 寸法
     * @type {number}
     * @memberof Furniture
     */
    'width_mm': number;
    /**
     * 寸法
     * @type {number}
     * @memberof Furniture
     */
    'depth_mm': number;
    /**
     * 寸法
     * @type {number}
     * @memberof Furniture
     */
    'height_mm': number;
    /**
     * メイン画像パス
     * @type {string}
     * @memberof Furniture
     */
    'main_image_file_path': string;
    /**
     * 
     * @type {number}
     * @memberof Furniture
     */
    'furniture_series_id': number | null;
    /**
     * 
     * @type {number}
     * @memberof Furniture
     */
    'manufacture_id': number;
    /**
     * 
     * @type {string}
     * @memberof Furniture
     */
    'manufacture_name': string;
    /**
     * 
     * @type {string}
     * @memberof Furniture
     */
    'parts_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof Furniture
     */
    'unit_name': string | null;
    /**
     * 
     * @type {number}
     * @memberof Furniture
     */
    'standard_retail_price_excluding_tax': number | null;
    /**
     * 
     * @type {number}
     * @memberof Furniture
     */
    'seat_height_mm': number | null;
    /**
     * 
     * @type {string}
     * @memberof Furniture
     */
    'estimated_delivery_date': string | null;
    /**
     * 
     * @type {string}
     * @memberof Furniture
     */
    'catalog_page_url': string | null;
    /**
     * 
     * @type {number}
     * @memberof Furniture
     */
    'sai': number | null;
    /**
     * 
     * @type {string}
     * @memberof Furniture
     */
    'product_description_text': string | null;
    /**
     * 
     * @type {number}
     * @memberof Furniture
     */
    'construction_point': number | null;
    /**
     * 
     * @type {number}
     * @memberof Furniture
     */
    'quantity': number | null;
    /**
     * 
     * @type {FurnitureSeries}
     * @memberof Furniture
     */
    'series': FurnitureSeries;
}
/**
 * 
 * @export
 * @interface FurnitureCategory
 */
export interface FurnitureCategory {
    /**
     * 
     * @type {number}
     * @memberof FurnitureCategory
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof FurnitureCategory
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof FurnitureCategory
     */
    'sort_order': number;
    /**
     * 
     * @type {string}
     * @memberof FurnitureCategory
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof FurnitureCategory
     */
    'updated_at': string;
    /**
     * 
     * @type {Array<FurnitureSubCategory>}
     * @memberof FurnitureCategory
     */
    'sub_categories'?: Array<FurnitureSubCategory>;
}
/**
 * 表示用の家具情報
 * @export
 * @interface FurnitureDisplay
 */
export interface FurnitureDisplay {
    /**
     * 
     * @type {number}
     * @memberof FurnitureDisplay
     */
    'manufacture_id': number | null;
    /**
     * 
     * @type {string}
     * @memberof FurnitureDisplay
     */
    'manufacture_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof FurnitureDisplay
     */
    'parts_number': string | null;
    /**
     * 
     * @type {string}
     * @memberof FurnitureDisplay
     */
    'parts_name': string | null;
    /**
     * 
     * @type {number}
     * @memberof FurnitureDisplay
     */
    'width': number | null;
    /**
     * 
     * @type {number}
     * @memberof FurnitureDisplay
     */
    'depth': number | null;
    /**
     * 
     * @type {number}
     * @memberof FurnitureDisplay
     */
    'height': number | null;
    /**
     * 
     * @type {string}
     * @memberof FurnitureDisplay
     */
    'order_code': string | null;
    /**
     * 
     * @type {string}
     * @memberof FurnitureDisplay
     */
    'main_image_file_path': string | null;
    /**
     * 
     * @type {string}
     * @memberof FurnitureDisplay
     */
    'option_image_file_path1': string | null;
    /**
     * 
     * @type {string}
     * @memberof FurnitureDisplay
     */
    'option_image_file_path2': string | null;
    /**
     * 
     * @type {string}
     * @memberof FurnitureDisplay
     */
    'option_image_file_path3': string | null;
    /**
     * 
     * @type {string}
     * @memberof FurnitureDisplay
     */
    'option_name1': string | null;
    /**
     * 
     * @type {string}
     * @memberof FurnitureDisplay
     */
    'option_name2': string | null;
    /**
     * 
     * @type {string}
     * @memberof FurnitureDisplay
     */
    'option_name3': string | null;
}
/**
 * 
 * @export
 * @interface FurnitureManyOption
 */
export interface FurnitureManyOption {
    /**
     * 
     * @type {number}
     * @memberof FurnitureManyOption
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof FurnitureManyOption
     */
    'furniture_many_option_set_id': number;
    /**
     * 
     * @type {string}
     * @memberof FurnitureManyOption
     */
    'many_option_code': string;
    /**
     * 
     * @type {string}
     * @memberof FurnitureManyOption
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FurnitureManyOption
     */
    'image_file_path': string | null;
}
/**
 * 
 * @export
 * @interface FurnitureManyOptionSet
 */
export interface FurnitureManyOptionSet {
    /**
     * 
     * @type {number}
     * @memberof FurnitureManyOptionSet
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof FurnitureManyOptionSet
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof FurnitureManyOptionSet
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof FurnitureManyOptionSet
     */
    'sort_order': number | null;
    /**
     * 
     * @type {Array<FurnitureManyOption>}
     * @memberof FurnitureManyOptionSet
     */
    'options': Array<FurnitureManyOption>;
}
/**
 * 
 * @export
 * @interface FurnitureOption
 */
export interface FurnitureOption {
    /**
     * 
     * @type {number}
     * @memberof FurnitureOption
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof FurnitureOption
     */
    'furniture_series_id': number;
    /**
     * 
     * @type {number}
     * @memberof FurnitureOption
     */
    'manufacture_id': number;
    /**
     * 
     * @type {number}
     * @memberof FurnitureOption
     */
    'option_place_num': number;
    /**
     * 
     * @type {string}
     * @memberof FurnitureOption
     */
    'option_name': string;
    /**
     * 
     * @type {string}
     * @memberof FurnitureOption
     */
    'option_code': string | null;
    /**
     * 
     * @type {string}
     * @memberof FurnitureOption
     */
    'option_detail': string | null;
    /**
     * 
     * @type {string}
     * @memberof FurnitureOption
     */
    'image_file_path': string | null;
    /**
     * 
     * @type {number}
     * @memberof FurnitureOption
     */
    'option_price': number | null;
    /**
     * 
     * @type {string}
     * @memberof FurnitureOption
     */
    'prepend_part_number': string | null;
    /**
     * 
     * @type {string}
     * @memberof FurnitureOption
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof FurnitureOption
     */
    'updated_at': string;
    /**
     * 
     * @type {number}
     * @memberof FurnitureOption
     */
    'furniture_many_option_set_id': number | null;
    /**
     * 
     * @type {number}
     * @memberof FurnitureOption
     */
    'disassembled_parts_number_position': number;
}
/**
 * ファニチャーシリーズ情報
 * @export
 * @interface FurnitureSeries
 */
export interface FurnitureSeries {
    /**
     * 
     * @type {number}
     * @memberof FurnitureSeries
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof FurnitureSeries
     */
    'product_series_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof FurnitureSeries
     */
    'additional_search_word': string | null;
    /**
     * 
     * @type {number}
     * @memberof FurnitureSeries
     */
    'min_price': number | null;
    /**
     * 
     * @type {number}
     * @memberof FurnitureSeries
     */
    'max_price': number | null;
    /**
     * 
     * @type {number}
     * @memberof FurnitureSeries
     */
    'manufacture_id': number;
    /**
     * 
     * @type {string}
     * @memberof FurnitureSeries
     */
    'main_image_file_path': string | null;
    /**
     * 
     * @type {number}
     * @memberof FurnitureSeries
     */
    'min_depth': number | null;
    /**
     * 
     * @type {number}
     * @memberof FurnitureSeries
     */
    'max_depth': number | null;
    /**
     * 
     * @type {number}
     * @memberof FurnitureSeries
     */
    'min_width': number | null;
    /**
     * 
     * @type {number}
     * @memberof FurnitureSeries
     */
    'max_width': number | null;
    /**
     * 
     * @type {number}
     * @memberof FurnitureSeries
     */
    'min_height': number | null;
    /**
     * 
     * @type {number}
     * @memberof FurnitureSeries
     */
    'max_height': number | null;
    /**
     * 
     * @type {string}
     * @memberof FurnitureSeries
     */
    'catalog_page_url': string | null;
    /**
     * 
     * @type {number}
     * @memberof FurnitureSeries
     */
    'furniture_order': number | null;
    /**
     * 
     * @type {string}
     * @memberof FurnitureSeries
     */
    'parts_number_head': string | null;
    /**
     * 
     * @type {number}
     * @memberof FurnitureSeries
     */
    'is_searchable': FurnitureSeriesIsSearchableEnum;
    /**
     * 
     * @type {Manufacture}
     * @memberof FurnitureSeries
     */
    'manufacture'?: Manufacture;
}

export const FurnitureSeriesIsSearchableEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type FurnitureSeriesIsSearchableEnum = typeof FurnitureSeriesIsSearchableEnum[keyof typeof FurnitureSeriesIsSearchableEnum];

/**
 * 
 * @export
 * @interface FurnitureSeriesSubCategories
 */
export interface FurnitureSeriesSubCategories {
    /**
     * 
     * @type {number}
     * @memberof FurnitureSeriesSubCategories
     */
    'furniture_series_id': number;
    /**
     * 
     * @type {number}
     * @memberof FurnitureSeriesSubCategories
     */
    'furniture_sub_category_id': number;
    /**
     * 
     * @type {FurnitureSubCategory}
     * @memberof FurnitureSeriesSubCategories
     */
    'furniture_sub_category'?: FurnitureSubCategory;
}
/**
 * 家具スタンプ
 * @export
 * @interface FurnitureStamp
 */
export interface FurnitureStamp {
    /**
     * 
     * @type {number}
     * @memberof FurnitureStamp
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof FurnitureStamp
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof FurnitureStamp
     */
    'y': number;
    /**
     * 
     * @type {number}
     * @memberof FurnitureStamp
     */
    'canvas_scale': number;
    /**
     * 
     * @type {number}
     * @memberof FurnitureStamp
     */
    'area_definition_id': number;
    /**
     * 
     * @type {string}
     * @memberof FurnitureStamp
     */
    'fill': string;
    /**
     * 
     * @type {number}
     * @memberof FurnitureStamp
     */
    'area_id': number;
    /**
     * 
     * @type {string}
     * @memberof FurnitureStamp
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof FurnitureStamp
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface FurnitureSubCategory
 */
export interface FurnitureSubCategory {
    /**
     * 
     * @type {number}
     * @memberof FurnitureSubCategory
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof FurnitureSubCategory
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof FurnitureSubCategory
     */
    'sort_order': number;
    /**
     * 
     * @type {string}
     * @memberof FurnitureSubCategory
     */
    'image_file_path': string;
    /**
     * 
     * @type {string}
     * @memberof FurnitureSubCategory
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof FurnitureSubCategory
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface GetAreaByQuotation200Response
 */
export interface GetAreaByQuotation200Response {
    /**
     * レスポンスメッセージ
     * @type {string}
     * @memberof GetAreaByQuotation200Response
     */
    'msg'?: string;
    /**
     * レスポンスステータス
     * @type {boolean}
     * @memberof GetAreaByQuotation200Response
     * @deprecated
     */
    'status'?: boolean;
    /**
     * 
     * @type {Array<Area>}
     * @memberof GetAreaByQuotation200Response
     */
    'data'?: Array<Area>;
}
/**
 * 
 * @export
 * @interface GetBillingUsage200Response
 */
export interface GetBillingUsage200Response {
    /**
     * 
     * @type {GetBillingUsage200ResponseData}
     * @memberof GetBillingUsage200Response
     */
    'data': GetBillingUsage200ResponseData;
}
/**
 * 
 * @export
 * @interface GetBillingUsage200ResponseData
 */
export interface GetBillingUsage200ResponseData {
    /**
     * 追加された家具の数
     * @type {number}
     * @memberof GetBillingUsage200ResponseData
     */
    'furniture_added_count': number;
    /**
     * 利用金額合計
     * @type {number}
     * @memberof GetBillingUsage200ResponseData
     */
    'monthly_charge': number;
}
/**
 * 
 * @export
 * @interface GetCompany200Response
 */
export interface GetCompany200Response {
    /**
     * 
     * @type {Company}
     * @memberof GetCompany200Response
     */
    'data': Company;
}
/**
 * 
 * @export
 * @interface GetExpenseSets200Response
 */
export interface GetExpenseSets200Response {
    /**
     * レスポンスメッセージ
     * @type {string}
     * @memberof GetExpenseSets200Response
     */
    'msg'?: string;
    /**
     * レスポンスステータス
     * @type {boolean}
     * @memberof GetExpenseSets200Response
     * @deprecated
     */
    'status'?: boolean;
    /**
     * 
     * @type {Array<ExpenseSet>}
     * @memberof GetExpenseSets200Response
     */
    'data': Array<ExpenseSet>;
}
/**
 * 
 * @export
 * @interface GetFavoriteFurnitureSets200Response
 */
export interface GetFavoriteFurnitureSets200Response {
    /**
     * 
     * @type {Array<FavoriteFurnitureSet>}
     * @memberof GetFavoriteFurnitureSets200Response
     */
    'data': Array<FavoriteFurnitureSet>;
}
/**
 * 
 * @export
 * @interface GetFurnitureOptions200Response
 */
export interface GetFurnitureOptions200Response {
    /**
     * 
     * @type {number}
     * @memberof GetFurnitureOptions200Response
     */
    'current_page': number | null;
    /**
     * 
     * @type {number}
     * @memberof GetFurnitureOptions200Response
     */
    'from': number | null;
    /**
     * 
     * @type {number}
     * @memberof GetFurnitureOptions200Response
     */
    'to': number | null;
    /**
     * 
     * @type {number}
     * @memberof GetFurnitureOptions200Response
     */
    'total': number | null;
    /**
     * 
     * @type {number}
     * @memberof GetFurnitureOptions200Response
     */
    'last_page': number | null;
    /**
     * 
     * @type {number}
     * @memberof GetFurnitureOptions200Response
     */
    'per_page': number | null;
    /**
     * 
     * @type {string}
     * @memberof GetFurnitureOptions200Response
     */
    'first_page_url': string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFurnitureOptions200Response
     */
    'last_page_url': string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFurnitureOptions200Response
     */
    'next_page_url': string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFurnitureOptions200Response
     */
    'prev_page_url': string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFurnitureOptions200Response
     */
    'path': string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetFurnitureOptions200Response
     */
    'links': Array<string> | null;
    /**
     * 
     * @type {Array<FurnitureOption>}
     * @memberof GetFurnitureOptions200Response
     */
    'data': Array<FurnitureOption>;
}
/**
 * 
 * @export
 * @interface GetFurnitureSeriesResponse
 */
export interface GetFurnitureSeriesResponse {
    /**
     * 
     * @type {{ [key: string]: Array<GetFurnitureSeriesResponseOptionsValueInner>; }}
     * @memberof GetFurnitureSeriesResponse
     */
    'options': { [key: string]: Array<GetFurnitureSeriesResponseOptionsValueInner>; };
    /**
     * 
     * @type {Array<Array<AllAvailableParts>>}
     * @memberof GetFurnitureSeriesResponse
     */
    'all_available_parts': Array<Array<AllAvailableParts>>;
    /**
     * 
     * @type {{ [key: string]: Array<AvailableOption>; }}
     * @memberof GetFurnitureSeriesResponse
     */
    'available_options': { [key: string]: Array<AvailableOption>; };
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof GetFurnitureSeriesResponse
     */
    'available_parts': { [key: string]: Array<string>; };
    /**
     * 
     * @type {{ [key: string]: Array<FurnitureManyOptionSet>; }}
     * @memberof GetFurnitureSeriesResponse
     */
    'furniture_many_option_sets': { [key: string]: Array<FurnitureManyOptionSet>; };
}
/**
 * 
 * @export
 * @interface GetFurnitureSeriesResponseOptionsValueInner
 */
export interface GetFurnitureSeriesResponseOptionsValueInner {
    /**
     * 
     * @type {number}
     * @memberof GetFurnitureSeriesResponseOptionsValueInner
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof GetFurnitureSeriesResponseOptionsValueInner
     */
    'option_place_num': number;
    /**
     * 
     * @type {string}
     * @memberof GetFurnitureSeriesResponseOptionsValueInner
     */
    'option_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetFurnitureSeriesResponseOptionsValueInner
     */
    'option_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetFurnitureSeriesResponseOptionsValueInner
     */
    'option_detail': string;
    /**
     * 
     * @type {string}
     * @memberof GetFurnitureSeriesResponseOptionsValueInner
     */
    'image_file_path'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetFurnitureSeriesResponseOptionsValueInner
     */
    'manufacture_id': number;
    /**
     * 
     * @type {string}
     * @memberof GetFurnitureSeriesResponseOptionsValueInner
     */
    'prepend_part_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFurnitureSeriesResponseOptionsValueInner
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof GetFurnitureSeriesResponseOptionsValueInner
     */
    'updated_at': string;
    /**
     * 
     * @type {number}
     * @memberof GetFurnitureSeriesResponseOptionsValueInner
     */
    'furniture_series_id': number;
    /**
     * 
     * @type {string}
     * @memberof GetFurnitureSeriesResponseOptionsValueInner
     */
    'option_price': string;
    /**
     * 
     * @type {number}
     * @memberof GetFurnitureSeriesResponseOptionsValueInner
     */
    'furniture_many_option_set_id': number;
    /**
     * 
     * @type {number}
     * @memberof GetFurnitureSeriesResponseOptionsValueInner
     */
    'disassembled_parts_number_position': number;
}
/**
 * 
 * @export
 * @interface GetFurnitureTags200Response
 */
export interface GetFurnitureTags200Response {
    /**
     * 
     * @type {Array<Tag>}
     * @memberof GetFurnitureTags200Response
     */
    'data': Array<Tag>;
}
/**
 * 
 * @export
 * @interface GetKiribariTemplates200Response
 */
export interface GetKiribariTemplates200Response {
    /**
     * レスポンスメッセージ
     * @type {string}
     * @memberof GetKiribariTemplates200Response
     */
    'msg'?: string;
    /**
     * レスポンスステータス
     * @type {boolean}
     * @memberof GetKiribariTemplates200Response
     * @deprecated
     */
    'status'?: boolean;
    /**
     * 
     * @type {Array<KiribariTemplate>}
     * @memberof GetKiribariTemplates200Response
     */
    'data'?: Array<KiribariTemplate>;
}
/**
 * 
 * @export
 * @interface GetManufactures200Response
 */
export interface GetManufactures200Response {
    /**
     * 
     * @type {Array<Manufacture>}
     * @memberof GetManufactures200Response
     */
    'data': Array<Manufacture>;
}
/**
 * 
 * @export
 * @interface GetOrganizationManufactures200Response
 */
export interface GetOrganizationManufactures200Response {
    /**
     * レスポンスメッセージ
     * @type {string}
     * @memberof GetOrganizationManufactures200Response
     */
    'msg'?: string;
    /**
     * レスポンスステータス
     * @type {boolean}
     * @memberof GetOrganizationManufactures200Response
     * @deprecated
     */
    'status'?: boolean;
    /**
     * 
     * @type {Array<Manufacture>}
     * @memberof GetOrganizationManufactures200Response
     */
    'data': Array<Manufacture>;
}
/**
 * 
 * @export
 * @interface GetOrganizations200Response
 */
export interface GetOrganizations200Response {
    /**
     * 
     * @type {PaginatedResourceResponseLinks}
     * @memberof GetOrganizations200Response
     */
    'links': PaginatedResourceResponseLinks;
    /**
     * 
     * @type {PaginatedResourceResponseMeta}
     * @memberof GetOrganizations200Response
     */
    'meta': PaginatedResourceResponseMeta;
    /**
     * 
     * @type {Array<Organization>}
     * @memberof GetOrganizations200Response
     */
    'data'?: Array<Organization>;
}
/**
 * 
 * @export
 * @interface GetProject200Response
 */
export interface GetProject200Response {
    /**
     * レスポンスメッセージ
     * @type {string}
     * @memberof GetProject200Response
     */
    'msg'?: string;
    /**
     * レスポンスステータス
     * @type {boolean}
     * @memberof GetProject200Response
     * @deprecated
     */
    'status'?: boolean;
    /**
     * 
     * @type {Project}
     * @memberof GetProject200Response
     */
    'data': Project;
}
/**
 * 
 * @export
 * @interface GetProjects200Response
 */
export interface GetProjects200Response {
    /**
     * 
     * @type {PaginatedResourceResponseLinks}
     * @memberof GetProjects200Response
     */
    'links': PaginatedResourceResponseLinks;
    /**
     * 
     * @type {PaginatedResourceResponseMeta}
     * @memberof GetProjects200Response
     */
    'meta': PaginatedResourceResponseMeta;
    /**
     * 
     * @type {Array<Project>}
     * @memberof GetProjects200Response
     */
    'data': Array<Project>;
}
/**
 * 
 * @export
 * @interface GetUser200Response
 */
export interface GetUser200Response {
    /**
     * 
     * @type {LoginUserInfo}
     * @memberof GetUser200Response
     */
    'data': LoginUserInfo;
}
/**
 * 
 * @export
 * @interface GetUsers200Response
 */
export interface GetUsers200Response {
    /**
     * レスポンスメッセージ
     * @type {string}
     * @memberof GetUsers200Response
     */
    'msg'?: string;
    /**
     * レスポンスステータス
     * @type {boolean}
     * @memberof GetUsers200Response
     * @deprecated
     */
    'status'?: boolean;
    /**
     * 
     * @type {Array<User>}
     * @memberof GetUsers200Response
     */
    'data'?: Array<User>;
}
/**
 * 
 * @export
 * @interface KiribariTemplate
 */
export interface KiribariTemplate {
    /**
     * 
     * @type {number}
     * @memberof KiribariTemplate
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof KiribariTemplate
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof KiribariTemplate
     */
    'template_path': string;
    /**
     * 
     * @type {number}
     * @memberof KiribariTemplate
     */
    'furniture_count': number;
    /**
     * 
     * @type {number}
     * @memberof KiribariTemplate
     */
    'organization_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KiribariTemplate
     */
    'template_type': string;
    /**
     * 
     * @type {string}
     * @memberof KiribariTemplate
     */
    'template_image': string;
    /**
     * 
     * @type {boolean}
     * @memberof KiribariTemplate
     */
    'has_drawing'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof KiribariTemplate
     */
    'drawing_cols'?: number;
    /**
     * 
     * @type {number}
     * @memberof KiribariTemplate
     */
    'drawing_rows'?: number;
    /**
     * 
     * @type {number}
     * @memberof KiribariTemplate
     */
    'template_cols'?: number;
    /**
     * 
     * @type {number}
     * @memberof KiribariTemplate
     */
    'template_rows'?: number;
    /**
     * 
     * @type {number}
     * @memberof KiribariTemplate
     */
    'is_excel_export'?: number;
    /**
     * 
     * @type {number}
     * @memberof KiribariTemplate
     */
    'is_power_point_export'?: number;
}
/**
 * 
 * @export
 * @interface LinkFurnituresToSeries200Response
 */
export interface LinkFurnituresToSeries200Response {
    /**
     * レスポンスメッセージ
     * @type {string}
     * @memberof LinkFurnituresToSeries200Response
     */
    'msg'?: string;
    /**
     * レスポンスステータス
     * @type {boolean}
     * @memberof LinkFurnituresToSeries200Response
     * @deprecated
     */
    'status'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof LinkFurnituresToSeries200Response
     */
    'data': Array<string>;
}
/**
 * 
 * @export
 * @interface LoginAdmin
 */
export interface LoginAdmin {
    /**
     * 
     * @type {LoginAdminData}
     * @memberof LoginAdmin
     */
    'data'?: LoginAdminData;
    /**
     * 
     * @type {boolean}
     * @memberof LoginAdmin
     */
    'status'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoginAdmin
     */
    'msg'?: string;
}
/**
 * 
 * @export
 * @interface LoginAdminData
 */
export interface LoginAdminData {
    /**
     * 
     * @type {string}
     * @memberof LoginAdminData
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginAdminData
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface LoginUser
 */
export interface LoginUser {
    /**
     * 
     * @type {LoginUserData}
     * @memberof LoginUser
     */
    'data': LoginUserData;
    /**
     * 
     * @type {boolean}
     * @memberof LoginUser
     */
    'status': boolean;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    'msg': string;
}
/**
 * 
 * @export
 * @interface LoginUserData
 */
export interface LoginUserData {
    /**
     * 
     * @type {string}
     * @memberof LoginUserData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LoginUserData
     */
    'display_name': string;
    /**
     * 
     * @type {string}
     * @memberof LoginUserData
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof LoginUserData
     */
    'phone': string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginUserData
     */
    'firebase_uid': string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginUserData
     */
    'firebase_provider': string | null;
    /**
     * 
     * @type {number}
     * @memberof LoginUserData
     */
    'status': LoginUserDataStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof LoginUserData
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof LoginUserData
     */
    'updated_at': string;
    /**
     * 
     * @type {Array<LoginUserDataFurnitureManufacturerInner>}
     * @memberof LoginUserData
     */
    'furniture_manufacturer'?: Array<LoginUserDataFurnitureManufacturerInner>;
}

export const LoginUserDataStatusEnum = {
    NUMBER_1: 1
} as const;

export type LoginUserDataStatusEnum = typeof LoginUserDataStatusEnum[keyof typeof LoginUserDataStatusEnum];

/**
 * 
 * @export
 * @interface LoginUserDataFurnitureManufacturerInner
 */
export interface LoginUserDataFurnitureManufacturerInner {
    /**
     * 
     * @type {number}
     * @memberof LoginUserDataFurnitureManufacturerInner
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof LoginUserDataFurnitureManufacturerInner
     */
    'manufacture_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginUserDataFurnitureManufacturerInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginUserDataFurnitureManufacturerInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {LoginUserDataFurnitureManufacturerInnerPivot}
     * @memberof LoginUserDataFurnitureManufacturerInner
     */
    'pivot'?: LoginUserDataFurnitureManufacturerInnerPivot;
}
/**
 * 
 * @export
 * @interface LoginUserDataFurnitureManufacturerInnerPivot
 */
export interface LoginUserDataFurnitureManufacturerInnerPivot {
    /**
     * 
     * @type {string}
     * @memberof LoginUserDataFurnitureManufacturerInnerPivot
     */
    'user_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoginUserDataFurnitureManufacturerInnerPivot
     */
    'furniture_manufacture_id'?: number;
}
/**
 * 
 * @export
 * @interface LoginUserInfo
 */
export interface LoginUserInfo {
    /**
     * 
     * @type {string}
     * @memberof LoginUserInfo
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LoginUserInfo
     */
    'display_name': string;
    /**
     * 
     * @type {string}
     * @memberof LoginUserInfo
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof LoginUserInfo
     */
    'phone': string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginUserInfo
     */
    'firebase_uid': string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginUserInfo
     */
    'firebase_provider': string | null;
    /**
     * 
     * @type {number}
     * @memberof LoginUserInfo
     */
    'status': LoginUserInfoStatusEnum;
    /**
     * ログインユーザがもつrolesのname
     * @type {Array<string>}
     * @memberof LoginUserInfo
     */
    'roles': Array<string>;
    /**
     * 
     * @type {LoginUserInfoFeatures}
     * @memberof LoginUserInfo
     */
    'features': LoginUserInfoFeatures;
    /**
     * 
     * @type {LoginUserInfoOrganization}
     * @memberof LoginUserInfo
     */
    'organization': LoginUserInfoOrganization;
}

export const LoginUserInfoStatusEnum = {
    NUMBER_1: 1
} as const;

export type LoginUserInfoStatusEnum = typeof LoginUserInfoStatusEnum[keyof typeof LoginUserInfoStatusEnum];

/**
 * ユーザが利用可能な機能の詳細。
 * @export
 * @interface LoginUserInfoFeatures
 */
export interface LoginUserInfoFeatures {
    /**
     * 
     * @type {LoginUserInfoFeaturesIwos}
     * @memberof LoginUserInfoFeatures
     */
    'iwos': LoginUserInfoFeaturesIwos;
    /**
     * 
     * @type {LoginUserInfoFeaturesKas}
     * @memberof LoginUserInfoFeatures
     */
    'kas': LoginUserInfoFeaturesKas;
    /**
     * 
     * @type {LoginUserInfoFeaturesNaiki}
     * @memberof LoginUserInfoFeatures
     */
    'naiki': LoginUserInfoFeaturesNaiki;
}
/**
 * IWOS関連機能。
 * @export
 * @interface LoginUserInfoFeaturesIwos
 */
export interface LoginUserInfoFeaturesIwos {
    /**
     * IWOSダウンロードが利用可能な場合は `true`
     * @type {boolean}
     * @memberof LoginUserInfoFeaturesIwos
     */
    'download': boolean;
}
/**
 * コクヨ社の KAS ファイル関連機能。
 * @export
 * @interface LoginUserInfoFeaturesKas
 */
export interface LoginUserInfoFeaturesKas {
    /**
     * KASファイルダウンロードが利用可能な場合は `true` 
     * @type {boolean}
     * @memberof LoginUserInfoFeaturesKas
     */
    'download': boolean;
}
/**
 * NAIKI関連機能。
 * @export
 * @interface LoginUserInfoFeaturesNaiki
 */
export interface LoginUserInfoFeaturesNaiki {
    /**
     * 見積書XMLダウンロードが利用可能な場合は `true`
     * @type {boolean}
     * @memberof LoginUserInfoFeaturesNaiki
     */
    'quotation_xml_download': boolean;
}
/**
 * 
 * @export
 * @interface LoginUserInfoOrganization
 */
export interface LoginUserInfoOrganization {
    /**
     * 
     * @type {number}
     * @memberof LoginUserInfoOrganization
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof LoginUserInfoOrganization
     */
    'domain'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginUserInfoOrganization
     */
    'logo_path'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginUserInfoOrganization
     */
    'excel_cover_path'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginUserInfoOrganization
     */
    'plan'?: string | null;
}
/**
 * 
 * @export
 * @interface LoginWithFirebase401Response
 */
export interface LoginWithFirebase401Response {
    /**
     * エラーメッセージ
     * @type {string}
     * @memberof LoginWithFirebase401Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface LoginWithFirebaseRequest
 */
export interface LoginWithFirebaseRequest {
    /**
     * Firebaseトークン
     * @type {string}
     * @memberof LoginWithFirebaseRequest
     */
    'firebase_id_token': string;
}
/**
 * 
 * @export
 * @interface Manufacture
 */
export interface Manufacture {
    /**
     * メーカーID
     * @type {number}
     * @memberof Manufacture
     */
    'id': number;
    /**
     * メーカー名
     * @type {string}
     * @memberof Manufacture
     */
    'name': string;
    /**
     * 追加検索ワード
     * @type {string}
     * @memberof Manufacture
     */
    'additional_search_word': string | null;
    /**
     * 
     * @type {string}
     * @memberof Manufacture
     */
    'code': string | null;
}
/**
 * 仕入先の掛け率設定
 * @export
 * @interface ManufactureBettingRate
 */
export interface ManufactureBettingRate {
    /**
     * 
     * @type {number}
     * @memberof ManufactureBettingRate
     */
    'manufacture_id': number;
    /**
     * 
     * @type {Manufacture}
     * @memberof ManufactureBettingRate
     */
    'manufacture': Manufacture;
    /**
     * 
     * @type {number}
     * @memberof ManufactureBettingRate
     */
    'betting_rate': number | null;
    /**
     * 
     * @type {number}
     * @memberof ManufactureBettingRate
     */
    'rounding_type': ManufactureBettingRateRoundingTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ManufactureBettingRate
     */
    'rounding_unit': ManufactureBettingRateRoundingUnitEnum;
}

export const ManufactureBettingRateRoundingTypeEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
} as const;

export type ManufactureBettingRateRoundingTypeEnum = typeof ManufactureBettingRateRoundingTypeEnum[keyof typeof ManufactureBettingRateRoundingTypeEnum];
export const ManufactureBettingRateRoundingUnitEnum = {
    NUMBER_1: 1,
    NUMBER_10: 10,
    NUMBER_100: 100,
    NUMBER_1000: 1000
} as const;

export type ManufactureBettingRateRoundingUnitEnum = typeof ManufactureBettingRateRoundingUnitEnum[keyof typeof ManufactureBettingRateRoundingUnitEnum];

/**
 * 組織
 * @export
 * @interface Organization
 */
export interface Organization {
    /**
     * 
     * @type {number}
     * @memberof Organization
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'domain': string;
    /**
     * 
     * @type {Array<Manufacture>}
     * @memberof Organization
     */
    'manufactures': Array<Manufacture>;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'plan': OrganizationPlanEnum;
}

export const OrganizationPlanEnum = {
    MeasuredRate: 'measured_rate'
} as const;

export type OrganizationPlanEnum = typeof OrganizationPlanEnum[keyof typeof OrganizationPlanEnum];

/**
 * ページネーションレスポンス(リソース版)
 * @export
 * @interface PaginatedResourceResponse
 */
export interface PaginatedResourceResponse {
    /**
     * 
     * @type {PaginatedResourceResponseLinks}
     * @memberof PaginatedResourceResponse
     */
    'links': PaginatedResourceResponseLinks;
    /**
     * 
     * @type {PaginatedResourceResponseMeta}
     * @memberof PaginatedResourceResponse
     */
    'meta': PaginatedResourceResponseMeta;
}
/**
 * 
 * @export
 * @interface PaginatedResourceResponseLinks
 */
export interface PaginatedResourceResponseLinks {
    /**
     * 
     * @type {string}
     * @memberof PaginatedResourceResponseLinks
     */
    'first'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaginatedResourceResponseLinks
     */
    'last'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaginatedResourceResponseLinks
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedResourceResponseLinks
     */
    'prev'?: string | null;
}
/**
 * 
 * @export
 * @interface PaginatedResourceResponseMeta
 */
export interface PaginatedResourceResponseMeta {
    /**
     * 
     * @type {number}
     * @memberof PaginatedResourceResponseMeta
     */
    'current_page'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PaginatedResourceResponseMeta
     */
    'from'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PaginatedResourceResponseMeta
     */
    'to'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PaginatedResourceResponseMeta
     */
    'total'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PaginatedResourceResponseMeta
     */
    'last_page'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PaginatedResourceResponseMeta
     */
    'per_page'?: number | null;
    /**
     * 
     * @type {object}
     * @memberof PaginatedResourceResponseMeta
     */
    'links'?: object;
    /**
     * 
     * @type {string}
     * @memberof PaginatedResourceResponseMeta
     */
    'path'?: string | null;
}
/**
 * ページネーションレスポンス
 * @export
 * @interface PaginationResponse
 */
export interface PaginationResponse {
    /**
     * 
     * @type {number}
     * @memberof PaginationResponse
     */
    'current_page': number | null;
    /**
     * 
     * @type {number}
     * @memberof PaginationResponse
     */
    'from': number | null;
    /**
     * 
     * @type {number}
     * @memberof PaginationResponse
     */
    'to': number | null;
    /**
     * 
     * @type {number}
     * @memberof PaginationResponse
     */
    'total': number | null;
    /**
     * 
     * @type {number}
     * @memberof PaginationResponse
     */
    'last_page': number | null;
    /**
     * 
     * @type {number}
     * @memberof PaginationResponse
     */
    'per_page': number | null;
    /**
     * 
     * @type {string}
     * @memberof PaginationResponse
     */
    'first_page_url': string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginationResponse
     */
    'last_page_url': string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginationResponse
     */
    'next_page_url': string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginationResponse
     */
    'prev_page_url': string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginationResponse
     */
    'path': string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PaginationResponse
     */
    'links': Array<string> | null;
}
/**
 * 
 * @export
 * @interface PartNumberSearchFurniture
 */
export interface PartNumberSearchFurniture {
    /**
     * ファニチャーID
     * @type {number}
     * @memberof PartNumberSearchFurniture
     */
    'id': number;
    /**
     * ファニチャーシリーズID
     * @type {number}
     * @memberof PartNumberSearchFurniture
     */
    'furniture_series_id': number;
    /**
     * メイン画像ファイルパス
     * @type {string}
     * @memberof PartNumberSearchFurniture
     */
    'main_image_file_path': string | null;
    /**
     * 予想配送日
     * @type {string}
     * @memberof PartNumberSearchFurniture
     */
    'estimated_delivery_date'?: string | null;
    /**
     * 高さ（mm）
     * @type {number}
     * @memberof PartNumberSearchFurniture
     */
    'height_mm': number | null;
    /**
     * 奥行き（mm）
     * @type {number}
     * @memberof PartNumberSearchFurniture
     */
    'depth_mm': number | null;
    /**
     * 幅（mm）
     * @type {number}
     * @memberof PartNumberSearchFurniture
     */
    'width_mm': number | null;
    /**
     * パーツ名
     * @type {string}
     * @memberof PartNumberSearchFurniture
     */
    'parts_name': string | null;
    /**
     * パーツ番号
     * @type {string}
     * @memberof PartNumberSearchFurniture
     */
    'parts_number': string;
    /**
     * 標準小売価格（税抜き）
     * @type {number}
     * @memberof PartNumberSearchFurniture
     */
    'standard_retail_price_excluding_tax': number;
    /**
     * 注文コード
     * @type {string}
     * @memberof PartNumberSearchFurniture
     */
    'order_code': string | null;
    /**
     * メーカーID
     * @type {number}
     * @memberof PartNumberSearchFurniture
     */
    'manufacture_id': number;
    /**
     * メーカー名
     * @type {string}
     * @memberof PartNumberSearchFurniture
     */
    'manufacture_name': string;
    /**
     * カタログページ番号
     * @type {string}
     * @memberof PartNumberSearchFurniture
     */
    'catalog_page_num': string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PartNumberSearchFurniture
     */
    'selected_options': Array<string>;
    /**
     * 
     * @type {FurnitureSeries}
     * @memberof PartNumberSearchFurniture
     */
    'series': FurnitureSeries;
    /**
     * 
     * @type {Array<string>}
     * @memberof PartNumberSearchFurniture
     */
    'options_detail': Array<string>;
    /**
     * メニーオプションを持っているシリーズは `true` になる。 
     * @type {boolean}
     * @memberof PartNumberSearchFurniture
     */
    'has_furniture_many_option_sets': boolean;
    /**
     * 単位名
     * @type {string}
     * @memberof PartNumberSearchFurniture
     */
    'unit_name': string | null;
}
/**
 * 案件情報  `kiribari_template`フィールドは以下のエンドポイントのレスポンスにのみ含まれる:   `GET|PATCH /api/project/{project_id}` 
 * @export
 * @interface Project
 */
export interface Project {
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'user_id': string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'project_code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'customer_name': string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'estimated_amount': string | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'manager_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'final_customer_name': string | null;
    /**
     * 
     * @type {number}
     * @memberof Project
     */
    'organization_id': number;
    /**
     * 
     * @type {number}
     * @memberof Project
     */
    'template_id': number;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    'stamp_index_visible': boolean;
    /**
     * 
     * @type {number}
     * @memberof Project
     */
    'total_project_value': number;
    /**
     * 
     * @type {Array<FloorQuotation>}
     * @memberof Project
     */
    'floor_quotations': Array<FloorQuotation>;
    /**
     * 
     * @type {KiribariTemplate}
     * @memberof Project
     */
    'kiribari_template'?: KiribariTemplate;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface PutFurnitures200ResponseInner
 */
export interface PutFurnitures200ResponseInner {
    /**
     * 家具ID
     * @type {number}
     * @memberof PutFurnitures200ResponseInner
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface PutFurnituresRequest
 */
export interface PutFurnituresRequest {
    /**
     * 
     * @type {Array<PutFurnituresRequestFurnituresInner>}
     * @memberof PutFurnituresRequest
     */
    'furnitures': Array<PutFurnituresRequestFurnituresInner>;
}
/**
 * @type PutFurnituresRequestFurnituresInner
 * @export
 */
export type PutFurnituresRequestFurnituresInner = CreateAreaFurnitureRequest | CreateCustomFurnitureRequest | UpdateAreaFurnitureRequest | UpdateCustomFurnitureRequest;

/**
 * 
 * @export
 * @interface QuotationExpense
 */
export interface QuotationExpense {
    /**
     * 
     * @type {string}
     * @memberof QuotationExpense
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof QuotationExpense
     */
    'floor_quotation_id': string;
    /**
     * 
     * @type {string}
     * @memberof QuotationExpense
     */
    'floor_quotation_name': string;
    /**
     * 提出経費名
     * @type {string}
     * @memberof QuotationExpense
     */
    'name': string | null;
    /**
     * 品番
     * @type {string}
     * @memberof QuotationExpense
     */
    'parts_number': string | null;
    /**
     * 備考
     * @type {string}
     * @memberof QuotationExpense
     */
    'notes': string | null;
    /**
     * 単価
     * @type {number}
     * @memberof QuotationExpense
     */
    'amount': number | null;
    /**
     * 原価
     * @type {number}
     * @memberof QuotationExpense
     */
    'cost_excluding_tax': number | null;
    /**
     * 数量
     * @type {number}
     * @memberof QuotationExpense
     */
    'qty': number;
    /**
     * 単位
     * @type {string}
     * @memberof QuotationExpense
     */
    'unit': string;
    /**
     * ソート順序
     * @type {number}
     * @memberof QuotationExpense
     */
    'sort_order': number | null;
    /**
     * 定価
     * @type {number}
     * @memberof QuotationExpense
     */
    'standard_retail_price_excluding_tax': number | null;
}
/**
 * 見積の図面画像
 * @export
 * @interface QuotationPlanImage
 */
export interface QuotationPlanImage {
    /**
     * 図面画像ID
     * @type {number}
     * @memberof QuotationPlanImage
     */
    'id': number;
    /**
     * ファイル名
     * @type {string}
     * @memberof QuotationPlanImage
     */
    'file_name': string | null;
    /**
     * ファイルパス
     * @type {string}
     * @memberof QuotationPlanImage
     */
    'file_path': string | null;
    /**
     * PDFファイルパス
     * @type {string}
     * @memberof QuotationPlanImage
     */
    'pdf_file_path': string;
}
/**
 * 
 * @export
 * @interface ReorderFavoriteFurnitureInSetRequest
 */
export interface ReorderFavoriteFurnitureInSetRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof ReorderFavoriteFurnitureInSetRequest
     */
    'user_favorite_furniture_ids': Array<number>;
}
/**
 * 
 * @export
 * @interface ResetPasswordRequest
 */
export interface ResetPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface SaveAreaStampsRequest
 */
export interface SaveAreaStampsRequest {
    /**
     * 
     * @type {Array<SaveAreaStampsRequestStampsInner>}
     * @memberof SaveAreaStampsRequest
     */
    'stamps'?: Array<SaveAreaStampsRequestStampsInner>;
}
/**
 * 
 * @export
 * @interface SaveAreaStampsRequestStampsInner
 */
export interface SaveAreaStampsRequestStampsInner {
    /**
     * スタンプID
     * @type {number}
     * @memberof SaveAreaStampsRequestStampsInner
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof SaveAreaStampsRequestStampsInner
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof SaveAreaStampsRequestStampsInner
     */
    'y': number;
    /**
     * 
     * @type {number}
     * @memberof SaveAreaStampsRequestStampsInner
     */
    'canvas_scale': number;
    /**
     * 
     * @type {number}
     * @memberof SaveAreaStampsRequestStampsInner
     */
    'area_furniture_id': number;
}
/**
 * 
 * @export
 * @interface SaveExpenseSet200Response
 */
export interface SaveExpenseSet200Response {
    /**
     * レスポンスメッセージ
     * @type {string}
     * @memberof SaveExpenseSet200Response
     */
    'msg'?: string;
    /**
     * レスポンスステータス
     * @type {boolean}
     * @memberof SaveExpenseSet200Response
     * @deprecated
     */
    'status'?: boolean;
    /**
     * 
     * @type {SaveExpenseSet200ResponseAllOfData}
     * @memberof SaveExpenseSet200Response
     */
    'data': SaveExpenseSet200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface SaveExpenseSet200ResponseAllOfData
 */
export interface SaveExpenseSet200ResponseAllOfData {
    /**
     * 経費セットID
     * @type {number}
     * @memberof SaveExpenseSet200ResponseAllOfData
     */
    'id'?: number;
    /**
     * 経費セット名
     * @type {string}
     * @memberof SaveExpenseSet200ResponseAllOfData
     */
    'name'?: string;
    /**
     * ユーザーID
     * @type {string}
     * @memberof SaveExpenseSet200ResponseAllOfData
     */
    'user_id'?: string;
}
/**
 * 
 * @export
 * @interface SaveExpenseSetRequest
 */
export interface SaveExpenseSetRequest {
    /**
     * 経費セットID(更新時必須)
     * @type {number}
     * @memberof SaveExpenseSetRequest
     */
    'expense_group_id'?: number;
    /**
     * 経費セット名
     * @type {string}
     * @memberof SaveExpenseSetRequest
     */
    'name'?: string;
    /**
     * 経費セットに保存する経費一覧
     * @type {Array<SaveExpenseSetRequestExpensesInner>}
     * @memberof SaveExpenseSetRequest
     */
    'expenses': Array<SaveExpenseSetRequestExpensesInner>;
}
/**
 * 
 * @export
 * @interface SaveExpenseSetRequestExpensesInner
 */
export interface SaveExpenseSetRequestExpensesInner {
    /**
     * 経費種類
     * @type {string}
     * @memberof SaveExpenseSetRequestExpensesInner
     */
    'name': string;
    /**
     * 品番
     * @type {string}
     * @memberof SaveExpenseSetRequestExpensesInner
     */
    'parts_number': string;
    /**
     * 備考
     * @type {string}
     * @memberof SaveExpenseSetRequestExpensesInner
     */
    'notes': string;
    /**
     * 単価
     * @type {number}
     * @memberof SaveExpenseSetRequestExpensesInner
     */
    'amount': number;
    /**
     * 原価
     * @type {number}
     * @memberof SaveExpenseSetRequestExpensesInner
     */
    'cost_excluding_tax': number | null;
    /**
     * 数量
     * @type {number}
     * @memberof SaveExpenseSetRequestExpensesInner
     */
    'qty': number;
    /**
     * 単位
     * @type {string}
     * @memberof SaveExpenseSetRequestExpensesInner
     */
    'unit': string;
}
/**
 * 
 * @export
 * @interface SaveFurnitureBaseRequest
 */
export interface SaveFurnitureBaseRequest {
    /**
     * カスタム家具かどうか
     * @type {boolean}
     * @memberof SaveFurnitureBaseRequest
     */
    'is_custom_furniture': boolean;
    /**
     * エリアID
     * @type {string}
     * @memberof SaveFurnitureBaseRequest
     */
    'area_id': string;
    /**
     * 
     * @type {string}
     * @memberof SaveFurnitureBaseRequest
     */
    'main_image_file_path'?: string | null;
    /**
     * 注文コード
     * @type {string}
     * @memberof SaveFurnitureBaseRequest
     */
    'order_code'?: string | null;
    /**
     * 定価
     * @type {number}
     * @memberof SaveFurnitureBaseRequest
     */
    'standard_retail_price_excluding_tax'?: number | null;
    /**
     * W寸法
     * @type {number}
     * @memberof SaveFurnitureBaseRequest
     */
    'width'?: number | null;
    /**
     * D寸法
     * @type {number}
     * @memberof SaveFurnitureBaseRequest
     */
    'depth'?: number | null;
    /**
     * H寸法
     * @type {number}
     * @memberof SaveFurnitureBaseRequest
     */
    'height'?: number | null;
    /**
     * 掛率
     * @type {number}
     * @memberof SaveFurnitureBaseRequest
     */
    'betting_rate'?: number | null;
    /**
     * 売価
     * @type {number}
     * @memberof SaveFurnitureBaseRequest
     */
    'price'?: number | null;
    /**
     * 数量
     * @type {number}
     * @memberof SaveFurnitureBaseRequest
     */
    'quantity'?: number | null;
    /**
     * 単位
     * @type {string}
     * @memberof SaveFurnitureBaseRequest
     */
    'unit_name'?: string | null;
    /**
     * 原価
     * @type {number}
     * @memberof SaveFurnitureBaseRequest
     */
    'cost_excluding_tax'?: number | null;
    /**
     * 備考
     * @type {string}
     * @memberof SaveFurnitureBaseRequest
     */
    'notes'?: string | null;
    /**
     * 並び順
     * @type {number}
     * @memberof SaveFurnitureBaseRequest
     */
    'sort_order'?: number | null;
    /**
     * 任意メーカーID
     * @type {number}
     * @memberof SaveFurnitureBaseRequest
     */
    'custom_manufacture_id'?: number | null;
}
/**
 * 
 * @export
 * @interface SaveFurnitureRequest
 */
export interface SaveFurnitureRequest {
    /**
     * 
     * @type {number}
     * @memberof SaveFurnitureRequest
     */
    'manufacture_id': number | null;
    /**
     * 
     * @type {string}
     * @memberof SaveFurnitureRequest
     */
    'parts_number': string | null;
    /**
     * 
     * @type {string}
     * @memberof SaveFurnitureRequest
     */
    'area_id': string;
    /**
     * 
     * @type {number}
     * @memberof SaveFurnitureRequest
     */
    'quantity': number;
    /**
     * 
     * @type {number}
     * @memberof SaveFurnitureRequest
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof SaveFurnitureRequest
     */
    'sort_order'?: number | null;
    /**
     * 
     * @type {Array<SelectedFurnitureManyOption>}
     * @memberof SaveFurnitureRequest
     */
    'furniture_many_options'?: Array<SelectedFurnitureManyOption> | null;
}
/**
 * 
 * @export
 * @interface SaveFurnituresRequest
 */
export interface SaveFurnituresRequest {
    /**
     * エリアID
     * @type {string}
     * @memberof SaveFurnituresRequest
     */
    'area_id': string;
    /**
     * 
     * @type {Array<SaveFurnituresRequestFurnituresInner>}
     * @memberof SaveFurnituresRequest
     */
    'furnitures': Array<SaveFurnituresRequestFurnituresInner>;
}
/**
 * 
 * @export
 * @interface SaveFurnituresRequestFurnituresInner
 */
export interface SaveFurnituresRequestFurnituresInner {
    /**
     * 家具ID
     * @type {number}
     * @memberof SaveFurnituresRequestFurnituresInner
     */
    'furniture_id': number;
    /**
     * 数量
     * @type {number}
     * @memberof SaveFurnituresRequestFurnituresInner
     */
    'quantity': number;
}
/**
 * 
 * @export
 * @interface SearchFurniture
 */
export interface SearchFurniture {
    /**
     * ID
     * @type {number}
     * @memberof SearchFurniture
     */
    'id': number;
    /**
     * シリーズ名
     * @type {string}
     * @memberof SearchFurniture
     */
    'product_series_name': string;
    /**
     * 追加の検索ワード
     * @type {string}
     * @memberof SearchFurniture
     */
    'additional_search_word'?: string;
    /**
     * 最小価格
     * @type {number}
     * @memberof SearchFurniture
     */
    'min_price': number;
    /**
     * 最大価格
     * @type {number}
     * @memberof SearchFurniture
     */
    'max_price': number;
    /**
     * メーカーID
     * @type {number}
     * @memberof SearchFurniture
     */
    'manufacture_id': number;
    /**
     * メイン画像ファイルパス
     * @type {string}
     * @memberof SearchFurniture
     */
    'main_image_file_path': string;
    /**
     * 最小奥行き
     * @type {number}
     * @memberof SearchFurniture
     */
    'min_depth': number;
    /**
     * 最大奥行き
     * @type {number}
     * @memberof SearchFurniture
     */
    'max_depth': number;
    /**
     * 最小幅
     * @type {number}
     * @memberof SearchFurniture
     */
    'min_width': number;
    /**
     * 最大幅
     * @type {number}
     * @memberof SearchFurniture
     */
    'max_width': number;
    /**
     * 最小高さ
     * @type {number}
     * @memberof SearchFurniture
     */
    'min_height': number;
    /**
     * 最大高さ
     * @type {number}
     * @memberof SearchFurniture
     */
    'max_height': number;
    /**
     * カタログページのURL
     * @type {string}
     * @memberof SearchFurniture
     */
    'catalog_page_url': string;
    /**
     * 順序
     * @type {number}
     * @memberof SearchFurniture
     */
    'furniture_order': number;
    /**
     * 先頭品番
     * @type {string}
     * @memberof SearchFurniture
     */
    'parts_number_head': string;
    /**
     * 検索可能
     * @type {number}
     * @memberof SearchFurniture
     */
    'is_searchable': number;
    /**
     * 
     * @type {Manufacture}
     * @memberof SearchFurniture
     */
    'manufacture': Manufacture;
    /**
     * 
     * @type {Array<FurnitureSeriesSubCategories>}
     * @memberof SearchFurniture
     */
    'furniture_series_sub_categories'?: Array<FurnitureSeriesSubCategories>;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof SearchFurniture
     */
    'tags': Array<Tag>;
    /**
     * 
     * @type {string}
     * @memberof SearchFurniture
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchFurniture
     */
    'updated_at'?: string;
}
/**
 * 
 * @export
 * @interface SearchFurnituresByPartsNumbers200Response
 */
export interface SearchFurnituresByPartsNumbers200Response {
    /**
     * 
     * @type {Array<SearchFurnituresByPartsNumbers200ResponseDataInner>}
     * @memberof SearchFurnituresByPartsNumbers200Response
     */
    'data': Array<SearchFurnituresByPartsNumbers200ResponseDataInner>;
}
/**
 * 
 * @export
 * @interface SearchFurnituresByPartsNumbers200ResponseDataInner
 */
export interface SearchFurnituresByPartsNumbers200ResponseDataInner {
    /**
     * 品番
     * @type {string}
     * @memberof SearchFurnituresByPartsNumbers200ResponseDataInner
     */
    'parts_number': string;
    /**
     * 
     * @type {Array<Furniture>}
     * @memberof SearchFurnituresByPartsNumbers200ResponseDataInner
     */
    'furnitures': Array<Furniture>;
}
/**
 * 
 * @export
 * @interface SearchFurnituresByPartsNumbersRequest
 */
export interface SearchFurnituresByPartsNumbersRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchFurnituresByPartsNumbersRequest
     */
    'parts_numbers': Array<string>;
}
/**
 * 選択されたメニーオプション
 * @export
 * @interface SelectedFurnitureManyOption
 */
export interface SelectedFurnitureManyOption {
    /**
     * 品番コード位置
     * @type {number}
     * @memberof SelectedFurnitureManyOption
     */
    'option_place_num'?: number;
    /**
     * 選択されたメニーオプションID
     * @type {number}
     * @memberof SelectedFurnitureManyOption
     */
    'furniture_many_option_id'?: number;
    /**
     * メニーオプション画像パス
     * @type {string}
     * @memberof SelectedFurnitureManyOption
     */
    'image_file_path'?: string;
}
/**
 * 
 * @export
 * @interface SendPasswordResetLinkRequest
 */
export interface SendPasswordResetLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof SendPasswordResetLinkRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface StoreQuotationExpenses200Response
 */
export interface StoreQuotationExpenses200Response {
    /**
     * レスポンスメッセージ
     * @type {string}
     * @memberof StoreQuotationExpenses200Response
     */
    'msg'?: string;
    /**
     * レスポンスステータス
     * @type {boolean}
     * @memberof StoreQuotationExpenses200Response
     * @deprecated
     */
    'status'?: boolean;
    /**
     * 
     * @type {Array<QuotationExpense>}
     * @memberof StoreQuotationExpenses200Response
     */
    'data': Array<QuotationExpense>;
}
/**
 * 
 * @export
 * @interface StoreQuotationExpensesRequest
 */
export interface StoreQuotationExpensesRequest {
    /**
     * 見積ID
     * @type {string}
     * @memberof StoreQuotationExpensesRequest
     */
    'floor_quotation_id': string;
    /**
     * 経費一覧  NOTE: - この配列に含まれない経費は削除される。 - 空配列が渡された場合は、経費一覧をすべて削除する。 
     * @type {Array<StoreQuotationExpensesRequestExpensesInner>}
     * @memberof StoreQuotationExpensesRequest
     */
    'expenses': Array<StoreQuotationExpensesRequestExpensesInner> | null;
}
/**
 * 
 * @export
 * @interface StoreQuotationExpensesRequestExpensesInner
 */
export interface StoreQuotationExpensesRequestExpensesInner {
    /**
     * 経費ID
     * @type {string}
     * @memberof StoreQuotationExpensesRequestExpensesInner
     */
    'id'?: string;
    /**
     * 提出経費名
     * @type {string}
     * @memberof StoreQuotationExpensesRequestExpensesInner
     */
    'name'?: string | null;
    /**
     * 備考
     * @type {string}
     * @memberof StoreQuotationExpensesRequestExpensesInner
     */
    'notes'?: string | null;
    /**
     * 単価
     * @type {number}
     * @memberof StoreQuotationExpensesRequestExpensesInner
     */
    'amount'?: number | null;
    /**
     * 原価
     * @type {number}
     * @memberof StoreQuotationExpensesRequestExpensesInner
     */
    'cost_excluding_tax'?: number | null;
    /**
     * 数量
     * @type {number}
     * @memberof StoreQuotationExpensesRequestExpensesInner
     */
    'qty': number;
    /**
     * 単位
     * @type {string}
     * @memberof StoreQuotationExpensesRequestExpensesInner
     */
    'unit'?: string | null;
    /**
     * パーツ番号
     * @type {string}
     * @memberof StoreQuotationExpensesRequestExpensesInner
     */
    'parts_number'?: string | null;
    /**
     * 並び順
     * @type {number}
     * @memberof StoreQuotationExpensesRequestExpensesInner
     */
    'sort_order'?: number | null;
}
/**
 * 
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     * タグID
     * @type {number}
     * @memberof Tag
     */
    'id': number;
    /**
     * タグ名
     * @type {string}
     * @memberof Tag
     */
    'name': string;
    /**
     * タグ色
     * @type {string}
     * @memberof Tag
     */
    'bg_color': string;
    /**
     * タグ文字色
     * @type {string}
     * @memberof Tag
     */
    'text_color': string;
}
/**
 * 
 * @export
 * @interface UpdateAreaFurnitureNotesRequest
 */
export interface UpdateAreaFurnitureNotesRequest {
    /**
     * 備考欄メモ
     * @type {string}
     * @memberof UpdateAreaFurnitureNotesRequest
     */
    'notes': string;
}
/**
 * 
 * @export
 * @interface UpdateAreaFurnitureRequest
 */
export interface UpdateAreaFurnitureRequest {
    /**
     * カスタム家具かどうか
     * @type {boolean}
     * @memberof UpdateAreaFurnitureRequest
     */
    'is_custom_furniture': boolean;
    /**
     * エリアID
     * @type {string}
     * @memberof UpdateAreaFurnitureRequest
     */
    'area_id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAreaFurnitureRequest
     */
    'main_image_file_path'?: string | null;
    /**
     * 注文コード
     * @type {string}
     * @memberof UpdateAreaFurnitureRequest
     */
    'order_code'?: string | null;
    /**
     * 定価
     * @type {number}
     * @memberof UpdateAreaFurnitureRequest
     */
    'standard_retail_price_excluding_tax'?: number | null;
    /**
     * W寸法
     * @type {number}
     * @memberof UpdateAreaFurnitureRequest
     */
    'width'?: number | null;
    /**
     * D寸法
     * @type {number}
     * @memberof UpdateAreaFurnitureRequest
     */
    'depth'?: number | null;
    /**
     * H寸法
     * @type {number}
     * @memberof UpdateAreaFurnitureRequest
     */
    'height'?: number | null;
    /**
     * 掛率
     * @type {number}
     * @memberof UpdateAreaFurnitureRequest
     */
    'betting_rate'?: number | null;
    /**
     * 売価
     * @type {number}
     * @memberof UpdateAreaFurnitureRequest
     */
    'price'?: number | null;
    /**
     * 数量
     * @type {number}
     * @memberof UpdateAreaFurnitureRequest
     */
    'quantity'?: number | null;
    /**
     * 単位
     * @type {string}
     * @memberof UpdateAreaFurnitureRequest
     */
    'unit_name'?: string | null;
    /**
     * 原価
     * @type {number}
     * @memberof UpdateAreaFurnitureRequest
     */
    'cost_excluding_tax'?: number | null;
    /**
     * 備考
     * @type {string}
     * @memberof UpdateAreaFurnitureRequest
     */
    'notes'?: string | null;
    /**
     * 並び順
     * @type {number}
     * @memberof UpdateAreaFurnitureRequest
     */
    'sort_order'?: number | null;
    /**
     * 任意メーカーID
     * @type {number}
     * @memberof UpdateAreaFurnitureRequest
     */
    'custom_manufacture_id'?: number | null;
    /**
     * エリア家具ID
     * @type {number}
     * @memberof UpdateAreaFurnitureRequest
     */
    'id': number;
    /**
     * メーカーID
     * @type {number}
     * @memberof UpdateAreaFurnitureRequest
     */
    'manufacture_id'?: number | null;
    /**
     * 品番
     * @type {string}
     * @memberof UpdateAreaFurnitureRequest
     */
    'parts_number'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateAreaNameRequest
 */
export interface UpdateAreaNameRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateAreaNameRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UpdateAreaRequest
 */
export interface UpdateAreaRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateAreaRequest
     */
    'floor_quotation_id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAreaRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateAreaRequest
     */
    'canvas_scale': number;
    /**
     * 
     * @type {AreaType}
     * @memberof UpdateAreaRequest
     */
    'type': AreaType;
    /**
     * 
     * @type {CreateAreaRequestDefinition}
     * @memberof UpdateAreaRequest
     */
    'definition': CreateAreaRequestDefinition;
}


/**
 * 
 * @export
 * @interface UpdateAreaSortOrderRequest
 */
export interface UpdateAreaSortOrderRequest {
    /**
     * 
     * @type {Array<UpdateAreaSortOrderRequestSortOrdersInner>}
     * @memberof UpdateAreaSortOrderRequest
     */
    'sort_orders'?: Array<UpdateAreaSortOrderRequestSortOrdersInner>;
}
/**
 * 
 * @export
 * @interface UpdateAreaSortOrderRequestSortOrdersInner
 */
export interface UpdateAreaSortOrderRequestSortOrdersInner {
    /**
     * 
     * @type {string}
     * @memberof UpdateAreaSortOrderRequestSortOrdersInner
     */
    'area_id': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateAreaSortOrderRequestSortOrdersInner
     */
    'sort_order': number;
}
/**
 * 
 * @export
 * @interface UpdateCompany200Response
 */
export interface UpdateCompany200Response {
    /**
     * レスポンスメッセージ
     * @type {string}
     * @memberof UpdateCompany200Response
     */
    'msg'?: string;
    /**
     * レスポンスステータス
     * @type {boolean}
     * @memberof UpdateCompany200Response
     * @deprecated
     */
    'status'?: boolean;
    /**
     * 
     * @type {Company}
     * @memberof UpdateCompany200Response
     */
    'data': Company;
}
/**
 * 
 * @export
 * @interface UpdateCompanyRequest
 */
export interface UpdateCompanyRequest {
    /**
     * 会社名
     * @type {string}
     * @memberof UpdateCompanyRequest
     */
    'company_name': string;
    /**
     * 郵便番号
     * @type {string}
     * @memberof UpdateCompanyRequest
     */
    'postal_code': string;
    /**
     * 会社所在地
     * @type {string}
     * @memberof UpdateCompanyRequest
     */
    'company_location': string;
    /**
     * 電話番号
     * @type {string}
     * @memberof UpdateCompanyRequest
     */
    'company_telphone_number'?: string | null;
    /**
     * 担当者氏名
     * @type {string}
     * @memberof UpdateCompanyRequest
     */
    'manager_name'?: string | null;
    /**
     * 建物名・部屋番号
     * @type {string}
     * @memberof UpdateCompanyRequest
     */
    'company_building'?: string | null;
    /**
     * FAX番号
     * @type {string}
     * @memberof UpdateCompanyRequest
     */
    'company_fax_number'?: string | null;
    /**
     * 担当部署
     * @type {string}
     * @memberof UpdateCompanyRequest
     */
    'company_branch_name'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateCustomFurnitureRequest
 */
export interface UpdateCustomFurnitureRequest {
    /**
     * カスタム家具かどうか
     * @type {boolean}
     * @memberof UpdateCustomFurnitureRequest
     */
    'is_custom_furniture': boolean;
    /**
     * エリアID
     * @type {string}
     * @memberof UpdateCustomFurnitureRequest
     */
    'area_id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomFurnitureRequest
     */
    'main_image_file_path'?: string | null;
    /**
     * 注文コード
     * @type {string}
     * @memberof UpdateCustomFurnitureRequest
     */
    'order_code'?: string | null;
    /**
     * 定価
     * @type {number}
     * @memberof UpdateCustomFurnitureRequest
     */
    'standard_retail_price_excluding_tax'?: number | null;
    /**
     * W寸法
     * @type {number}
     * @memberof UpdateCustomFurnitureRequest
     */
    'width'?: number | null;
    /**
     * D寸法
     * @type {number}
     * @memberof UpdateCustomFurnitureRequest
     */
    'depth'?: number | null;
    /**
     * H寸法
     * @type {number}
     * @memberof UpdateCustomFurnitureRequest
     */
    'height'?: number | null;
    /**
     * 掛率
     * @type {number}
     * @memberof UpdateCustomFurnitureRequest
     */
    'betting_rate'?: number | null;
    /**
     * 売価
     * @type {number}
     * @memberof UpdateCustomFurnitureRequest
     */
    'price'?: number | null;
    /**
     * 数量
     * @type {number}
     * @memberof UpdateCustomFurnitureRequest
     */
    'quantity'?: number | null;
    /**
     * 単位
     * @type {string}
     * @memberof UpdateCustomFurnitureRequest
     */
    'unit_name'?: string | null;
    /**
     * 原価
     * @type {number}
     * @memberof UpdateCustomFurnitureRequest
     */
    'cost_excluding_tax'?: number | null;
    /**
     * 備考
     * @type {string}
     * @memberof UpdateCustomFurnitureRequest
     */
    'notes'?: string | null;
    /**
     * 並び順
     * @type {number}
     * @memberof UpdateCustomFurnitureRequest
     */
    'sort_order'?: number | null;
    /**
     * 任意メーカーID
     * @type {number}
     * @memberof UpdateCustomFurnitureRequest
     */
    'custom_manufacture_id'?: number | null;
    /**
     * カスタム家具ID
     * @type {number}
     * @memberof UpdateCustomFurnitureRequest
     */
    'id': number;
    /**
     * 品番
     * @type {string}
     * @memberof UpdateCustomFurnitureRequest
     */
    'parts_number'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateFurnitureRequest
 */
export interface UpdateFurnitureRequest {
    /**
     * 
     * @type {number}
     * @memberof UpdateFurnitureRequest
     */
    'manufacture_id': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateFurnitureRequest
     */
    'parts_number': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateFurnitureRequest
     */
    'quantity': number;
    /**
     * 
     * @type {Array<SelectedFurnitureManyOption>}
     * @memberof UpdateFurnitureRequest
     */
    'furniture_many_options'?: Array<SelectedFurnitureManyOption> | null;
}
/**
 * 
 * @export
 * @interface UpdateProject200Response
 */
export interface UpdateProject200Response {
    /**
     * レスポンスメッセージ
     * @type {string}
     * @memberof UpdateProject200Response
     */
    'msg'?: string;
    /**
     * レスポンスステータス
     * @type {boolean}
     * @memberof UpdateProject200Response
     * @deprecated
     */
    'status'?: boolean;
    /**
     * 
     * @type {Project}
     * @memberof UpdateProject200Response
     */
    'data'?: Project;
}
/**
 * @type UpdateProjectRequest
 * @export
 */
export type UpdateProjectRequest = UpdateProjectRequestOneOf | UpdateProjectRequestOneOf1 | UpdateProjectRequestOneOf2;

/**
 * 
 * @export
 * @interface UpdateProjectRequestOneOf
 */
export interface UpdateProjectRequestOneOf {
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequestOneOf
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UpdateProjectRequestOneOf1
 */
export interface UpdateProjectRequestOneOf1 {
    /**
     * 
     * @type {number}
     * @memberof UpdateProjectRequestOneOf1
     */
    'template_id': number;
}
/**
 * 
 * @export
 * @interface UpdateProjectRequestOneOf2
 */
export interface UpdateProjectRequestOneOf2 {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProjectRequestOneOf2
     */
    'stamp_index_visible': boolean;
}
/**
 * 
 * @export
 * @interface UpdateQuotationDetailsRequest
 */
export interface UpdateQuotationDetailsRequest {
    /**
     * 顧客名
     * @type {string}
     * @memberof UpdateQuotationDetailsRequest
     */
    'client_name': string;
    /**
     * 最終顧客名
     * @type {string}
     * @memberof UpdateQuotationDetailsRequest
     */
    'end_customer_name': string | null;
    /**
     * 見積件名
     * @type {string}
     * @memberof UpdateQuotationDetailsRequest
     */
    'name': string;
    /**
     * 想定納期
     * @type {string}
     * @memberof UpdateQuotationDetailsRequest
     */
    'expected_delivery_date': string;
    /**
     * 納品先（受け渡し住所）
     * @type {string}
     * @memberof UpdateQuotationDetailsRequest
     */
    'client_company_address'?: string | null;
    /**
     * 支払い条件
     * @type {string}
     * @memberof UpdateQuotationDetailsRequest
     */
    'method_of_payment': string;
    /**
     * 見積有効期限
     * @type {string}
     * @memberof UpdateQuotationDetailsRequest
     */
    'quotation_expiration_date'?: string;
    /**
     * 見積書番号
     * @type {string}
     * @memberof UpdateQuotationDetailsRequest
     */
    'quotation_number'?: string | null;
    /**
     * 備考
     * @type {string}
     * @memberof UpdateQuotationDetailsRequest
     */
    'others'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateQuotationPdfRequest
 */
export interface UpdateQuotationPdfRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateQuotationPdfRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateQuotationPdfRequest
     */
    'plan_image_id'?: number | null;
}
/**
 * 
 * @export
 * @interface UpdateUserRequest
 */
export interface UpdateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'email'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateUserRequest
     */
    'organization_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'display_name'?: string;
}
/**
 * ユーザー
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'display_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'phone'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'status'?: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'organization_id'?: number | null;
}
/**
 * 
 * @export
 * @interface UserFavoriteFurniture
 */
export interface UserFavoriteFurniture {
    /**
     * 
     * @type {number}
     * @memberof UserFavoriteFurniture
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UserFavoriteFurniture
     */
    'user_id': string;
    /**
     * 
     * @type {string}
     * @memberof UserFavoriteFurniture
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof UserFavoriteFurniture
     */
    'updated_at': string;
    /**
     * 
     * @type {number}
     * @memberof UserFavoriteFurniture
     */
    'sort_order': number;
    /**
     * 
     * @type {number}
     * @memberof UserFavoriteFurniture
     * @deprecated
     */
    'furniture_id': number | null;
    /**
     * 
     * @type {Array<SelectedFurnitureManyOption>}
     * @memberof UserFavoriteFurniture
     */
    'furniture_many_options': Array<SelectedFurnitureManyOption> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserFavoriteFurniture
     */
    'selected_options': Array<string> | null;
    /**
     * 
     * @type {Furniture}
     * @memberof UserFavoriteFurniture
     */
    'furniture': Furniture;
    /**
     * お気に入りセット詳細APIのみ返却する
     * @type {Array<FavoriteFurnitureSet>}
     * @memberof UserFavoriteFurniture
     */
    'favorite_sets'?: Array<FavoriteFurnitureSet>;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ログインしている管理者の情報を取得する。
         * @summary 管理者情報取得
         * @param {AdminApiAuthLoginPostRequest} [adminApiAuthLoginPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiAuthAdminGet: async (adminApiAuthLoginPostRequest?: AdminApiAuthLoginPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/api/auth/admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminApiAuthLoginPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 管理者のメールアドレスとパスワードを使用してログインする。
         * @summary 管理者ログイン
         * @param {AdminApiAuthLoginPostRequest} [adminApiAuthLoginPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiAuthLoginPost: async (adminApiAuthLoginPostRequest?: AdminApiAuthLoginPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/api/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminApiAuthLoginPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * エリア家具を複製する。 
         * @summary エリア家具複製
         * @param {number} areaFurnitureId エリア家具ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAreaFurnitureAreaFurnitureIdDuplicatePost: async (areaFurnitureId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaFurnitureId' is not null or undefined
            assertParamExists('apiAreaFurnitureAreaFurnitureIdDuplicatePost', 'areaFurnitureId', areaFurnitureId)
            const localVarPath = `/api/areaFurniture/{areaFurnitureId}/duplicate`
                .replace(`{${"areaFurnitureId"}}`, encodeURIComponent(String(areaFurnitureId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary カテゴリー検索
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoriesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary カスタム家具作成
         * @param {ApiCustomFurnitureCreatePostRequest} apiCustomFurnitureCreatePostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomFurnitureCreatePost: async (apiCustomFurnitureCreatePostRequest: ApiCustomFurnitureCreatePostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiCustomFurnitureCreatePostRequest' is not null or undefined
            assertParamExists('apiCustomFurnitureCreatePost', 'apiCustomFurnitureCreatePostRequest', apiCustomFurnitureCreatePostRequest)
            const localVarPath = `/api/customFurniture/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiCustomFurnitureCreatePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カスタム家具を複製する。 
         * @summary カスタム家具複製
         * @param {number} customFurnitureId カスタム家具ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomFurnitureCustomFurnitureIdDuplicatePost: async (customFurnitureId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customFurnitureId' is not null or undefined
            assertParamExists('apiCustomFurnitureCustomFurnitureIdDuplicatePost', 'customFurnitureId', customFurnitureId)
            const localVarPath = `/api/customFurniture/{custom_furniture_id}/duplicate`
                .replace(`{${"custom_furniture_id"}}`, encodeURIComponent(String(customFurnitureId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary カスタム家具更新
         * @param {ApiCustomFurnitureCreatePostRequestCustomFurnituresInner} apiCustomFurnitureCreatePostRequestCustomFurnituresInner 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomFurnitureUpdatePatch: async (apiCustomFurnitureCreatePostRequestCustomFurnituresInner: ApiCustomFurnitureCreatePostRequestCustomFurnituresInner, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiCustomFurnitureCreatePostRequestCustomFurnituresInner' is not null or undefined
            assertParamExists('apiCustomFurnitureUpdatePatch', 'apiCustomFurnitureCreatePostRequestCustomFurnituresInner', apiCustomFurnitureCreatePostRequestCustomFurnituresInner)
            const localVarPath = `/api/customFurniture/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiCustomFurnitureCreatePostRequestCustomFurnituresInner, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カスタム家具のメイン画像を削除する。 
         * @summary カスタム家具メイン画像削除
         * @param {number} customFurnitureId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomFurnituresCustomFurnitureIdMainImageDelete: async (customFurnitureId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customFurnitureId' is not null or undefined
            assertParamExists('apiCustomFurnituresCustomFurnitureIdMainImageDelete', 'customFurnitureId', customFurnitureId)
            const localVarPath = `/api/custom_furnitures/{custom_furniture_id}/main_image`
                .replace(`{${"custom_furniture_id"}}`, encodeURIComponent(String(customFurnitureId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カスタム家具のメイン画像を変更する。 
         * @summary カスタム家具メイン画像更新
         * @param {number} customFurnitureId 
         * @param {File} file アップロードするファイル。拡張子の制限あり。  アップロード可能な拡張子（大文字小文字の区別なし） - jpg/jpeg - png - gif - webp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomFurnituresCustomFurnitureIdMainImagePost: async (customFurnitureId: number, file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customFurnitureId' is not null or undefined
            assertParamExists('apiCustomFurnituresCustomFurnitureIdMainImagePost', 'customFurnitureId', customFurnitureId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('apiCustomFurnituresCustomFurnitureIdMainImagePost', 'file', file)
            const localVarPath = `/api/custom_furnitures/{custom_furniture_id}/main_image`
                .replace(`{${"custom_furniture_id"}}`, encodeURIComponent(String(customFurnitureId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カスタム家具のオプション画像を削除する。 
         * @summary カスタム家具オプション画像削除
         * @param {number} customFurnitureId 
         * @param {ApiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexDeleteOptionIndexEnum} optionIndex オプション画像の位置インデックス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexDelete: async (customFurnitureId: number, optionIndex: ApiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexDeleteOptionIndexEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customFurnitureId' is not null or undefined
            assertParamExists('apiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexDelete', 'customFurnitureId', customFurnitureId)
            // verify required parameter 'optionIndex' is not null or undefined
            assertParamExists('apiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexDelete', 'optionIndex', optionIndex)
            const localVarPath = `/api/custom_furnitures/{custom_furniture_id}/option_images/{option_index}`
                .replace(`{${"custom_furniture_id"}}`, encodeURIComponent(String(customFurnitureId)))
                .replace(`{${"option_index"}}`, encodeURIComponent(String(optionIndex)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カスタム家具のオプション画像を変更する。 
         * @summary カスタム家具オプション画像更新
         * @param {number} customFurnitureId 
         * @param {ApiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexPostOptionIndexEnum} optionIndex オプション画像の位置インデックス
         * @param {File} file アップロードするファイル。拡張子の制限あり。  アップロード可能な拡張子（大文字小文字の区別なし） - jpg/jpeg - png - gif - webp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexPost: async (customFurnitureId: number, optionIndex: ApiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexPostOptionIndexEnum, file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customFurnitureId' is not null or undefined
            assertParamExists('apiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexPost', 'customFurnitureId', customFurnitureId)
            // verify required parameter 'optionIndex' is not null or undefined
            assertParamExists('apiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexPost', 'optionIndex', optionIndex)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('apiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexPost', 'file', file)
            const localVarPath = `/api/custom_furnitures/{custom_furniture_id}/option_images/{option_index}`
                .replace(`{${"custom_furniture_id"}}`, encodeURIComponent(String(customFurnitureId)))
                .replace(`{${"option_index"}}`, encodeURIComponent(String(optionIndex)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * お気に入りのリストを取得する。 
         * @summary お気に入り一覧取得
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        apiFavoriteGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/favorite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 選択した家具をお気に入りに追加する。 
         * @summary お気に入り追加
         * @param {ApiFavoritePostRequest} [apiFavoritePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFavoritePost: async (apiFavoritePostRequest?: ApiFavoritePostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/favorite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiFavoritePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * エリア家具を削除する。
         * @summary エリア家具削除
         * @param {number} areaFurnitureId エリア家具ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFurnitureAreaFurnitureIdDelete: async (areaFurnitureId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaFurnitureId' is not null or undefined
            assertParamExists('apiFurnitureAreaFurnitureIdDelete', 'areaFurnitureId', areaFurnitureId)
            const localVarPath = `/api/furniture/{areaFurnitureId}`
                .replace(`{${"areaFurnitureId"}}`, encodeURIComponent(String(areaFurnitureId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * エリア家具のメイン画像を削除する。 
         * @summary エリア家具メイン画像削除
         * @param {string} areaId 
         * @param {number} areaFurnitureId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFurnitureAreaIdUpdateAreaFurnitureIdMainImageDelete: async (areaId: string, areaFurnitureId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaId' is not null or undefined
            assertParamExists('apiFurnitureAreaIdUpdateAreaFurnitureIdMainImageDelete', 'areaId', areaId)
            // verify required parameter 'areaFurnitureId' is not null or undefined
            assertParamExists('apiFurnitureAreaIdUpdateAreaFurnitureIdMainImageDelete', 'areaFurnitureId', areaFurnitureId)
            const localVarPath = `/api/furniture/{area_id}/update/{area_furniture_id}/main_image`
                .replace(`{${"area_id"}}`, encodeURIComponent(String(areaId)))
                .replace(`{${"area_furniture_id"}}`, encodeURIComponent(String(areaFurnitureId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * エリア家具のメイン画像を変更する。 
         * @summary エリア家具メイン画像更新
         * @param {string} areaId 
         * @param {number} areaFurnitureId 
         * @param {File} file アップロードするファイル。拡張子の制限あり。  アップロード可能な拡張子（大文字小文字の区別なし） - jpg/jpeg - png - gif - webp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFurnitureAreaIdUpdateAreaFurnitureIdMainImagePost: async (areaId: string, areaFurnitureId: number, file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaId' is not null or undefined
            assertParamExists('apiFurnitureAreaIdUpdateAreaFurnitureIdMainImagePost', 'areaId', areaId)
            // verify required parameter 'areaFurnitureId' is not null or undefined
            assertParamExists('apiFurnitureAreaIdUpdateAreaFurnitureIdMainImagePost', 'areaFurnitureId', areaFurnitureId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('apiFurnitureAreaIdUpdateAreaFurnitureIdMainImagePost', 'file', file)
            const localVarPath = `/api/furniture/{area_id}/update/{area_furniture_id}/main_image`
                .replace(`{${"area_id"}}`, encodeURIComponent(String(areaId)))
                .replace(`{${"area_furniture_id"}}`, encodeURIComponent(String(areaFurnitureId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * エリア家具のオプション画像を削除する。 
         * @summary エリア家具オプション画像削除
         * @param {string} areaId 
         * @param {number} areaFurnitureId 
         * @param {ApiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexDeleteOptionIndexEnum} optionIndex オプション画像の位置インデックス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexDelete: async (areaId: string, areaFurnitureId: number, optionIndex: ApiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexDeleteOptionIndexEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaId' is not null or undefined
            assertParamExists('apiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexDelete', 'areaId', areaId)
            // verify required parameter 'areaFurnitureId' is not null or undefined
            assertParamExists('apiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexDelete', 'areaFurnitureId', areaFurnitureId)
            // verify required parameter 'optionIndex' is not null or undefined
            assertParamExists('apiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexDelete', 'optionIndex', optionIndex)
            const localVarPath = `/api/furniture/{area_id}/update/{area_furniture_id}/option_images/{option_index}`
                .replace(`{${"area_id"}}`, encodeURIComponent(String(areaId)))
                .replace(`{${"area_furniture_id"}}`, encodeURIComponent(String(areaFurnitureId)))
                .replace(`{${"option_index"}}`, encodeURIComponent(String(optionIndex)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * エリア家具のオプション画像を変更する。 
         * @summary エリア家具オプション画像更新
         * @param {string} areaId 
         * @param {number} areaFurnitureId 
         * @param {ApiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexPostOptionIndexEnum} optionIndex オプション画像の位置インデックス
         * @param {File} file アップロードするファイル。拡張子の制限あり。  アップロード可能な拡張子（大文字小文字の区別なし） - jpg/jpeg - png - gif - webp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexPost: async (areaId: string, areaFurnitureId: number, optionIndex: ApiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexPostOptionIndexEnum, file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaId' is not null or undefined
            assertParamExists('apiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexPost', 'areaId', areaId)
            // verify required parameter 'areaFurnitureId' is not null or undefined
            assertParamExists('apiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexPost', 'areaFurnitureId', areaFurnitureId)
            // verify required parameter 'optionIndex' is not null or undefined
            assertParamExists('apiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexPost', 'optionIndex', optionIndex)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('apiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexPost', 'file', file)
            const localVarPath = `/api/furniture/{area_id}/update/{area_furniture_id}/option_images/{option_index}`
                .replace(`{${"area_id"}}`, encodeURIComponent(String(areaId)))
                .replace(`{${"area_furniture_id"}}`, encodeURIComponent(String(areaFurnitureId)))
                .replace(`{${"option_index"}}`, encodeURIComponent(String(optionIndex)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary エリア家具更新
         * @param {string} areaId 
         * @param {number} areaFurnitureId 
         * @param {ApiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequest} apiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFurnitureAreaIdUpdateAreaFurnitureIdPatch: async (areaId: string, areaFurnitureId: number, apiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequest: ApiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaId' is not null or undefined
            assertParamExists('apiFurnitureAreaIdUpdateAreaFurnitureIdPatch', 'areaId', areaId)
            // verify required parameter 'areaFurnitureId' is not null or undefined
            assertParamExists('apiFurnitureAreaIdUpdateAreaFurnitureIdPatch', 'areaFurnitureId', areaFurnitureId)
            // verify required parameter 'apiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequest' is not null or undefined
            assertParamExists('apiFurnitureAreaIdUpdateAreaFurnitureIdPatch', 'apiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequest', apiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequest)
            const localVarPath = `/api/furniture/{area_id}/update/{area_furniture_id}`
                .replace(`{${"area_id"}}`, encodeURIComponent(String(areaId)))
                .replace(`{${"area_furniture_id"}}`, encodeURIComponent(String(areaFurnitureId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した見積のエリアと家具を取得する。 
         * @summary エリア家具取得
         * @param {ApiFurnitureAreaPostRequest} [apiFurnitureAreaPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFurnitureAreaPost: async (apiFurnitureAreaPostRequest?: ApiFurnitureAreaPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/furniture/area`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiFurnitureAreaPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 品番検索の結果を取得する。 
         * @summary 品番検索
         * @param {ApiFurniturePartNumberSearchPostRequest} [apiFurniturePartNumberSearchPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFurniturePartNumberSearchPost: async (apiFurniturePartNumberSearchPostRequest?: ApiFurniturePartNumberSearchPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/furniture/partNumberSearch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiFurniturePartNumberSearchPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 絞り込み検索の結果を取得する。 
         * @summary 絞り込み検索
         * @param {ApiFurnitureSearchFurnituresPostRequest} [apiFurnitureSearchFurnituresPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFurnitureSearchFurnituresPost: async (apiFurnitureSearchFurnituresPostRequest?: ApiFurnitureSearchFurnituresPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/furniture/searchFurnitures`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiFurnitureSearchFurnituresPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary シリーズ取得
         * @param {number} furnitureSeriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFurnitureSeriesFurnitureSeriesIdGet: async (furnitureSeriesId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'furnitureSeriesId' is not null or undefined
            assertParamExists('apiFurnitureSeriesFurnitureSeriesIdGet', 'furnitureSeriesId', furnitureSeriesId)
            const localVarPath = `/api/furnitureSeries/{furnitureSeriesId}`
                .replace(`{${"furnitureSeriesId"}}`, encodeURIComponent(String(furnitureSeriesId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 関連シリーズ取得
         * @param {number} furnitureSeriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFurnitureSeriesFurnitureSeriesIdRelatedFurnituresGet: async (furnitureSeriesId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'furnitureSeriesId' is not null or undefined
            assertParamExists('apiFurnitureSeriesFurnitureSeriesIdRelatedFurnituresGet', 'furnitureSeriesId', furnitureSeriesId)
            const localVarPath = `/api/furnitureSeries/{furnitureSeriesId}/relatedFurnitures`
                .replace(`{${"furnitureSeriesId"}}`, encodeURIComponent(String(furnitureSeriesId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 経費一括取得
         * @param {string} floorQuotationId 見積ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuotationAllExpensesGet: async (floorQuotationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'floorQuotationId' is not null or undefined
            assertParamExists('apiQuotationAllExpensesGet', 'floorQuotationId', floorQuotationId)
            const localVarPath = `/api/quotation/all/expenses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (floorQuotationId !== undefined) {
                localVarQueryParameter['floor_quotation_id'] = floorQuotationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 経費更新
         * @param {number} quotationExpensesId QuotationExpense.id
         * @param {ApiQuotationExpensesQuotationExpensesIdPatchRequest} [apiQuotationExpensesQuotationExpensesIdPatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuotationExpensesQuotationExpensesIdPatch: async (quotationExpensesId: number, apiQuotationExpensesQuotationExpensesIdPatchRequest?: ApiQuotationExpensesQuotationExpensesIdPatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'quotationExpensesId' is not null or undefined
            assertParamExists('apiQuotationExpensesQuotationExpensesIdPatch', 'quotationExpensesId', quotationExpensesId)
            const localVarPath = `/api/quotation_expenses/{quotation_expenses_id}`
                .replace(`{${"quotation_expenses_id"}}`, encodeURIComponent(String(quotationExpensesId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiQuotationExpensesQuotationExpensesIdPatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary メーカー掛け率設定取得
         * @param {string} quotationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuotationQuotationIdManufactureBettingRatesGet: async (quotationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'quotationId' is not null or undefined
            assertParamExists('apiQuotationQuotationIdManufactureBettingRatesGet', 'quotationId', quotationId)
            const localVarPath = `/api/quotation/{quotationId}/manufacture_betting_rates`
                .replace(`{${"quotationId"}}`, encodeURIComponent(String(quotationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 見積設定更新
         * @param {string} quotationId 
         * @param {ApiQuotationQuotationIdUpdatePatchRequest} apiQuotationQuotationIdUpdatePatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuotationQuotationIdUpdatePatch: async (quotationId: string, apiQuotationQuotationIdUpdatePatchRequest: ApiQuotationQuotationIdUpdatePatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'quotationId' is not null or undefined
            assertParamExists('apiQuotationQuotationIdUpdatePatch', 'quotationId', quotationId)
            // verify required parameter 'apiQuotationQuotationIdUpdatePatchRequest' is not null or undefined
            assertParamExists('apiQuotationQuotationIdUpdatePatch', 'apiQuotationQuotationIdUpdatePatchRequest', apiQuotationQuotationIdUpdatePatchRequest)
            const localVarPath = `/api/quotation/{quotationId}/update`
                .replace(`{${"quotationId"}}`, encodeURIComponent(String(quotationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiQuotationQuotationIdUpdatePatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザーが所属するOrganizationに画像アップロードを行う アップロード先は `<bucket>/{organizations}/{organization_id}/{directory}/{ファイルのハッシュ値}.{extension}` とする  NOTE:   - `organizations`は環境に応じて変わる(本番: `organizations`, 開発: `organizations-dev`)   - `organization_id`はユーザーが所属するOrganizationのID 
         * @summary Organization画像アップロード
         * @param {File} imageFile 画像ファイル。形式は&#x60;jpg&#x60;, &#x60;jpeg&#x60;, &#x60;png&#x60;, &#x60;gif&#x60;, &#x60;webp&#x60;のいずれか  最大サイズは&#x60;2MB&#x60; 
         * @param {string | null} [directory] アップロード先サブディレクトリ - &#x60;..&#x60; を含む場合は422エラーとなる 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUploadFilePost: async (imageFile: File, directory?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageFile' is not null or undefined
            assertParamExists('apiUploadFilePost', 'imageFile', imageFile)
            const localVarPath = `/api/upload_file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (imageFile !== undefined) { 
                localVarFormParams.append('image_file', imageFile as any);
            }
    
            if (directory !== undefined) { 
                localVarFormParams.append('directory', directory as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * お気に入りへお気に入りセットを紐付ける。 
         * @summary お気に入りセット紐付
         * @param {number} userFavoriteFurnitureId 
         * @param {AttachFavoriteFurnitureSetRequest} [attachFavoriteFurnitureSetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachFavoriteFurnitureSet: async (userFavoriteFurnitureId: number, attachFavoriteFurnitureSetRequest?: AttachFavoriteFurnitureSetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userFavoriteFurnitureId' is not null or undefined
            assertParamExists('attachFavoriteFurnitureSet', 'userFavoriteFurnitureId', userFavoriteFurnitureId)
            const localVarPath = `/api/favorite/{userFavoriteFurnitureId}/attachSets`
                .replace(`{${"userFavoriteFurnitureId"}}`, encodeURIComponent(String(userFavoriteFurnitureId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attachFavoriteFurnitureSetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * エリアを作成する 
         * @summary エリア作成
         * @param {CreateAreaRequest} [createAreaRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createArea: async (createAreaRequest?: CreateAreaRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/area`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAreaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * お気に入りセットを追加する。 
         * @summary お気に入りセット追加
         * @param {CreateFavoriteFurnitureSetRequest} [createFavoriteFurnitureSetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFavoriteFurnitureSet: async (createFavoriteFurnitureSetRequest?: CreateFavoriteFurnitureSetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/favoriteFurnitureSets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFavoriteFurnitureSetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * エリアを削除する 
         * @summary エリア削除
         * @param {string} areaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteArea: async (areaId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaId' is not null or undefined
            assertParamExists('deleteArea', 'areaId', areaId)
            const localVarPath = `/api/area/{areaId}/delete`
                .replace(`{${"areaId"}}`, encodeURIComponent(String(areaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * お気に入りセットを削除する。 
         * @summary お気に入りセット削除
         * @param {number} favoriteFurnitureSetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFavoriteFurnitureSet: async (favoriteFurnitureSetId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'favoriteFurnitureSetId' is not null or undefined
            assertParamExists('deleteFavoriteFurnitureSet', 'favoriteFurnitureSetId', favoriteFurnitureSetId)
            const localVarPath = `/api/favoriteFurnitureSets/{favoriteFurnitureSetId}`
                .replace(`{${"favoriteFurnitureSetId"}}`, encodeURIComponent(String(favoriteFurnitureSetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 家具削除
         * @param {DeleteFurnituresRequest} [deleteFurnituresRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFurnitures: async (deleteFurnituresRequest?: DeleteFurnituresRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/furnitures`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteFurnituresRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 案件の削除
         * @summary 案件削除
         * @param {string} projectId 案件id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject: async (projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('deleteProject', 'projectId', projectId)
            const localVarPath = `/api/project/{project_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 見積削除
         * @param {string} quotationId 
         * @param {DeleteQuotationRequest} deleteQuotationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQuotation: async (quotationId: string, deleteQuotationRequest: DeleteQuotationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'quotationId' is not null or undefined
            assertParamExists('deleteQuotation', 'quotationId', quotationId)
            // verify required parameter 'deleteQuotationRequest' is not null or undefined
            assertParamExists('deleteQuotation', 'deleteQuotationRequest', deleteQuotationRequest)
            const localVarPath = `/api/quotation/{quotationId}/delete`
                .replace(`{${"quotationId"}}`, encodeURIComponent(String(quotationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteQuotationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * お気に入りセットからお気に入りの紐付けを解除する。 
         * @summary お気に入りのセット紐付解除
         * @param {number} favoriteFurnitureSetId 
         * @param {number} userFavoriteFurnitureId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detachFavoriteFurnitureSet: async (favoriteFurnitureSetId: number, userFavoriteFurnitureId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'favoriteFurnitureSetId' is not null or undefined
            assertParamExists('detachFavoriteFurnitureSet', 'favoriteFurnitureSetId', favoriteFurnitureSetId)
            // verify required parameter 'userFavoriteFurnitureId' is not null or undefined
            assertParamExists('detachFavoriteFurnitureSet', 'userFavoriteFurnitureId', userFavoriteFurnitureId)
            const localVarPath = `/api/favoriteFurnitureSets/{favoriteFurnitureSetId}/favoriteFurnitures/{userFavoriteFurnitureId}`
                .replace(`{${"favoriteFurnitureSetId"}}`, encodeURIComponent(String(favoriteFurnitureSetId)))
                .replace(`{${"userFavoriteFurnitureId"}}`, encodeURIComponent(String(userFavoriteFurnitureId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * IWOS向けCSVダウンロード 
         * @summary IWOS向けCSVダウンロード
         * @param {string} floorQuotationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadIwosCsv: async (floorQuotationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'floorQuotationId' is not null or undefined
            assertParamExists('downloadIwosCsv', 'floorQuotationId', floorQuotationId)
            const localVarPath = `/api/customers/iwos/export/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (floorQuotationId !== undefined) {
                localVarQueryParameter['floor_quotation_id'] = floorQuotationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * NAIKI社向け見積もりXMLをダウンロードする 
         * @summary NAIKI社見積もりXMLダウンロード
         * @param {string} floorQuotationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadNaikiQuotationXml: async (floorQuotationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'floorQuotationId' is not null or undefined
            assertParamExists('downloadNaikiQuotationXml', 'floorQuotationId', floorQuotationId)
            const localVarPath = `/api/customers/naiki/export/xml`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (floorQuotationId !== undefined) {
                localVarQueryParameter['floor_quotation_id'] = floorQuotationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 図面ファイルをアップロードし、展開したイメージ情報を返す
         * @summary 図面アップロード
         * @param {File} pdfFile 図面ファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extractPlanImage: async (pdfFile: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pdfFile' is not null or undefined
            assertParamExists('extractPlanImage', 'pdfFile', pdfFile)
            const localVarPath = `/api/quotation/extract/image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (pdfFile !== undefined) { 
                localVarFormParams.append('pdf_file', pdfFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 見積IDからエリアを取得する 
         * @summary 見積IDからエリア取得
         * @param {string} quotationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAreaByQuotation: async (quotationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'quotationId' is not null or undefined
            assertParamExists('getAreaByQuotation', 'quotationId', quotationId)
            const localVarPath = `/api/area/{quotationId}/by/quotation`
                .replace(`{${"quotationId"}}`, encodeURIComponent(String(quotationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 従量課金の使用状況を取得する。 
         * @summary 従量課金の使用状況取得
         * @param {string} yearMonth 従量課金の使用状況を取得する年月
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBillingUsage: async (yearMonth: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'yearMonth' is not null or undefined
            assertParamExists('getBillingUsage', 'yearMonth', yearMonth)
            const localVarPath = `/api/billing/usage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (yearMonth !== undefined) {
                localVarQueryParameter['year_month'] = yearMonth;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザーに紐づくCompanyを取得する
         * @summary Company取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompany: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 経費セット一覧を取得する。
         * @summary 経費セット一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExpenseSets: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/quotation/expense/groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 見積家具リスト一括ダウンロード
         * @param {string} floorQuotationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExportRequestQuotationExcelFloorQuotation: async (floorQuotationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'floorQuotationId' is not null or undefined
            assertParamExists('getExportRequestQuotationExcelFloorQuotation', 'floorQuotationId', floorQuotationId)
            const localVarPath = `/api/export/request_quotation_excel/{floor_quotation_id}`
                .replace(`{${"floor_quotation_id"}}`, encodeURIComponent(String(floorQuotationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * お気に入りセットを取得する。 
         * @summary お気に入りセット取得
         * @param {number} favoriteFurnitureSetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavoriteFurnitureSet: async (favoriteFurnitureSetId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'favoriteFurnitureSetId' is not null or undefined
            assertParamExists('getFavoriteFurnitureSet', 'favoriteFurnitureSetId', favoriteFurnitureSetId)
            const localVarPath = `/api/favoriteFurnitureSets/{favoriteFurnitureSetId}`
                .replace(`{${"favoriteFurnitureSetId"}}`, encodeURIComponent(String(favoriteFurnitureSetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * お気に入りセット一覧を取得する。 
         * @summary お気に入りセット一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavoriteFurnitureSets: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/favoriteFurnitureSets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 家具のタグ取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFurnitureTags: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/furnitures/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 切り貼りテンプレート取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKiribariTemplates: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * メーカー一覧を取得する
         * @summary メーカー一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManufactures: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/manufactures`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザーの組織に紐づくメーカーを取得する
         * @summary 組織に紐づくメーカー一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationManufactures: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/manufactures/organization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 案件の取得
         * @summary 案件取得
         * @param {string} projectId 案件id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject: async (projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProject', 'projectId', projectId)
            const localVarPath = `/api/project/{project_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * プロジェクトに紐づくCompanyを取得する
         * @summary プロジェクトのCompany取得
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectCompany: async (projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectCompany', 'projectId', projectId)
            const localVarPath = `/api/company/{project_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ログインユーザーの案件一覧を取得する
         * @summary 案件一覧取得
         * @param {string} [managerName] 担当者名で絞り込み
         * @param {string} [search] 案件名で絞り込み
         * @param {number} [page] ページ番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects: async (managerName?: string, search?: string, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/project`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (managerName !== undefined) {
                localVarQueryParameter['manager_name'] = managerName;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ログインしているユーザの情報を取得する。
         * @summary ユーザ情報取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * メールアドレスとパスワードを使用してログインする。
         * @summary ログイン
         * @param {LoginRequest} [loginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (loginRequest?: LoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Firebase を使用して外部サービスのアカウントで Swish にログインする。
         * @summary 外部サービスログイン
         * @param {LoginWithFirebaseRequest} [loginWithFirebaseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginWithFirebase: async (loginWithFirebaseRequest?: LoginWithFirebaseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/firebase`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginWithFirebaseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 家具一括追加/更新
         * @param {PutFurnituresRequest} [putFurnituresRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFurnitures: async (putFurnituresRequest?: PutFurnituresRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/furnitures`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putFurnituresRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * お気に入りセット内のお気に入りの並び順を更新する。 
         * @summary お気に入りセット内のお気に入り並び順更新
         * @param {number} favoriteFurnitureSetId 
         * @param {ReorderFavoriteFurnitureInSetRequest} [reorderFavoriteFurnitureInSetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reorderFavoriteFurnitureInSet: async (favoriteFurnitureSetId: number, reorderFavoriteFurnitureInSetRequest?: ReorderFavoriteFurnitureInSetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'favoriteFurnitureSetId' is not null or undefined
            assertParamExists('reorderFavoriteFurnitureInSet', 'favoriteFurnitureSetId', favoriteFurnitureSetId)
            const localVarPath = `/api/favoriteFurnitureSets/{favoriteFurnitureSetId}/order`
                .replace(`{${"favoriteFurnitureSetId"}}`, encodeURIComponent(String(favoriteFurnitureSetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reorderFavoriteFurnitureInSetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * お気に入りセットの並び順を更新する。 
         * @summary お気に入りセット並び順更新
         * @param {AttachFavoriteFurnitureSetRequest} [attachFavoriteFurnitureSetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reorderFavoriteFurnitureSets: async (attachFavoriteFurnitureSetRequest?: AttachFavoriteFurnitureSetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/favoriteFurnitureSets/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attachFavoriteFurnitureSetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定されたパスワードでリセットする。 
         * @summary パスワード更新
         * @param {ResetPasswordRequest} [resetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (resetPasswordRequest?: ResetPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reset-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * エリアのスタンプを保存する 
         * @summary エリアのスタンプ保存
         * @param {string} areaId 
         * @param {SaveAreaStampsRequest} [saveAreaStampsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAreaStamps: async (areaId: string, saveAreaStampsRequest?: SaveAreaStampsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaId' is not null or undefined
            assertParamExists('saveAreaStamps', 'areaId', areaId)
            const localVarPath = `/api/area/{areaId}/saveStamps`
                .replace(`{${"areaId"}}`, encodeURIComponent(String(areaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveAreaStampsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 経費セットを追加・更新する。
         * @summary 経費セットの保存
         * @param {SaveExpenseSetRequest} [saveExpenseSetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveExpenseSet: async (saveExpenseSetRequest?: SaveExpenseSetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/quotation/group/expenses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveExpenseSetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 家具を指定のエリアに追加する。
         * @summary 家具追加
         * @param {SaveFurnitureRequest} [saveFurnitureRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveFurniture: async (saveFurnitureRequest?: SaveFurnitureRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/furniture/saveFurniture`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveFurnitureRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 複数の家具を指定のエリアに一括で追加する。  NOTE: メニーオプションを持つ家具は現時点では未対応なのでバリデーションエラーとなる <a href=\"https://linear.app/swish-inc/issue/SW-24#comment-b67e4820\">関連コメント</a> 
         * @summary 家具一括追加
         * @param {SaveFurnituresRequest} [saveFurnituresRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveFurnitures: async (saveFurnituresRequest?: SaveFurnituresRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/furniture/saveFurnitures`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveFurnituresRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 家具一括品番検索
         * @param {SearchFurnituresByPartsNumbersRequest} [searchFurnituresByPartsNumbersRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchFurnituresByPartsNumbers: async (searchFurnituresByPartsNumbersRequest?: SearchFurnituresByPartsNumbersRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/furnitures/searchByPartsNumbers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchFurnituresByPartsNumbersRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary パスワードリセットリンクの送信
         * @param {SendPasswordResetLinkRequest} [sendPasswordResetLinkRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPasswordResetLink: async (sendPasswordResetLinkRequest?: SendPasswordResetLinkRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/send_password_reset_link`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendPasswordResetLinkRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 経費一括追加/更新/削除
         * @param {StoreQuotationExpensesRequest} [storeQuotationExpensesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeQuotationExpenses: async (storeQuotationExpensesRequest?: StoreQuotationExpensesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/quotation/expenses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storeQuotationExpensesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * エリアを更新する 
         * @summary エリア更新
         * @param {string} areaId 
         * @param {UpdateAreaRequest} [updateAreaRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArea: async (areaId: string, updateAreaRequest?: UpdateAreaRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaId' is not null or undefined
            assertParamExists('updateArea', 'areaId', areaId)
            const localVarPath = `/api/area/{areaId}/update`
                .replace(`{${"areaId"}}`, encodeURIComponent(String(areaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAreaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * エリア家具の備考欄メモを更新する。 
         * @summary エリア家具備考欄メモ更新
         * @param {number} areaFurnitureId エリア家具ID
         * @param {UpdateAreaFurnitureNotesRequest} [updateAreaFurnitureNotesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAreaFurnitureNotes: async (areaFurnitureId: number, updateAreaFurnitureNotesRequest?: UpdateAreaFurnitureNotesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaFurnitureId' is not null or undefined
            assertParamExists('updateAreaFurnitureNotes', 'areaFurnitureId', areaFurnitureId)
            const localVarPath = `/api/areaFurniture/{areaFurnitureId}/updateNotes`
                .replace(`{${"areaFurnitureId"}}`, encodeURIComponent(String(areaFurnitureId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAreaFurnitureNotesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * エリアの名称を更新する 
         * @summary エリア名更新
         * @param {string} areaId 
         * @param {UpdateAreaNameRequest} [updateAreaNameRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAreaName: async (areaId: string, updateAreaNameRequest?: UpdateAreaNameRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaId' is not null or undefined
            assertParamExists('updateAreaName', 'areaId', areaId)
            const localVarPath = `/api/area/{areaId}/updateName`
                .replace(`{${"areaId"}}`, encodeURIComponent(String(areaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAreaNameRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * エリアの並び順を更新する 
         * @summary エリアの並び順を更新
         * @param {UpdateAreaSortOrderRequest} [updateAreaSortOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAreaSortOrder: async (updateAreaSortOrderRequest?: UpdateAreaSortOrderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/area/sort/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAreaSortOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 会社情報を更新する
         * @summary Company更新
         * @param {UpdateCompanyRequest} [updateCompanyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompany: async (updateCompanyRequest?: UpdateCompanyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/company/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCompanyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * お気に入りセットを更新する。 
         * @summary お気に入りセット更新
         * @param {number} favoriteFurnitureSetId 
         * @param {CreateFavoriteFurnitureSetRequest} [createFavoriteFurnitureSetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFavoriteFurnitureSet: async (favoriteFurnitureSetId: number, createFavoriteFurnitureSetRequest?: CreateFavoriteFurnitureSetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'favoriteFurnitureSetId' is not null or undefined
            assertParamExists('updateFavoriteFurnitureSet', 'favoriteFurnitureSetId', favoriteFurnitureSetId)
            const localVarPath = `/api/favoriteFurnitureSets/{favoriteFurnitureSetId}`
                .replace(`{${"favoriteFurnitureSetId"}}`, encodeURIComponent(String(favoriteFurnitureSetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFavoriteFurnitureSetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * エリアの家具を変更する。
         * @summary 家具更新
         * @param {string} areaId 
         * @param {number} areaFurnitureId 
         * @param {UpdateFurnitureRequest} [updateFurnitureRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFurniture: async (areaId: string, areaFurnitureId: number, updateFurnitureRequest?: UpdateFurnitureRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaId' is not null or undefined
            assertParamExists('updateFurniture', 'areaId', areaId)
            // verify required parameter 'areaFurnitureId' is not null or undefined
            assertParamExists('updateFurniture', 'areaFurnitureId', areaFurnitureId)
            const localVarPath = `/api/furniture/{area_id}/updateFurniture/{area_furniture_id}`
                .replace(`{${"area_id"}}`, encodeURIComponent(String(areaId)))
                .replace(`{${"area_furniture_id"}}`, encodeURIComponent(String(areaFurnitureId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFurnitureRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 案件の更新
         * @summary 案件更新
         * @param {string} projectId 案件id
         * @param {UpdateProjectRequest} updateProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject: async (projectId: string, updateProjectRequest: UpdateProjectRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('updateProject', 'projectId', projectId)
            // verify required parameter 'updateProjectRequest' is not null or undefined
            assertParamExists('updateProject', 'updateProjectRequest', updateProjectRequest)
            const localVarPath = `/api/project/{project_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProjectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 見積詳細の基本情報を更新する。 
         * @summary 見積詳細基本情報更新
         * @param {string} quotationId 
         * @param {UpdateQuotationDetailsRequest} updateQuotationDetailsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQuotationDetails: async (quotationId: string, updateQuotationDetailsRequest: UpdateQuotationDetailsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'quotationId' is not null or undefined
            assertParamExists('updateQuotationDetails', 'quotationId', quotationId)
            // verify required parameter 'updateQuotationDetailsRequest' is not null or undefined
            assertParamExists('updateQuotationDetails', 'updateQuotationDetailsRequest', updateQuotationDetailsRequest)
            const localVarPath = `/api/quotation/{quotationId}/details/update`
                .replace(`{${"quotationId"}}`, encodeURIComponent(String(quotationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateQuotationDetailsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 見積名、図面を更新する。 
         * @summary 見積名・図面更新
         * @param {string} quotationId 
         * @param {UpdateQuotationPdfRequest} updateQuotationPdfRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQuotationPdf: async (quotationId: string, updateQuotationPdfRequest: UpdateQuotationPdfRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'quotationId' is not null or undefined
            assertParamExists('updateQuotationPdf', 'quotationId', quotationId)
            // verify required parameter 'updateQuotationPdfRequest' is not null or undefined
            assertParamExists('updateQuotationPdf', 'updateQuotationPdfRequest', updateQuotationPdfRequest)
            const localVarPath = `/api/quotation/{quotationId}/pdf/update`
                .replace(`{${"quotationId"}}`, encodeURIComponent(String(quotationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateQuotationPdfRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * ログインしている管理者の情報を取得する。
         * @summary 管理者情報取得
         * @param {AdminApiAuthLoginPostRequest} [adminApiAuthLoginPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminApiAuthAdminGet(adminApiAuthLoginPostRequest?: AdminApiAuthLoginPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginAdmin>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminApiAuthAdminGet(adminApiAuthLoginPostRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.adminApiAuthAdminGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 管理者のメールアドレスとパスワードを使用してログインする。
         * @summary 管理者ログイン
         * @param {AdminApiAuthLoginPostRequest} [adminApiAuthLoginPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminApiAuthLoginPost(adminApiAuthLoginPostRequest?: AdminApiAuthLoginPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginAdmin>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminApiAuthLoginPost(adminApiAuthLoginPostRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.adminApiAuthLoginPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * エリア家具を複製する。 
         * @summary エリア家具複製
         * @param {number} areaFurnitureId エリア家具ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAreaFurnitureAreaFurnitureIdDuplicatePost(areaFurnitureId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiAreaFurnitureAreaFurnitureIdDuplicatePost201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAreaFurnitureAreaFurnitureIdDuplicatePost(areaFurnitureId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiAreaFurnitureAreaFurnitureIdDuplicatePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary カテゴリー検索
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCategoriesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiCategoriesGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCategoriesGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiCategoriesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary カスタム家具作成
         * @param {ApiCustomFurnitureCreatePostRequest} apiCustomFurnitureCreatePostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomFurnitureCreatePost(apiCustomFurnitureCreatePostRequest: ApiCustomFurnitureCreatePostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiCustomFurnitureCreatePost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomFurnitureCreatePost(apiCustomFurnitureCreatePostRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiCustomFurnitureCreatePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * カスタム家具を複製する。 
         * @summary カスタム家具複製
         * @param {number} customFurnitureId カスタム家具ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomFurnitureCustomFurnitureIdDuplicatePost(customFurnitureId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiCustomFurnitureCustomFurnitureIdDuplicatePost201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomFurnitureCustomFurnitureIdDuplicatePost(customFurnitureId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiCustomFurnitureCustomFurnitureIdDuplicatePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary カスタム家具更新
         * @param {ApiCustomFurnitureCreatePostRequestCustomFurnituresInner} apiCustomFurnitureCreatePostRequestCustomFurnituresInner 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomFurnitureUpdatePatch(apiCustomFurnitureCreatePostRequestCustomFurnituresInner: ApiCustomFurnitureCreatePostRequestCustomFurnituresInner, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomFurnitureUpdatePatch(apiCustomFurnitureCreatePostRequestCustomFurnituresInner, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiCustomFurnitureUpdatePatch']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * カスタム家具のメイン画像を削除する。 
         * @summary カスタム家具メイン画像削除
         * @param {number} customFurnitureId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomFurnituresCustomFurnitureIdMainImageDelete(customFurnitureId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomFurnituresCustomFurnitureIdMainImageDelete(customFurnitureId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiCustomFurnituresCustomFurnitureIdMainImageDelete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * カスタム家具のメイン画像を変更する。 
         * @summary カスタム家具メイン画像更新
         * @param {number} customFurnitureId 
         * @param {File} file アップロードするファイル。拡張子の制限あり。  アップロード可能な拡張子（大文字小文字の区別なし） - jpg/jpeg - png - gif - webp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomFurnituresCustomFurnitureIdMainImagePost(customFurnitureId: number, file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomFurnituresCustomFurnitureIdMainImagePost(customFurnitureId, file, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiCustomFurnituresCustomFurnitureIdMainImagePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * カスタム家具のオプション画像を削除する。 
         * @summary カスタム家具オプション画像削除
         * @param {number} customFurnitureId 
         * @param {ApiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexDeleteOptionIndexEnum} optionIndex オプション画像の位置インデックス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexDelete(customFurnitureId: number, optionIndex: ApiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexDeleteOptionIndexEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexDelete(customFurnitureId, optionIndex, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexDelete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * カスタム家具のオプション画像を変更する。 
         * @summary カスタム家具オプション画像更新
         * @param {number} customFurnitureId 
         * @param {ApiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexPostOptionIndexEnum} optionIndex オプション画像の位置インデックス
         * @param {File} file アップロードするファイル。拡張子の制限あり。  アップロード可能な拡張子（大文字小文字の区別なし） - jpg/jpeg - png - gif - webp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexPost(customFurnitureId: number, optionIndex: ApiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexPostOptionIndexEnum, file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexPost(customFurnitureId, optionIndex, file, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * お気に入りのリストを取得する。 
         * @summary お気に入り一覧取得
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async apiFavoriteGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiFavoriteGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFavoriteGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiFavoriteGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 選択した家具をお気に入りに追加する。 
         * @summary お気に入り追加
         * @param {ApiFavoritePostRequest} [apiFavoritePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFavoritePost(apiFavoritePostRequest?: ApiFavoritePostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFavoritePost(apiFavoritePostRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiFavoritePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * エリア家具を削除する。
         * @summary エリア家具削除
         * @param {number} areaFurnitureId エリア家具ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFurnitureAreaFurnitureIdDelete(areaFurnitureId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFurnitureAreaFurnitureIdDelete(areaFurnitureId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiFurnitureAreaFurnitureIdDelete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * エリア家具のメイン画像を削除する。 
         * @summary エリア家具メイン画像削除
         * @param {string} areaId 
         * @param {number} areaFurnitureId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFurnitureAreaIdUpdateAreaFurnitureIdMainImageDelete(areaId: string, areaFurnitureId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFurnitureAreaIdUpdateAreaFurnitureIdMainImageDelete(areaId, areaFurnitureId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiFurnitureAreaIdUpdateAreaFurnitureIdMainImageDelete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * エリア家具のメイン画像を変更する。 
         * @summary エリア家具メイン画像更新
         * @param {string} areaId 
         * @param {number} areaFurnitureId 
         * @param {File} file アップロードするファイル。拡張子の制限あり。  アップロード可能な拡張子（大文字小文字の区別なし） - jpg/jpeg - png - gif - webp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFurnitureAreaIdUpdateAreaFurnitureIdMainImagePost(areaId: string, areaFurnitureId: number, file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFurnitureAreaIdUpdateAreaFurnitureIdMainImagePost(areaId, areaFurnitureId, file, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiFurnitureAreaIdUpdateAreaFurnitureIdMainImagePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * エリア家具のオプション画像を削除する。 
         * @summary エリア家具オプション画像削除
         * @param {string} areaId 
         * @param {number} areaFurnitureId 
         * @param {ApiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexDeleteOptionIndexEnum} optionIndex オプション画像の位置インデックス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexDelete(areaId: string, areaFurnitureId: number, optionIndex: ApiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexDeleteOptionIndexEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexDelete(areaId, areaFurnitureId, optionIndex, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexDelete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * エリア家具のオプション画像を変更する。 
         * @summary エリア家具オプション画像更新
         * @param {string} areaId 
         * @param {number} areaFurnitureId 
         * @param {ApiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexPostOptionIndexEnum} optionIndex オプション画像の位置インデックス
         * @param {File} file アップロードするファイル。拡張子の制限あり。  アップロード可能な拡張子（大文字小文字の区別なし） - jpg/jpeg - png - gif - webp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexPost(areaId: string, areaFurnitureId: number, optionIndex: ApiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexPostOptionIndexEnum, file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexPost(areaId, areaFurnitureId, optionIndex, file, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary エリア家具更新
         * @param {string} areaId 
         * @param {number} areaFurnitureId 
         * @param {ApiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequest} apiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFurnitureAreaIdUpdateAreaFurnitureIdPatch(areaId: string, areaFurnitureId: number, apiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequest: ApiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiFurnitureAreaIdUpdateAreaFurnitureIdPatch200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFurnitureAreaIdUpdateAreaFurnitureIdPatch(areaId, areaFurnitureId, apiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiFurnitureAreaIdUpdateAreaFurnitureIdPatch']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 指定した見積のエリアと家具を取得する。 
         * @summary エリア家具取得
         * @param {ApiFurnitureAreaPostRequest} [apiFurnitureAreaPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFurnitureAreaPost(apiFurnitureAreaPostRequest?: ApiFurnitureAreaPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiFurnitureAreaPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFurnitureAreaPost(apiFurnitureAreaPostRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiFurnitureAreaPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 品番検索の結果を取得する。 
         * @summary 品番検索
         * @param {ApiFurniturePartNumberSearchPostRequest} [apiFurniturePartNumberSearchPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFurniturePartNumberSearchPost(apiFurniturePartNumberSearchPostRequest?: ApiFurniturePartNumberSearchPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiFurniturePartNumberSearchPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFurniturePartNumberSearchPost(apiFurniturePartNumberSearchPostRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiFurniturePartNumberSearchPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 絞り込み検索の結果を取得する。 
         * @summary 絞り込み検索
         * @param {ApiFurnitureSearchFurnituresPostRequest} [apiFurnitureSearchFurnituresPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFurnitureSearchFurnituresPost(apiFurnitureSearchFurnituresPostRequest?: ApiFurnitureSearchFurnituresPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiFurnitureSearchFurnituresPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFurnitureSearchFurnituresPost(apiFurnitureSearchFurnituresPostRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiFurnitureSearchFurnituresPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary シリーズ取得
         * @param {number} furnitureSeriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFurnitureSeriesFurnitureSeriesIdGet(furnitureSeriesId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiFurnitureSeriesFurnitureSeriesIdGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFurnitureSeriesFurnitureSeriesIdGet(furnitureSeriesId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiFurnitureSeriesFurnitureSeriesIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary 関連シリーズ取得
         * @param {number} furnitureSeriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFurnitureSeriesFurnitureSeriesIdRelatedFurnituresGet(furnitureSeriesId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiFurnitureSeriesFurnitureSeriesIdRelatedFurnituresGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFurnitureSeriesFurnitureSeriesIdRelatedFurnituresGet(furnitureSeriesId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiFurnitureSeriesFurnitureSeriesIdRelatedFurnituresGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary 経費一括取得
         * @param {string} floorQuotationId 見積ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiQuotationAllExpensesGet(floorQuotationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiQuotationAllExpensesGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiQuotationAllExpensesGet(floorQuotationId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiQuotationAllExpensesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary 経費更新
         * @param {number} quotationExpensesId QuotationExpense.id
         * @param {ApiQuotationExpensesQuotationExpensesIdPatchRequest} [apiQuotationExpensesQuotationExpensesIdPatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiQuotationExpensesQuotationExpensesIdPatch(quotationExpensesId: number, apiQuotationExpensesQuotationExpensesIdPatchRequest?: ApiQuotationExpensesQuotationExpensesIdPatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiQuotationExpensesQuotationExpensesIdPatch200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiQuotationExpensesQuotationExpensesIdPatch(quotationExpensesId, apiQuotationExpensesQuotationExpensesIdPatchRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiQuotationExpensesQuotationExpensesIdPatch']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary メーカー掛け率設定取得
         * @param {string} quotationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiQuotationQuotationIdManufactureBettingRatesGet(quotationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiQuotationQuotationIdManufactureBettingRatesGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiQuotationQuotationIdManufactureBettingRatesGet(quotationId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiQuotationQuotationIdManufactureBettingRatesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary 見積設定更新
         * @param {string} quotationId 
         * @param {ApiQuotationQuotationIdUpdatePatchRequest} apiQuotationQuotationIdUpdatePatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiQuotationQuotationIdUpdatePatch(quotationId: string, apiQuotationQuotationIdUpdatePatchRequest: ApiQuotationQuotationIdUpdatePatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiQuotationQuotationIdUpdatePatch(quotationId, apiQuotationQuotationIdUpdatePatchRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiQuotationQuotationIdUpdatePatch']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * ユーザーが所属するOrganizationに画像アップロードを行う アップロード先は `<bucket>/{organizations}/{organization_id}/{directory}/{ファイルのハッシュ値}.{extension}` とする  NOTE:   - `organizations`は環境に応じて変わる(本番: `organizations`, 開発: `organizations-dev`)   - `organization_id`はユーザーが所属するOrganizationのID 
         * @summary Organization画像アップロード
         * @param {File} imageFile 画像ファイル。形式は&#x60;jpg&#x60;, &#x60;jpeg&#x60;, &#x60;png&#x60;, &#x60;gif&#x60;, &#x60;webp&#x60;のいずれか  最大サイズは&#x60;2MB&#x60; 
         * @param {string | null} [directory] アップロード先サブディレクトリ - &#x60;..&#x60; を含む場合は422エラーとなる 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUploadFilePost(imageFile: File, directory?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiUploadFilePost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUploadFilePost(imageFile, directory, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiUploadFilePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * お気に入りへお気に入りセットを紐付ける。 
         * @summary お気に入りセット紐付
         * @param {number} userFavoriteFurnitureId 
         * @param {AttachFavoriteFurnitureSetRequest} [attachFavoriteFurnitureSetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachFavoriteFurnitureSet(userFavoriteFurnitureId: number, attachFavoriteFurnitureSetRequest?: AttachFavoriteFurnitureSetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachFavoriteFurnitureSet(userFavoriteFurnitureId, attachFavoriteFurnitureSetRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.attachFavoriteFurnitureSet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * エリアを作成する 
         * @summary エリア作成
         * @param {CreateAreaRequest} [createAreaRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createArea(createAreaRequest?: CreateAreaRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createArea(createAreaRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.createArea']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * お気に入りセットを追加する。 
         * @summary お気に入りセット追加
         * @param {CreateFavoriteFurnitureSetRequest} [createFavoriteFurnitureSetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFavoriteFurnitureSet(createFavoriteFurnitureSetRequest?: CreateFavoriteFurnitureSetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateFavoriteFurnitureSet201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFavoriteFurnitureSet(createFavoriteFurnitureSetRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.createFavoriteFurnitureSet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * エリアを削除する 
         * @summary エリア削除
         * @param {string} areaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteArea(areaId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteArea(areaId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.deleteArea']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * お気に入りセットを削除する。 
         * @summary お気に入りセット削除
         * @param {number} favoriteFurnitureSetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFavoriteFurnitureSet(favoriteFurnitureSetId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFavoriteFurnitureSet(favoriteFurnitureSetId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.deleteFavoriteFurnitureSet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary 家具削除
         * @param {DeleteFurnituresRequest} [deleteFurnituresRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFurnitures(deleteFurnituresRequest?: DeleteFurnituresRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFurnitures(deleteFurnituresRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.deleteFurnitures']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 案件の削除
         * @summary 案件削除
         * @param {string} projectId 案件id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProject(projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProject(projectId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.deleteProject']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary 見積削除
         * @param {string} quotationId 
         * @param {DeleteQuotationRequest} deleteQuotationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteQuotation(quotationId: string, deleteQuotationRequest: DeleteQuotationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteQuotation200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteQuotation(quotationId, deleteQuotationRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.deleteQuotation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * お気に入りセットからお気に入りの紐付けを解除する。 
         * @summary お気に入りのセット紐付解除
         * @param {number} favoriteFurnitureSetId 
         * @param {number} userFavoriteFurnitureId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detachFavoriteFurnitureSet(favoriteFurnitureSetId: number, userFavoriteFurnitureId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detachFavoriteFurnitureSet(favoriteFurnitureSetId, userFavoriteFurnitureId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.detachFavoriteFurnitureSet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * IWOS向けCSVダウンロード 
         * @summary IWOS向けCSVダウンロード
         * @param {string} floorQuotationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadIwosCsv(floorQuotationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadIwosCsv(floorQuotationId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.downloadIwosCsv']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * NAIKI社向け見積もりXMLをダウンロードする 
         * @summary NAIKI社見積もりXMLダウンロード
         * @param {string} floorQuotationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadNaikiQuotationXml(floorQuotationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadNaikiQuotationXml(floorQuotationId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.downloadNaikiQuotationXml']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 図面ファイルをアップロードし、展開したイメージ情報を返す
         * @summary 図面アップロード
         * @param {File} pdfFile 図面ファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async extractPlanImage(pdfFile: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExtractPlanImage200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.extractPlanImage(pdfFile, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.extractPlanImage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 見積IDからエリアを取得する 
         * @summary 見積IDからエリア取得
         * @param {string} quotationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAreaByQuotation(quotationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAreaByQuotation200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAreaByQuotation(quotationId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getAreaByQuotation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 従量課金の使用状況を取得する。 
         * @summary 従量課金の使用状況取得
         * @param {string} yearMonth 従量課金の使用状況を取得する年月
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBillingUsage(yearMonth: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBillingUsage200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBillingUsage(yearMonth, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getBillingUsage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * ユーザーに紐づくCompanyを取得する
         * @summary Company取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompany(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCompany200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompany(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getCompany']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 経費セット一覧を取得する。
         * @summary 経費セット一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExpenseSets(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetExpenseSets200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExpenseSets(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getExpenseSets']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary 見積家具リスト一括ダウンロード
         * @param {string} floorQuotationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExportRequestQuotationExcelFloorQuotation(floorQuotationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExportRequestQuotationExcelFloorQuotation(floorQuotationId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getExportRequestQuotationExcelFloorQuotation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * お気に入りセットを取得する。 
         * @summary お気に入りセット取得
         * @param {number} favoriteFurnitureSetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFavoriteFurnitureSet(favoriteFurnitureSetId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateFavoriteFurnitureSet201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFavoriteFurnitureSet(favoriteFurnitureSetId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getFavoriteFurnitureSet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * お気に入りセット一覧を取得する。 
         * @summary お気に入りセット一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFavoriteFurnitureSets(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFavoriteFurnitureSets200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFavoriteFurnitureSets(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getFavoriteFurnitureSets']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary 家具のタグ取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFurnitureTags(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFurnitureTags200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFurnitureTags(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getFurnitureTags']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary 切り貼りテンプレート取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKiribariTemplates(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetKiribariTemplates200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getKiribariTemplates(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getKiribariTemplates']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * メーカー一覧を取得する
         * @summary メーカー一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManufactures(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManufactures200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManufactures(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getManufactures']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * ユーザーの組織に紐づくメーカーを取得する
         * @summary 組織に紐づくメーカー一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationManufactures(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOrganizationManufactures200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationManufactures(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getOrganizationManufactures']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 案件の取得
         * @summary 案件取得
         * @param {string} projectId 案件id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProject(projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProject200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProject(projectId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getProject']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * プロジェクトに紐づくCompanyを取得する
         * @summary プロジェクトのCompany取得
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectCompany(projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCompany200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectCompany(projectId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getProjectCompany']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * ログインユーザーの案件一覧を取得する
         * @summary 案件一覧取得
         * @param {string} [managerName] 担当者名で絞り込み
         * @param {string} [search] 案件名で絞り込み
         * @param {number} [page] ページ番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjects(managerName?: string, search?: string, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjects(managerName, search, page, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getProjects']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * ログインしているユーザの情報を取得する。
         * @summary ユーザ情報取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUser200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * メールアドレスとパスワードを使用してログインする。
         * @summary ログイン
         * @param {LoginRequest} [loginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(loginRequest?: LoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(loginRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.login']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Firebase を使用して外部サービスのアカウントで Swish にログインする。
         * @summary 外部サービスログイン
         * @param {LoginWithFirebaseRequest} [loginWithFirebaseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginWithFirebase(loginWithFirebaseRequest?: LoginWithFirebaseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginWithFirebase(loginWithFirebaseRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.loginWithFirebase']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary 家具一括追加/更新
         * @param {PutFurnituresRequest} [putFurnituresRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putFurnitures(putFurnituresRequest?: PutFurnituresRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PutFurnitures200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putFurnitures(putFurnituresRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.putFurnitures']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * お気に入りセット内のお気に入りの並び順を更新する。 
         * @summary お気に入りセット内のお気に入り並び順更新
         * @param {number} favoriteFurnitureSetId 
         * @param {ReorderFavoriteFurnitureInSetRequest} [reorderFavoriteFurnitureInSetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reorderFavoriteFurnitureInSet(favoriteFurnitureSetId: number, reorderFavoriteFurnitureInSetRequest?: ReorderFavoriteFurnitureInSetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reorderFavoriteFurnitureInSet(favoriteFurnitureSetId, reorderFavoriteFurnitureInSetRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.reorderFavoriteFurnitureInSet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * お気に入りセットの並び順を更新する。 
         * @summary お気に入りセット並び順更新
         * @param {AttachFavoriteFurnitureSetRequest} [attachFavoriteFurnitureSetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reorderFavoriteFurnitureSets(attachFavoriteFurnitureSetRequest?: AttachFavoriteFurnitureSetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reorderFavoriteFurnitureSets(attachFavoriteFurnitureSetRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.reorderFavoriteFurnitureSets']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 指定されたパスワードでリセットする。 
         * @summary パスワード更新
         * @param {ResetPasswordRequest} [resetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(resetPasswordRequest?: ResetPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(resetPasswordRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.resetPassword']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * エリアのスタンプを保存する 
         * @summary エリアのスタンプ保存
         * @param {string} areaId 
         * @param {SaveAreaStampsRequest} [saveAreaStampsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveAreaStamps(areaId: string, saveAreaStampsRequest?: SaveAreaStampsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveAreaStamps(areaId, saveAreaStampsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.saveAreaStamps']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 経費セットを追加・更新する。
         * @summary 経費セットの保存
         * @param {SaveExpenseSetRequest} [saveExpenseSetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveExpenseSet(saveExpenseSetRequest?: SaveExpenseSetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveExpenseSet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveExpenseSet(saveExpenseSetRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.saveExpenseSet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 家具を指定のエリアに追加する。
         * @summary 家具追加
         * @param {SaveFurnitureRequest} [saveFurnitureRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveFurniture(saveFurnitureRequest?: SaveFurnitureRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AreaFurniture>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveFurniture(saveFurnitureRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.saveFurniture']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 複数の家具を指定のエリアに一括で追加する。  NOTE: メニーオプションを持つ家具は現時点では未対応なのでバリデーションエラーとなる <a href=\"https://linear.app/swish-inc/issue/SW-24#comment-b67e4820\">関連コメント</a> 
         * @summary 家具一括追加
         * @param {SaveFurnituresRequest} [saveFurnituresRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveFurnitures(saveFurnituresRequest?: SaveFurnituresRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveFurnitures(saveFurnituresRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.saveFurnitures']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary 家具一括品番検索
         * @param {SearchFurnituresByPartsNumbersRequest} [searchFurnituresByPartsNumbersRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchFurnituresByPartsNumbers(searchFurnituresByPartsNumbersRequest?: SearchFurnituresByPartsNumbersRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchFurnituresByPartsNumbers200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchFurnituresByPartsNumbers(searchFurnituresByPartsNumbersRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.searchFurnituresByPartsNumbers']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary パスワードリセットリンクの送信
         * @param {SendPasswordResetLinkRequest} [sendPasswordResetLinkRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendPasswordResetLink(sendPasswordResetLinkRequest?: SendPasswordResetLinkRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendPasswordResetLink(sendPasswordResetLinkRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.sendPasswordResetLink']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary 経費一括追加/更新/削除
         * @param {StoreQuotationExpensesRequest} [storeQuotationExpensesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeQuotationExpenses(storeQuotationExpensesRequest?: StoreQuotationExpensesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreQuotationExpenses200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeQuotationExpenses(storeQuotationExpensesRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.storeQuotationExpenses']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * エリアを更新する 
         * @summary エリア更新
         * @param {string} areaId 
         * @param {UpdateAreaRequest} [updateAreaRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateArea(areaId: string, updateAreaRequest?: UpdateAreaRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateArea(areaId, updateAreaRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.updateArea']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * エリア家具の備考欄メモを更新する。 
         * @summary エリア家具備考欄メモ更新
         * @param {number} areaFurnitureId エリア家具ID
         * @param {UpdateAreaFurnitureNotesRequest} [updateAreaFurnitureNotesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAreaFurnitureNotes(areaFurnitureId: number, updateAreaFurnitureNotesRequest?: UpdateAreaFurnitureNotesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAreaFurnitureNotes(areaFurnitureId, updateAreaFurnitureNotesRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.updateAreaFurnitureNotes']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * エリアの名称を更新する 
         * @summary エリア名更新
         * @param {string} areaId 
         * @param {UpdateAreaNameRequest} [updateAreaNameRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAreaName(areaId: string, updateAreaNameRequest?: UpdateAreaNameRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAreaName(areaId, updateAreaNameRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.updateAreaName']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * エリアの並び順を更新する 
         * @summary エリアの並び順を更新
         * @param {UpdateAreaSortOrderRequest} [updateAreaSortOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAreaSortOrder(updateAreaSortOrderRequest?: UpdateAreaSortOrderRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAreaSortOrder(updateAreaSortOrderRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.updateAreaSortOrder']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 会社情報を更新する
         * @summary Company更新
         * @param {UpdateCompanyRequest} [updateCompanyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompany(updateCompanyRequest?: UpdateCompanyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateCompany200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompany(updateCompanyRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.updateCompany']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * お気に入りセットを更新する。 
         * @summary お気に入りセット更新
         * @param {number} favoriteFurnitureSetId 
         * @param {CreateFavoriteFurnitureSetRequest} [createFavoriteFurnitureSetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFavoriteFurnitureSet(favoriteFurnitureSetId: number, createFavoriteFurnitureSetRequest?: CreateFavoriteFurnitureSetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFavoriteFurnitureSet(favoriteFurnitureSetId, createFavoriteFurnitureSetRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.updateFavoriteFurnitureSet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * エリアの家具を変更する。
         * @summary 家具更新
         * @param {string} areaId 
         * @param {number} areaFurnitureId 
         * @param {UpdateFurnitureRequest} [updateFurnitureRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFurniture(areaId: string, areaFurnitureId: number, updateFurnitureRequest?: UpdateFurnitureRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFurniture(areaId, areaFurnitureId, updateFurnitureRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.updateFurniture']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 案件の更新
         * @summary 案件更新
         * @param {string} projectId 案件id
         * @param {UpdateProjectRequest} updateProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProject(projectId: string, updateProjectRequest: UpdateProjectRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateProject200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProject(projectId, updateProjectRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.updateProject']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 見積詳細の基本情報を更新する。 
         * @summary 見積詳細基本情報更新
         * @param {string} quotationId 
         * @param {UpdateQuotationDetailsRequest} updateQuotationDetailsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateQuotationDetails(quotationId: string, updateQuotationDetailsRequest: UpdateQuotationDetailsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateQuotationDetails(quotationId, updateQuotationDetailsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.updateQuotationDetails']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 見積名、図面を更新する。 
         * @summary 見積名・図面更新
         * @param {string} quotationId 
         * @param {UpdateQuotationPdfRequest} updateQuotationPdfRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateQuotationPdf(quotationId: string, updateQuotationPdfRequest: UpdateQuotationPdfRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FloorQuotationModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateQuotationPdf(quotationId, updateQuotationPdfRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.updateQuotationPdf']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * ログインしている管理者の情報を取得する。
         * @summary 管理者情報取得
         * @param {AdminApiAuthLoginPostRequest} [adminApiAuthLoginPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiAuthAdminGet(adminApiAuthLoginPostRequest?: AdminApiAuthLoginPostRequest, options?: any): AxiosPromise<LoginAdmin> {
            return localVarFp.adminApiAuthAdminGet(adminApiAuthLoginPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 管理者のメールアドレスとパスワードを使用してログインする。
         * @summary 管理者ログイン
         * @param {AdminApiAuthLoginPostRequest} [adminApiAuthLoginPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiAuthLoginPost(adminApiAuthLoginPostRequest?: AdminApiAuthLoginPostRequest, options?: any): AxiosPromise<LoginAdmin> {
            return localVarFp.adminApiAuthLoginPost(adminApiAuthLoginPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * エリア家具を複製する。 
         * @summary エリア家具複製
         * @param {number} areaFurnitureId エリア家具ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAreaFurnitureAreaFurnitureIdDuplicatePost(areaFurnitureId: number, options?: any): AxiosPromise<ApiAreaFurnitureAreaFurnitureIdDuplicatePost201Response> {
            return localVarFp.apiAreaFurnitureAreaFurnitureIdDuplicatePost(areaFurnitureId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary カテゴリー検索
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoriesGet(options?: any): AxiosPromise<ApiCategoriesGet200Response> {
            return localVarFp.apiCategoriesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary カスタム家具作成
         * @param {ApiCustomFurnitureCreatePostRequest} apiCustomFurnitureCreatePostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomFurnitureCreatePost(apiCustomFurnitureCreatePostRequest: ApiCustomFurnitureCreatePostRequest, options?: any): AxiosPromise<ApiCustomFurnitureCreatePost200Response> {
            return localVarFp.apiCustomFurnitureCreatePost(apiCustomFurnitureCreatePostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * カスタム家具を複製する。 
         * @summary カスタム家具複製
         * @param {number} customFurnitureId カスタム家具ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomFurnitureCustomFurnitureIdDuplicatePost(customFurnitureId: number, options?: any): AxiosPromise<ApiCustomFurnitureCustomFurnitureIdDuplicatePost201Response> {
            return localVarFp.apiCustomFurnitureCustomFurnitureIdDuplicatePost(customFurnitureId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary カスタム家具更新
         * @param {ApiCustomFurnitureCreatePostRequestCustomFurnituresInner} apiCustomFurnitureCreatePostRequestCustomFurnituresInner 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomFurnitureUpdatePatch(apiCustomFurnitureCreatePostRequestCustomFurnituresInner: ApiCustomFurnitureCreatePostRequestCustomFurnituresInner, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomFurnitureUpdatePatch(apiCustomFurnitureCreatePostRequestCustomFurnituresInner, options).then((request) => request(axios, basePath));
        },
        /**
         * カスタム家具のメイン画像を削除する。 
         * @summary カスタム家具メイン画像削除
         * @param {number} customFurnitureId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomFurnituresCustomFurnitureIdMainImageDelete(customFurnitureId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomFurnituresCustomFurnitureIdMainImageDelete(customFurnitureId, options).then((request) => request(axios, basePath));
        },
        /**
         * カスタム家具のメイン画像を変更する。 
         * @summary カスタム家具メイン画像更新
         * @param {number} customFurnitureId 
         * @param {File} file アップロードするファイル。拡張子の制限あり。  アップロード可能な拡張子（大文字小文字の区別なし） - jpg/jpeg - png - gif - webp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomFurnituresCustomFurnitureIdMainImagePost(customFurnitureId: number, file: File, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomFurnituresCustomFurnitureIdMainImagePost(customFurnitureId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * カスタム家具のオプション画像を削除する。 
         * @summary カスタム家具オプション画像削除
         * @param {number} customFurnitureId 
         * @param {ApiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexDeleteOptionIndexEnum} optionIndex オプション画像の位置インデックス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexDelete(customFurnitureId: number, optionIndex: ApiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexDeleteOptionIndexEnum, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexDelete(customFurnitureId, optionIndex, options).then((request) => request(axios, basePath));
        },
        /**
         * カスタム家具のオプション画像を変更する。 
         * @summary カスタム家具オプション画像更新
         * @param {number} customFurnitureId 
         * @param {ApiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexPostOptionIndexEnum} optionIndex オプション画像の位置インデックス
         * @param {File} file アップロードするファイル。拡張子の制限あり。  アップロード可能な拡張子（大文字小文字の区別なし） - jpg/jpeg - png - gif - webp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexPost(customFurnitureId: number, optionIndex: ApiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexPostOptionIndexEnum, file: File, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexPost(customFurnitureId, optionIndex, file, options).then((request) => request(axios, basePath));
        },
        /**
         * お気に入りのリストを取得する。 
         * @summary お気に入り一覧取得
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        apiFavoriteGet(options?: any): AxiosPromise<ApiFavoriteGet200Response> {
            return localVarFp.apiFavoriteGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 選択した家具をお気に入りに追加する。 
         * @summary お気に入り追加
         * @param {ApiFavoritePostRequest} [apiFavoritePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFavoritePost(apiFavoritePostRequest?: ApiFavoritePostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiFavoritePost(apiFavoritePostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * エリア家具を削除する。
         * @summary エリア家具削除
         * @param {number} areaFurnitureId エリア家具ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFurnitureAreaFurnitureIdDelete(areaFurnitureId: number, options?: any): AxiosPromise<BaseResponse> {
            return localVarFp.apiFurnitureAreaFurnitureIdDelete(areaFurnitureId, options).then((request) => request(axios, basePath));
        },
        /**
         * エリア家具のメイン画像を削除する。 
         * @summary エリア家具メイン画像削除
         * @param {string} areaId 
         * @param {number} areaFurnitureId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFurnitureAreaIdUpdateAreaFurnitureIdMainImageDelete(areaId: string, areaFurnitureId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiFurnitureAreaIdUpdateAreaFurnitureIdMainImageDelete(areaId, areaFurnitureId, options).then((request) => request(axios, basePath));
        },
        /**
         * エリア家具のメイン画像を変更する。 
         * @summary エリア家具メイン画像更新
         * @param {string} areaId 
         * @param {number} areaFurnitureId 
         * @param {File} file アップロードするファイル。拡張子の制限あり。  アップロード可能な拡張子（大文字小文字の区別なし） - jpg/jpeg - png - gif - webp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFurnitureAreaIdUpdateAreaFurnitureIdMainImagePost(areaId: string, areaFurnitureId: number, file: File, options?: any): AxiosPromise<void> {
            return localVarFp.apiFurnitureAreaIdUpdateAreaFurnitureIdMainImagePost(areaId, areaFurnitureId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * エリア家具のオプション画像を削除する。 
         * @summary エリア家具オプション画像削除
         * @param {string} areaId 
         * @param {number} areaFurnitureId 
         * @param {ApiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexDeleteOptionIndexEnum} optionIndex オプション画像の位置インデックス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexDelete(areaId: string, areaFurnitureId: number, optionIndex: ApiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexDeleteOptionIndexEnum, options?: any): AxiosPromise<void> {
            return localVarFp.apiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexDelete(areaId, areaFurnitureId, optionIndex, options).then((request) => request(axios, basePath));
        },
        /**
         * エリア家具のオプション画像を変更する。 
         * @summary エリア家具オプション画像更新
         * @param {string} areaId 
         * @param {number} areaFurnitureId 
         * @param {ApiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexPostOptionIndexEnum} optionIndex オプション画像の位置インデックス
         * @param {File} file アップロードするファイル。拡張子の制限あり。  アップロード可能な拡張子（大文字小文字の区別なし） - jpg/jpeg - png - gif - webp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexPost(areaId: string, areaFurnitureId: number, optionIndex: ApiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexPostOptionIndexEnum, file: File, options?: any): AxiosPromise<void> {
            return localVarFp.apiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexPost(areaId, areaFurnitureId, optionIndex, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary エリア家具更新
         * @param {string} areaId 
         * @param {number} areaFurnitureId 
         * @param {ApiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequest} apiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFurnitureAreaIdUpdateAreaFurnitureIdPatch(areaId: string, areaFurnitureId: number, apiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequest: ApiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequest, options?: any): AxiosPromise<ApiFurnitureAreaIdUpdateAreaFurnitureIdPatch200Response> {
            return localVarFp.apiFurnitureAreaIdUpdateAreaFurnitureIdPatch(areaId, areaFurnitureId, apiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した見積のエリアと家具を取得する。 
         * @summary エリア家具取得
         * @param {ApiFurnitureAreaPostRequest} [apiFurnitureAreaPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFurnitureAreaPost(apiFurnitureAreaPostRequest?: ApiFurnitureAreaPostRequest, options?: any): AxiosPromise<ApiFurnitureAreaPost200Response> {
            return localVarFp.apiFurnitureAreaPost(apiFurnitureAreaPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 品番検索の結果を取得する。 
         * @summary 品番検索
         * @param {ApiFurniturePartNumberSearchPostRequest} [apiFurniturePartNumberSearchPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFurniturePartNumberSearchPost(apiFurniturePartNumberSearchPostRequest?: ApiFurniturePartNumberSearchPostRequest, options?: any): AxiosPromise<ApiFurniturePartNumberSearchPost200Response> {
            return localVarFp.apiFurniturePartNumberSearchPost(apiFurniturePartNumberSearchPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 絞り込み検索の結果を取得する。 
         * @summary 絞り込み検索
         * @param {ApiFurnitureSearchFurnituresPostRequest} [apiFurnitureSearchFurnituresPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFurnitureSearchFurnituresPost(apiFurnitureSearchFurnituresPostRequest?: ApiFurnitureSearchFurnituresPostRequest, options?: any): AxiosPromise<ApiFurnitureSearchFurnituresPost200Response> {
            return localVarFp.apiFurnitureSearchFurnituresPost(apiFurnitureSearchFurnituresPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary シリーズ取得
         * @param {number} furnitureSeriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFurnitureSeriesFurnitureSeriesIdGet(furnitureSeriesId: number, options?: any): AxiosPromise<ApiFurnitureSeriesFurnitureSeriesIdGet200Response> {
            return localVarFp.apiFurnitureSeriesFurnitureSeriesIdGet(furnitureSeriesId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 関連シリーズ取得
         * @param {number} furnitureSeriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFurnitureSeriesFurnitureSeriesIdRelatedFurnituresGet(furnitureSeriesId: number, options?: any): AxiosPromise<ApiFurnitureSeriesFurnitureSeriesIdRelatedFurnituresGet200Response> {
            return localVarFp.apiFurnitureSeriesFurnitureSeriesIdRelatedFurnituresGet(furnitureSeriesId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 経費一括取得
         * @param {string} floorQuotationId 見積ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuotationAllExpensesGet(floorQuotationId: string, options?: any): AxiosPromise<ApiQuotationAllExpensesGet200Response> {
            return localVarFp.apiQuotationAllExpensesGet(floorQuotationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 経費更新
         * @param {number} quotationExpensesId QuotationExpense.id
         * @param {ApiQuotationExpensesQuotationExpensesIdPatchRequest} [apiQuotationExpensesQuotationExpensesIdPatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuotationExpensesQuotationExpensesIdPatch(quotationExpensesId: number, apiQuotationExpensesQuotationExpensesIdPatchRequest?: ApiQuotationExpensesQuotationExpensesIdPatchRequest, options?: any): AxiosPromise<ApiQuotationExpensesQuotationExpensesIdPatch200Response> {
            return localVarFp.apiQuotationExpensesQuotationExpensesIdPatch(quotationExpensesId, apiQuotationExpensesQuotationExpensesIdPatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary メーカー掛け率設定取得
         * @param {string} quotationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuotationQuotationIdManufactureBettingRatesGet(quotationId: string, options?: any): AxiosPromise<ApiQuotationQuotationIdManufactureBettingRatesGet200Response> {
            return localVarFp.apiQuotationQuotationIdManufactureBettingRatesGet(quotationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 見積設定更新
         * @param {string} quotationId 
         * @param {ApiQuotationQuotationIdUpdatePatchRequest} apiQuotationQuotationIdUpdatePatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuotationQuotationIdUpdatePatch(quotationId: string, apiQuotationQuotationIdUpdatePatchRequest: ApiQuotationQuotationIdUpdatePatchRequest, options?: any): AxiosPromise<object> {
            return localVarFp.apiQuotationQuotationIdUpdatePatch(quotationId, apiQuotationQuotationIdUpdatePatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザーが所属するOrganizationに画像アップロードを行う アップロード先は `<bucket>/{organizations}/{organization_id}/{directory}/{ファイルのハッシュ値}.{extension}` とする  NOTE:   - `organizations`は環境に応じて変わる(本番: `organizations`, 開発: `organizations-dev`)   - `organization_id`はユーザーが所属するOrganizationのID 
         * @summary Organization画像アップロード
         * @param {File} imageFile 画像ファイル。形式は&#x60;jpg&#x60;, &#x60;jpeg&#x60;, &#x60;png&#x60;, &#x60;gif&#x60;, &#x60;webp&#x60;のいずれか  最大サイズは&#x60;2MB&#x60; 
         * @param {string | null} [directory] アップロード先サブディレクトリ - &#x60;..&#x60; を含む場合は422エラーとなる 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUploadFilePost(imageFile: File, directory?: string | null, options?: any): AxiosPromise<ApiUploadFilePost200Response> {
            return localVarFp.apiUploadFilePost(imageFile, directory, options).then((request) => request(axios, basePath));
        },
        /**
         * お気に入りへお気に入りセットを紐付ける。 
         * @summary お気に入りセット紐付
         * @param {number} userFavoriteFurnitureId 
         * @param {AttachFavoriteFurnitureSetRequest} [attachFavoriteFurnitureSetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachFavoriteFurnitureSet(userFavoriteFurnitureId: number, attachFavoriteFurnitureSetRequest?: AttachFavoriteFurnitureSetRequest, options?: any): AxiosPromise<void> {
            return localVarFp.attachFavoriteFurnitureSet(userFavoriteFurnitureId, attachFavoriteFurnitureSetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * エリアを作成する 
         * @summary エリア作成
         * @param {CreateAreaRequest} [createAreaRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createArea(createAreaRequest?: CreateAreaRequest, options?: any): AxiosPromise<BaseResponse> {
            return localVarFp.createArea(createAreaRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * お気に入りセットを追加する。 
         * @summary お気に入りセット追加
         * @param {CreateFavoriteFurnitureSetRequest} [createFavoriteFurnitureSetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFavoriteFurnitureSet(createFavoriteFurnitureSetRequest?: CreateFavoriteFurnitureSetRequest, options?: any): AxiosPromise<CreateFavoriteFurnitureSet201Response> {
            return localVarFp.createFavoriteFurnitureSet(createFavoriteFurnitureSetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * エリアを削除する 
         * @summary エリア削除
         * @param {string} areaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteArea(areaId: string, options?: any): AxiosPromise<BaseResponse> {
            return localVarFp.deleteArea(areaId, options).then((request) => request(axios, basePath));
        },
        /**
         * お気に入りセットを削除する。 
         * @summary お気に入りセット削除
         * @param {number} favoriteFurnitureSetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFavoriteFurnitureSet(favoriteFurnitureSetId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFavoriteFurnitureSet(favoriteFurnitureSetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 家具削除
         * @param {DeleteFurnituresRequest} [deleteFurnituresRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFurnitures(deleteFurnituresRequest?: DeleteFurnituresRequest, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFurnitures(deleteFurnituresRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 案件の削除
         * @summary 案件削除
         * @param {string} projectId 案件id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject(projectId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProject(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 見積削除
         * @param {string} quotationId 
         * @param {DeleteQuotationRequest} deleteQuotationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQuotation(quotationId: string, deleteQuotationRequest: DeleteQuotationRequest, options?: any): AxiosPromise<DeleteQuotation200Response> {
            return localVarFp.deleteQuotation(quotationId, deleteQuotationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * お気に入りセットからお気に入りの紐付けを解除する。 
         * @summary お気に入りのセット紐付解除
         * @param {number} favoriteFurnitureSetId 
         * @param {number} userFavoriteFurnitureId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detachFavoriteFurnitureSet(favoriteFurnitureSetId: number, userFavoriteFurnitureId: number, options?: any): AxiosPromise<void> {
            return localVarFp.detachFavoriteFurnitureSet(favoriteFurnitureSetId, userFavoriteFurnitureId, options).then((request) => request(axios, basePath));
        },
        /**
         * IWOS向けCSVダウンロード 
         * @summary IWOS向けCSVダウンロード
         * @param {string} floorQuotationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadIwosCsv(floorQuotationId: string, options?: any): AxiosPromise<File> {
            return localVarFp.downloadIwosCsv(floorQuotationId, options).then((request) => request(axios, basePath));
        },
        /**
         * NAIKI社向け見積もりXMLをダウンロードする 
         * @summary NAIKI社見積もりXMLダウンロード
         * @param {string} floorQuotationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadNaikiQuotationXml(floorQuotationId: string, options?: any): AxiosPromise<File> {
            return localVarFp.downloadNaikiQuotationXml(floorQuotationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 図面ファイルをアップロードし、展開したイメージ情報を返す
         * @summary 図面アップロード
         * @param {File} pdfFile 図面ファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extractPlanImage(pdfFile: File, options?: any): AxiosPromise<ExtractPlanImage200Response> {
            return localVarFp.extractPlanImage(pdfFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 見積IDからエリアを取得する 
         * @summary 見積IDからエリア取得
         * @param {string} quotationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAreaByQuotation(quotationId: string, options?: any): AxiosPromise<GetAreaByQuotation200Response> {
            return localVarFp.getAreaByQuotation(quotationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 従量課金の使用状況を取得する。 
         * @summary 従量課金の使用状況取得
         * @param {string} yearMonth 従量課金の使用状況を取得する年月
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBillingUsage(yearMonth: string, options?: any): AxiosPromise<GetBillingUsage200Response> {
            return localVarFp.getBillingUsage(yearMonth, options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザーに紐づくCompanyを取得する
         * @summary Company取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompany(options?: any): AxiosPromise<GetCompany200Response> {
            return localVarFp.getCompany(options).then((request) => request(axios, basePath));
        },
        /**
         * 経費セット一覧を取得する。
         * @summary 経費セット一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExpenseSets(options?: any): AxiosPromise<GetExpenseSets200Response> {
            return localVarFp.getExpenseSets(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 見積家具リスト一括ダウンロード
         * @param {string} floorQuotationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExportRequestQuotationExcelFloorQuotation(floorQuotationId: string, options?: any): AxiosPromise<File> {
            return localVarFp.getExportRequestQuotationExcelFloorQuotation(floorQuotationId, options).then((request) => request(axios, basePath));
        },
        /**
         * お気に入りセットを取得する。 
         * @summary お気に入りセット取得
         * @param {number} favoriteFurnitureSetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavoriteFurnitureSet(favoriteFurnitureSetId: number, options?: any): AxiosPromise<CreateFavoriteFurnitureSet201Response> {
            return localVarFp.getFavoriteFurnitureSet(favoriteFurnitureSetId, options).then((request) => request(axios, basePath));
        },
        /**
         * お気に入りセット一覧を取得する。 
         * @summary お気に入りセット一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavoriteFurnitureSets(options?: any): AxiosPromise<GetFavoriteFurnitureSets200Response> {
            return localVarFp.getFavoriteFurnitureSets(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 家具のタグ取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFurnitureTags(options?: any): AxiosPromise<GetFurnitureTags200Response> {
            return localVarFp.getFurnitureTags(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 切り貼りテンプレート取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKiribariTemplates(options?: any): AxiosPromise<GetKiribariTemplates200Response> {
            return localVarFp.getKiribariTemplates(options).then((request) => request(axios, basePath));
        },
        /**
         * メーカー一覧を取得する
         * @summary メーカー一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManufactures(options?: any): AxiosPromise<GetManufactures200Response> {
            return localVarFp.getManufactures(options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザーの組織に紐づくメーカーを取得する
         * @summary 組織に紐づくメーカー一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationManufactures(options?: any): AxiosPromise<GetOrganizationManufactures200Response> {
            return localVarFp.getOrganizationManufactures(options).then((request) => request(axios, basePath));
        },
        /**
         * 案件の取得
         * @summary 案件取得
         * @param {string} projectId 案件id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject(projectId: string, options?: any): AxiosPromise<GetProject200Response> {
            return localVarFp.getProject(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * プロジェクトに紐づくCompanyを取得する
         * @summary プロジェクトのCompany取得
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectCompany(projectId: string, options?: any): AxiosPromise<GetCompany200Response> {
            return localVarFp.getProjectCompany(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * ログインユーザーの案件一覧を取得する
         * @summary 案件一覧取得
         * @param {string} [managerName] 担当者名で絞り込み
         * @param {string} [search] 案件名で絞り込み
         * @param {number} [page] ページ番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects(managerName?: string, search?: string, page?: number, options?: any): AxiosPromise<GetProjects200Response> {
            return localVarFp.getProjects(managerName, search, page, options).then((request) => request(axios, basePath));
        },
        /**
         * ログインしているユーザの情報を取得する。
         * @summary ユーザ情報取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(options?: any): AxiosPromise<GetUser200Response> {
            return localVarFp.getUser(options).then((request) => request(axios, basePath));
        },
        /**
         * メールアドレスとパスワードを使用してログインする。
         * @summary ログイン
         * @param {LoginRequest} [loginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginRequest?: LoginRequest, options?: any): AxiosPromise<LoginUser> {
            return localVarFp.login(loginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Firebase を使用して外部サービスのアカウントで Swish にログインする。
         * @summary 外部サービスログイン
         * @param {LoginWithFirebaseRequest} [loginWithFirebaseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginWithFirebase(loginWithFirebaseRequest?: LoginWithFirebaseRequest, options?: any): AxiosPromise<LoginUser> {
            return localVarFp.loginWithFirebase(loginWithFirebaseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 家具一括追加/更新
         * @param {PutFurnituresRequest} [putFurnituresRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFurnitures(putFurnituresRequest?: PutFurnituresRequest, options?: any): AxiosPromise<Array<PutFurnitures200ResponseInner>> {
            return localVarFp.putFurnitures(putFurnituresRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * お気に入りセット内のお気に入りの並び順を更新する。 
         * @summary お気に入りセット内のお気に入り並び順更新
         * @param {number} favoriteFurnitureSetId 
         * @param {ReorderFavoriteFurnitureInSetRequest} [reorderFavoriteFurnitureInSetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reorderFavoriteFurnitureInSet(favoriteFurnitureSetId: number, reorderFavoriteFurnitureInSetRequest?: ReorderFavoriteFurnitureInSetRequest, options?: any): AxiosPromise<void> {
            return localVarFp.reorderFavoriteFurnitureInSet(favoriteFurnitureSetId, reorderFavoriteFurnitureInSetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * お気に入りセットの並び順を更新する。 
         * @summary お気に入りセット並び順更新
         * @param {AttachFavoriteFurnitureSetRequest} [attachFavoriteFurnitureSetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reorderFavoriteFurnitureSets(attachFavoriteFurnitureSetRequest?: AttachFavoriteFurnitureSetRequest, options?: any): AxiosPromise<void> {
            return localVarFp.reorderFavoriteFurnitureSets(attachFavoriteFurnitureSetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定されたパスワードでリセットする。 
         * @summary パスワード更新
         * @param {ResetPasswordRequest} [resetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(resetPasswordRequest?: ResetPasswordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.resetPassword(resetPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * エリアのスタンプを保存する 
         * @summary エリアのスタンプ保存
         * @param {string} areaId 
         * @param {SaveAreaStampsRequest} [saveAreaStampsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAreaStamps(areaId: string, saveAreaStampsRequest?: SaveAreaStampsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.saveAreaStamps(areaId, saveAreaStampsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 経費セットを追加・更新する。
         * @summary 経費セットの保存
         * @param {SaveExpenseSetRequest} [saveExpenseSetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveExpenseSet(saveExpenseSetRequest?: SaveExpenseSetRequest, options?: any): AxiosPromise<SaveExpenseSet200Response> {
            return localVarFp.saveExpenseSet(saveExpenseSetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 家具を指定のエリアに追加する。
         * @summary 家具追加
         * @param {SaveFurnitureRequest} [saveFurnitureRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveFurniture(saveFurnitureRequest?: SaveFurnitureRequest, options?: any): AxiosPromise<AreaFurniture> {
            return localVarFp.saveFurniture(saveFurnitureRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 複数の家具を指定のエリアに一括で追加する。  NOTE: メニーオプションを持つ家具は現時点では未対応なのでバリデーションエラーとなる <a href=\"https://linear.app/swish-inc/issue/SW-24#comment-b67e4820\">関連コメント</a> 
         * @summary 家具一括追加
         * @param {SaveFurnituresRequest} [saveFurnituresRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveFurnitures(saveFurnituresRequest?: SaveFurnituresRequest, options?: any): AxiosPromise<void> {
            return localVarFp.saveFurnitures(saveFurnituresRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 家具一括品番検索
         * @param {SearchFurnituresByPartsNumbersRequest} [searchFurnituresByPartsNumbersRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchFurnituresByPartsNumbers(searchFurnituresByPartsNumbersRequest?: SearchFurnituresByPartsNumbersRequest, options?: any): AxiosPromise<SearchFurnituresByPartsNumbers200Response> {
            return localVarFp.searchFurnituresByPartsNumbers(searchFurnituresByPartsNumbersRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary パスワードリセットリンクの送信
         * @param {SendPasswordResetLinkRequest} [sendPasswordResetLinkRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPasswordResetLink(sendPasswordResetLinkRequest?: SendPasswordResetLinkRequest, options?: any): AxiosPromise<void> {
            return localVarFp.sendPasswordResetLink(sendPasswordResetLinkRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 経費一括追加/更新/削除
         * @param {StoreQuotationExpensesRequest} [storeQuotationExpensesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeQuotationExpenses(storeQuotationExpensesRequest?: StoreQuotationExpensesRequest, options?: any): AxiosPromise<StoreQuotationExpenses200Response> {
            return localVarFp.storeQuotationExpenses(storeQuotationExpensesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * エリアを更新する 
         * @summary エリア更新
         * @param {string} areaId 
         * @param {UpdateAreaRequest} [updateAreaRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArea(areaId: string, updateAreaRequest?: UpdateAreaRequest, options?: any): AxiosPromise<BaseResponse> {
            return localVarFp.updateArea(areaId, updateAreaRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * エリア家具の備考欄メモを更新する。 
         * @summary エリア家具備考欄メモ更新
         * @param {number} areaFurnitureId エリア家具ID
         * @param {UpdateAreaFurnitureNotesRequest} [updateAreaFurnitureNotesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAreaFurnitureNotes(areaFurnitureId: number, updateAreaFurnitureNotesRequest?: UpdateAreaFurnitureNotesRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateAreaFurnitureNotes(areaFurnitureId, updateAreaFurnitureNotesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * エリアの名称を更新する 
         * @summary エリア名更新
         * @param {string} areaId 
         * @param {UpdateAreaNameRequest} [updateAreaNameRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAreaName(areaId: string, updateAreaNameRequest?: UpdateAreaNameRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateAreaName(areaId, updateAreaNameRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * エリアの並び順を更新する 
         * @summary エリアの並び順を更新
         * @param {UpdateAreaSortOrderRequest} [updateAreaSortOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAreaSortOrder(updateAreaSortOrderRequest?: UpdateAreaSortOrderRequest, options?: any): AxiosPromise<BaseResponse> {
            return localVarFp.updateAreaSortOrder(updateAreaSortOrderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 会社情報を更新する
         * @summary Company更新
         * @param {UpdateCompanyRequest} [updateCompanyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompany(updateCompanyRequest?: UpdateCompanyRequest, options?: any): AxiosPromise<UpdateCompany200Response> {
            return localVarFp.updateCompany(updateCompanyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * お気に入りセットを更新する。 
         * @summary お気に入りセット更新
         * @param {number} favoriteFurnitureSetId 
         * @param {CreateFavoriteFurnitureSetRequest} [createFavoriteFurnitureSetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFavoriteFurnitureSet(favoriteFurnitureSetId: number, createFavoriteFurnitureSetRequest?: CreateFavoriteFurnitureSetRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateFavoriteFurnitureSet(favoriteFurnitureSetId, createFavoriteFurnitureSetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * エリアの家具を変更する。
         * @summary 家具更新
         * @param {string} areaId 
         * @param {number} areaFurnitureId 
         * @param {UpdateFurnitureRequest} [updateFurnitureRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFurniture(areaId: string, areaFurnitureId: number, updateFurnitureRequest?: UpdateFurnitureRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateFurniture(areaId, areaFurnitureId, updateFurnitureRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 案件の更新
         * @summary 案件更新
         * @param {string} projectId 案件id
         * @param {UpdateProjectRequest} updateProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject(projectId: string, updateProjectRequest: UpdateProjectRequest, options?: any): AxiosPromise<UpdateProject200Response> {
            return localVarFp.updateProject(projectId, updateProjectRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 見積詳細の基本情報を更新する。 
         * @summary 見積詳細基本情報更新
         * @param {string} quotationId 
         * @param {UpdateQuotationDetailsRequest} updateQuotationDetailsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQuotationDetails(quotationId: string, updateQuotationDetailsRequest: UpdateQuotationDetailsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateQuotationDetails(quotationId, updateQuotationDetailsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 見積名、図面を更新する。 
         * @summary 見積名・図面更新
         * @param {string} quotationId 
         * @param {UpdateQuotationPdfRequest} updateQuotationPdfRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQuotationPdf(quotationId: string, updateQuotationPdfRequest: UpdateQuotationPdfRequest, options?: any): AxiosPromise<FloorQuotationModel> {
            return localVarFp.updateQuotationPdf(quotationId, updateQuotationPdfRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * ログインしている管理者の情報を取得する。
     * @summary 管理者情報取得
     * @param {AdminApiAuthLoginPostRequest} [adminApiAuthLoginPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminApiAuthAdminGet(adminApiAuthLoginPostRequest?: AdminApiAuthLoginPostRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminApiAuthAdminGet(adminApiAuthLoginPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 管理者のメールアドレスとパスワードを使用してログインする。
     * @summary 管理者ログイン
     * @param {AdminApiAuthLoginPostRequest} [adminApiAuthLoginPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminApiAuthLoginPost(adminApiAuthLoginPostRequest?: AdminApiAuthLoginPostRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminApiAuthLoginPost(adminApiAuthLoginPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * エリア家具を複製する。 
     * @summary エリア家具複製
     * @param {number} areaFurnitureId エリア家具ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiAreaFurnitureAreaFurnitureIdDuplicatePost(areaFurnitureId: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiAreaFurnitureAreaFurnitureIdDuplicatePost(areaFurnitureId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary カテゴリー検索
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiCategoriesGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiCategoriesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary カスタム家具作成
     * @param {ApiCustomFurnitureCreatePostRequest} apiCustomFurnitureCreatePostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiCustomFurnitureCreatePost(apiCustomFurnitureCreatePostRequest: ApiCustomFurnitureCreatePostRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiCustomFurnitureCreatePost(apiCustomFurnitureCreatePostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * カスタム家具を複製する。 
     * @summary カスタム家具複製
     * @param {number} customFurnitureId カスタム家具ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiCustomFurnitureCustomFurnitureIdDuplicatePost(customFurnitureId: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiCustomFurnitureCustomFurnitureIdDuplicatePost(customFurnitureId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary カスタム家具更新
     * @param {ApiCustomFurnitureCreatePostRequestCustomFurnituresInner} apiCustomFurnitureCreatePostRequestCustomFurnituresInner 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiCustomFurnitureUpdatePatch(apiCustomFurnitureCreatePostRequestCustomFurnituresInner: ApiCustomFurnitureCreatePostRequestCustomFurnituresInner, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiCustomFurnitureUpdatePatch(apiCustomFurnitureCreatePostRequestCustomFurnituresInner, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * カスタム家具のメイン画像を削除する。 
     * @summary カスタム家具メイン画像削除
     * @param {number} customFurnitureId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiCustomFurnituresCustomFurnitureIdMainImageDelete(customFurnitureId: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiCustomFurnituresCustomFurnitureIdMainImageDelete(customFurnitureId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * カスタム家具のメイン画像を変更する。 
     * @summary カスタム家具メイン画像更新
     * @param {number} customFurnitureId 
     * @param {File} file アップロードするファイル。拡張子の制限あり。  アップロード可能な拡張子（大文字小文字の区別なし） - jpg/jpeg - png - gif - webp 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiCustomFurnituresCustomFurnitureIdMainImagePost(customFurnitureId: number, file: File, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiCustomFurnituresCustomFurnitureIdMainImagePost(customFurnitureId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * カスタム家具のオプション画像を削除する。 
     * @summary カスタム家具オプション画像削除
     * @param {number} customFurnitureId 
     * @param {ApiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexDeleteOptionIndexEnum} optionIndex オプション画像の位置インデックス
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexDelete(customFurnitureId: number, optionIndex: ApiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexDeleteOptionIndexEnum, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexDelete(customFurnitureId, optionIndex, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * カスタム家具のオプション画像を変更する。 
     * @summary カスタム家具オプション画像更新
     * @param {number} customFurnitureId 
     * @param {ApiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexPostOptionIndexEnum} optionIndex オプション画像の位置インデックス
     * @param {File} file アップロードするファイル。拡張子の制限あり。  アップロード可能な拡張子（大文字小文字の区別なし） - jpg/jpeg - png - gif - webp 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexPost(customFurnitureId: number, optionIndex: ApiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexPostOptionIndexEnum, file: File, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexPost(customFurnitureId, optionIndex, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * お気に入りのリストを取得する。 
     * @summary お気に入り一覧取得
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiFavoriteGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiFavoriteGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 選択した家具をお気に入りに追加する。 
     * @summary お気に入り追加
     * @param {ApiFavoritePostRequest} [apiFavoritePostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiFavoritePost(apiFavoritePostRequest?: ApiFavoritePostRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiFavoritePost(apiFavoritePostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * エリア家具を削除する。
     * @summary エリア家具削除
     * @param {number} areaFurnitureId エリア家具ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiFurnitureAreaFurnitureIdDelete(areaFurnitureId: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiFurnitureAreaFurnitureIdDelete(areaFurnitureId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * エリア家具のメイン画像を削除する。 
     * @summary エリア家具メイン画像削除
     * @param {string} areaId 
     * @param {number} areaFurnitureId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiFurnitureAreaIdUpdateAreaFurnitureIdMainImageDelete(areaId: string, areaFurnitureId: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiFurnitureAreaIdUpdateAreaFurnitureIdMainImageDelete(areaId, areaFurnitureId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * エリア家具のメイン画像を変更する。 
     * @summary エリア家具メイン画像更新
     * @param {string} areaId 
     * @param {number} areaFurnitureId 
     * @param {File} file アップロードするファイル。拡張子の制限あり。  アップロード可能な拡張子（大文字小文字の区別なし） - jpg/jpeg - png - gif - webp 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiFurnitureAreaIdUpdateAreaFurnitureIdMainImagePost(areaId: string, areaFurnitureId: number, file: File, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiFurnitureAreaIdUpdateAreaFurnitureIdMainImagePost(areaId, areaFurnitureId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * エリア家具のオプション画像を削除する。 
     * @summary エリア家具オプション画像削除
     * @param {string} areaId 
     * @param {number} areaFurnitureId 
     * @param {ApiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexDeleteOptionIndexEnum} optionIndex オプション画像の位置インデックス
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexDelete(areaId: string, areaFurnitureId: number, optionIndex: ApiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexDeleteOptionIndexEnum, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexDelete(areaId, areaFurnitureId, optionIndex, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * エリア家具のオプション画像を変更する。 
     * @summary エリア家具オプション画像更新
     * @param {string} areaId 
     * @param {number} areaFurnitureId 
     * @param {ApiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexPostOptionIndexEnum} optionIndex オプション画像の位置インデックス
     * @param {File} file アップロードするファイル。拡張子の制限あり。  アップロード可能な拡張子（大文字小文字の区別なし） - jpg/jpeg - png - gif - webp 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexPost(areaId: string, areaFurnitureId: number, optionIndex: ApiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexPostOptionIndexEnum, file: File, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexPost(areaId, areaFurnitureId, optionIndex, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary エリア家具更新
     * @param {string} areaId 
     * @param {number} areaFurnitureId 
     * @param {ApiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequest} apiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiFurnitureAreaIdUpdateAreaFurnitureIdPatch(areaId: string, areaFurnitureId: number, apiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequest: ApiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiFurnitureAreaIdUpdateAreaFurnitureIdPatch(areaId, areaFurnitureId, apiFurnitureAreaIdUpdateAreaFurnitureIdPatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した見積のエリアと家具を取得する。 
     * @summary エリア家具取得
     * @param {ApiFurnitureAreaPostRequest} [apiFurnitureAreaPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiFurnitureAreaPost(apiFurnitureAreaPostRequest?: ApiFurnitureAreaPostRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiFurnitureAreaPost(apiFurnitureAreaPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 品番検索の結果を取得する。 
     * @summary 品番検索
     * @param {ApiFurniturePartNumberSearchPostRequest} [apiFurniturePartNumberSearchPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiFurniturePartNumberSearchPost(apiFurniturePartNumberSearchPostRequest?: ApiFurniturePartNumberSearchPostRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiFurniturePartNumberSearchPost(apiFurniturePartNumberSearchPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 絞り込み検索の結果を取得する。 
     * @summary 絞り込み検索
     * @param {ApiFurnitureSearchFurnituresPostRequest} [apiFurnitureSearchFurnituresPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiFurnitureSearchFurnituresPost(apiFurnitureSearchFurnituresPostRequest?: ApiFurnitureSearchFurnituresPostRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiFurnitureSearchFurnituresPost(apiFurnitureSearchFurnituresPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary シリーズ取得
     * @param {number} furnitureSeriesId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiFurnitureSeriesFurnitureSeriesIdGet(furnitureSeriesId: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiFurnitureSeriesFurnitureSeriesIdGet(furnitureSeriesId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 関連シリーズ取得
     * @param {number} furnitureSeriesId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiFurnitureSeriesFurnitureSeriesIdRelatedFurnituresGet(furnitureSeriesId: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiFurnitureSeriesFurnitureSeriesIdRelatedFurnituresGet(furnitureSeriesId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 経費一括取得
     * @param {string} floorQuotationId 見積ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiQuotationAllExpensesGet(floorQuotationId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiQuotationAllExpensesGet(floorQuotationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 経費更新
     * @param {number} quotationExpensesId QuotationExpense.id
     * @param {ApiQuotationExpensesQuotationExpensesIdPatchRequest} [apiQuotationExpensesQuotationExpensesIdPatchRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiQuotationExpensesQuotationExpensesIdPatch(quotationExpensesId: number, apiQuotationExpensesQuotationExpensesIdPatchRequest?: ApiQuotationExpensesQuotationExpensesIdPatchRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiQuotationExpensesQuotationExpensesIdPatch(quotationExpensesId, apiQuotationExpensesQuotationExpensesIdPatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary メーカー掛け率設定取得
     * @param {string} quotationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiQuotationQuotationIdManufactureBettingRatesGet(quotationId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiQuotationQuotationIdManufactureBettingRatesGet(quotationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 見積設定更新
     * @param {string} quotationId 
     * @param {ApiQuotationQuotationIdUpdatePatchRequest} apiQuotationQuotationIdUpdatePatchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiQuotationQuotationIdUpdatePatch(quotationId: string, apiQuotationQuotationIdUpdatePatchRequest: ApiQuotationQuotationIdUpdatePatchRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiQuotationQuotationIdUpdatePatch(quotationId, apiQuotationQuotationIdUpdatePatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ユーザーが所属するOrganizationに画像アップロードを行う アップロード先は `<bucket>/{organizations}/{organization_id}/{directory}/{ファイルのハッシュ値}.{extension}` とする  NOTE:   - `organizations`は環境に応じて変わる(本番: `organizations`, 開発: `organizations-dev`)   - `organization_id`はユーザーが所属するOrganizationのID 
     * @summary Organization画像アップロード
     * @param {File} imageFile 画像ファイル。形式は&#x60;jpg&#x60;, &#x60;jpeg&#x60;, &#x60;png&#x60;, &#x60;gif&#x60;, &#x60;webp&#x60;のいずれか  最大サイズは&#x60;2MB&#x60; 
     * @param {string | null} [directory] アップロード先サブディレクトリ - &#x60;..&#x60; を含む場合は422エラーとなる 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiUploadFilePost(imageFile: File, directory?: string | null, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiUploadFilePost(imageFile, directory, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * お気に入りへお気に入りセットを紐付ける。 
     * @summary お気に入りセット紐付
     * @param {number} userFavoriteFurnitureId 
     * @param {AttachFavoriteFurnitureSetRequest} [attachFavoriteFurnitureSetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public attachFavoriteFurnitureSet(userFavoriteFurnitureId: number, attachFavoriteFurnitureSetRequest?: AttachFavoriteFurnitureSetRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).attachFavoriteFurnitureSet(userFavoriteFurnitureId, attachFavoriteFurnitureSetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * エリアを作成する 
     * @summary エリア作成
     * @param {CreateAreaRequest} [createAreaRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createArea(createAreaRequest?: CreateAreaRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createArea(createAreaRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * お気に入りセットを追加する。 
     * @summary お気に入りセット追加
     * @param {CreateFavoriteFurnitureSetRequest} [createFavoriteFurnitureSetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createFavoriteFurnitureSet(createFavoriteFurnitureSetRequest?: CreateFavoriteFurnitureSetRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createFavoriteFurnitureSet(createFavoriteFurnitureSetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * エリアを削除する 
     * @summary エリア削除
     * @param {string} areaId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteArea(areaId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteArea(areaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * お気に入りセットを削除する。 
     * @summary お気に入りセット削除
     * @param {number} favoriteFurnitureSetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteFavoriteFurnitureSet(favoriteFurnitureSetId: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteFavoriteFurnitureSet(favoriteFurnitureSetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 家具削除
     * @param {DeleteFurnituresRequest} [deleteFurnituresRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteFurnitures(deleteFurnituresRequest?: DeleteFurnituresRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteFurnitures(deleteFurnituresRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 案件の削除
     * @summary 案件削除
     * @param {string} projectId 案件id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteProject(projectId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteProject(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 見積削除
     * @param {string} quotationId 
     * @param {DeleteQuotationRequest} deleteQuotationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteQuotation(quotationId: string, deleteQuotationRequest: DeleteQuotationRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteQuotation(quotationId, deleteQuotationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * お気に入りセットからお気に入りの紐付けを解除する。 
     * @summary お気に入りのセット紐付解除
     * @param {number} favoriteFurnitureSetId 
     * @param {number} userFavoriteFurnitureId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public detachFavoriteFurnitureSet(favoriteFurnitureSetId: number, userFavoriteFurnitureId: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).detachFavoriteFurnitureSet(favoriteFurnitureSetId, userFavoriteFurnitureId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * IWOS向けCSVダウンロード 
     * @summary IWOS向けCSVダウンロード
     * @param {string} floorQuotationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public downloadIwosCsv(floorQuotationId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).downloadIwosCsv(floorQuotationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * NAIKI社向け見積もりXMLをダウンロードする 
     * @summary NAIKI社見積もりXMLダウンロード
     * @param {string} floorQuotationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public downloadNaikiQuotationXml(floorQuotationId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).downloadNaikiQuotationXml(floorQuotationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 図面ファイルをアップロードし、展開したイメージ情報を返す
     * @summary 図面アップロード
     * @param {File} pdfFile 図面ファイル
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public extractPlanImage(pdfFile: File, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).extractPlanImage(pdfFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 見積IDからエリアを取得する 
     * @summary 見積IDからエリア取得
     * @param {string} quotationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAreaByQuotation(quotationId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAreaByQuotation(quotationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 従量課金の使用状況を取得する。 
     * @summary 従量課金の使用状況取得
     * @param {string} yearMonth 従量課金の使用状況を取得する年月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getBillingUsage(yearMonth: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getBillingUsage(yearMonth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ユーザーに紐づくCompanyを取得する
     * @summary Company取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCompany(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getCompany(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 経費セット一覧を取得する。
     * @summary 経費セット一覧の取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getExpenseSets(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getExpenseSets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 見積家具リスト一括ダウンロード
     * @param {string} floorQuotationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getExportRequestQuotationExcelFloorQuotation(floorQuotationId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getExportRequestQuotationExcelFloorQuotation(floorQuotationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * お気に入りセットを取得する。 
     * @summary お気に入りセット取得
     * @param {number} favoriteFurnitureSetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getFavoriteFurnitureSet(favoriteFurnitureSetId: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getFavoriteFurnitureSet(favoriteFurnitureSetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * お気に入りセット一覧を取得する。 
     * @summary お気に入りセット一覧取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getFavoriteFurnitureSets(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getFavoriteFurnitureSets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 家具のタグ取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getFurnitureTags(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getFurnitureTags(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 切り貼りテンプレート取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getKiribariTemplates(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getKiribariTemplates(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * メーカー一覧を取得する
     * @summary メーカー一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getManufactures(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getManufactures(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ユーザーの組織に紐づくメーカーを取得する
     * @summary 組織に紐づくメーカー一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getOrganizationManufactures(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getOrganizationManufactures(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 案件の取得
     * @summary 案件取得
     * @param {string} projectId 案件id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProject(projectId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getProject(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * プロジェクトに紐づくCompanyを取得する
     * @summary プロジェクトのCompany取得
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProjectCompany(projectId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getProjectCompany(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ログインユーザーの案件一覧を取得する
     * @summary 案件一覧取得
     * @param {string} [managerName] 担当者名で絞り込み
     * @param {string} [search] 案件名で絞り込み
     * @param {number} [page] ページ番号
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProjects(managerName?: string, search?: string, page?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getProjects(managerName, search, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ログインしているユーザの情報を取得する。
     * @summary ユーザ情報取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUser(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * メールアドレスとパスワードを使用してログインする。
     * @summary ログイン
     * @param {LoginRequest} [loginRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public login(loginRequest?: LoginRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).login(loginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Firebase を使用して外部サービスのアカウントで Swish にログインする。
     * @summary 外部サービスログイン
     * @param {LoginWithFirebaseRequest} [loginWithFirebaseRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public loginWithFirebase(loginWithFirebaseRequest?: LoginWithFirebaseRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).loginWithFirebase(loginWithFirebaseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 家具一括追加/更新
     * @param {PutFurnituresRequest} [putFurnituresRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public putFurnitures(putFurnituresRequest?: PutFurnituresRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).putFurnitures(putFurnituresRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * お気に入りセット内のお気に入りの並び順を更新する。 
     * @summary お気に入りセット内のお気に入り並び順更新
     * @param {number} favoriteFurnitureSetId 
     * @param {ReorderFavoriteFurnitureInSetRequest} [reorderFavoriteFurnitureInSetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reorderFavoriteFurnitureInSet(favoriteFurnitureSetId: number, reorderFavoriteFurnitureInSetRequest?: ReorderFavoriteFurnitureInSetRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).reorderFavoriteFurnitureInSet(favoriteFurnitureSetId, reorderFavoriteFurnitureInSetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * お気に入りセットの並び順を更新する。 
     * @summary お気に入りセット並び順更新
     * @param {AttachFavoriteFurnitureSetRequest} [attachFavoriteFurnitureSetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reorderFavoriteFurnitureSets(attachFavoriteFurnitureSetRequest?: AttachFavoriteFurnitureSetRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).reorderFavoriteFurnitureSets(attachFavoriteFurnitureSetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定されたパスワードでリセットする。 
     * @summary パスワード更新
     * @param {ResetPasswordRequest} [resetPasswordRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public resetPassword(resetPasswordRequest?: ResetPasswordRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).resetPassword(resetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * エリアのスタンプを保存する 
     * @summary エリアのスタンプ保存
     * @param {string} areaId 
     * @param {SaveAreaStampsRequest} [saveAreaStampsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public saveAreaStamps(areaId: string, saveAreaStampsRequest?: SaveAreaStampsRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).saveAreaStamps(areaId, saveAreaStampsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 経費セットを追加・更新する。
     * @summary 経費セットの保存
     * @param {SaveExpenseSetRequest} [saveExpenseSetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public saveExpenseSet(saveExpenseSetRequest?: SaveExpenseSetRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).saveExpenseSet(saveExpenseSetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 家具を指定のエリアに追加する。
     * @summary 家具追加
     * @param {SaveFurnitureRequest} [saveFurnitureRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public saveFurniture(saveFurnitureRequest?: SaveFurnitureRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).saveFurniture(saveFurnitureRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 複数の家具を指定のエリアに一括で追加する。  NOTE: メニーオプションを持つ家具は現時点では未対応なのでバリデーションエラーとなる <a href=\"https://linear.app/swish-inc/issue/SW-24#comment-b67e4820\">関連コメント</a> 
     * @summary 家具一括追加
     * @param {SaveFurnituresRequest} [saveFurnituresRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public saveFurnitures(saveFurnituresRequest?: SaveFurnituresRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).saveFurnitures(saveFurnituresRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 家具一括品番検索
     * @param {SearchFurnituresByPartsNumbersRequest} [searchFurnituresByPartsNumbersRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public searchFurnituresByPartsNumbers(searchFurnituresByPartsNumbersRequest?: SearchFurnituresByPartsNumbersRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).searchFurnituresByPartsNumbers(searchFurnituresByPartsNumbersRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary パスワードリセットリンクの送信
     * @param {SendPasswordResetLinkRequest} [sendPasswordResetLinkRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public sendPasswordResetLink(sendPasswordResetLinkRequest?: SendPasswordResetLinkRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).sendPasswordResetLink(sendPasswordResetLinkRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 経費一括追加/更新/削除
     * @param {StoreQuotationExpensesRequest} [storeQuotationExpensesRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public storeQuotationExpenses(storeQuotationExpensesRequest?: StoreQuotationExpensesRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).storeQuotationExpenses(storeQuotationExpensesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * エリアを更新する 
     * @summary エリア更新
     * @param {string} areaId 
     * @param {UpdateAreaRequest} [updateAreaRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateArea(areaId: string, updateAreaRequest?: UpdateAreaRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateArea(areaId, updateAreaRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * エリア家具の備考欄メモを更新する。 
     * @summary エリア家具備考欄メモ更新
     * @param {number} areaFurnitureId エリア家具ID
     * @param {UpdateAreaFurnitureNotesRequest} [updateAreaFurnitureNotesRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateAreaFurnitureNotes(areaFurnitureId: number, updateAreaFurnitureNotesRequest?: UpdateAreaFurnitureNotesRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateAreaFurnitureNotes(areaFurnitureId, updateAreaFurnitureNotesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * エリアの名称を更新する 
     * @summary エリア名更新
     * @param {string} areaId 
     * @param {UpdateAreaNameRequest} [updateAreaNameRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateAreaName(areaId: string, updateAreaNameRequest?: UpdateAreaNameRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateAreaName(areaId, updateAreaNameRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * エリアの並び順を更新する 
     * @summary エリアの並び順を更新
     * @param {UpdateAreaSortOrderRequest} [updateAreaSortOrderRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateAreaSortOrder(updateAreaSortOrderRequest?: UpdateAreaSortOrderRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateAreaSortOrder(updateAreaSortOrderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 会社情報を更新する
     * @summary Company更新
     * @param {UpdateCompanyRequest} [updateCompanyRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateCompany(updateCompanyRequest?: UpdateCompanyRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateCompany(updateCompanyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * お気に入りセットを更新する。 
     * @summary お気に入りセット更新
     * @param {number} favoriteFurnitureSetId 
     * @param {CreateFavoriteFurnitureSetRequest} [createFavoriteFurnitureSetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateFavoriteFurnitureSet(favoriteFurnitureSetId: number, createFavoriteFurnitureSetRequest?: CreateFavoriteFurnitureSetRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateFavoriteFurnitureSet(favoriteFurnitureSetId, createFavoriteFurnitureSetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * エリアの家具を変更する。
     * @summary 家具更新
     * @param {string} areaId 
     * @param {number} areaFurnitureId 
     * @param {UpdateFurnitureRequest} [updateFurnitureRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateFurniture(areaId: string, areaFurnitureId: number, updateFurnitureRequest?: UpdateFurnitureRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateFurniture(areaId, areaFurnitureId, updateFurnitureRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 案件の更新
     * @summary 案件更新
     * @param {string} projectId 案件id
     * @param {UpdateProjectRequest} updateProjectRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateProject(projectId: string, updateProjectRequest: UpdateProjectRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateProject(projectId, updateProjectRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 見積詳細の基本情報を更新する。 
     * @summary 見積詳細基本情報更新
     * @param {string} quotationId 
     * @param {UpdateQuotationDetailsRequest} updateQuotationDetailsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateQuotationDetails(quotationId: string, updateQuotationDetailsRequest: UpdateQuotationDetailsRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateQuotationDetails(quotationId, updateQuotationDetailsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 見積名、図面を更新する。 
     * @summary 見積名・図面更新
     * @param {string} quotationId 
     * @param {UpdateQuotationPdfRequest} updateQuotationPdfRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateQuotationPdf(quotationId: string, updateQuotationPdfRequest: UpdateQuotationPdfRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateQuotationPdf(quotationId, updateQuotationPdfRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ApiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexDeleteOptionIndexEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;
export type ApiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexDeleteOptionIndexEnum = typeof ApiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexDeleteOptionIndexEnum[keyof typeof ApiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexDeleteOptionIndexEnum];
/**
 * @export
 */
export const ApiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexPostOptionIndexEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;
export type ApiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexPostOptionIndexEnum = typeof ApiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexPostOptionIndexEnum[keyof typeof ApiCustomFurnituresCustomFurnitureIdOptionImagesOptionIndexPostOptionIndexEnum];
/**
 * @export
 */
export const ApiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexDeleteOptionIndexEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;
export type ApiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexDeleteOptionIndexEnum = typeof ApiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexDeleteOptionIndexEnum[keyof typeof ApiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexDeleteOptionIndexEnum];
/**
 * @export
 */
export const ApiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexPostOptionIndexEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;
export type ApiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexPostOptionIndexEnum = typeof ApiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexPostOptionIndexEnum[keyof typeof ApiFurnitureAreaIdUpdateAreaFurnitureIdOptionImagesOptionIndexPostOptionIndexEnum];


/**
 * DBApi - axios parameter creator
 * @export
 */
export const DBApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary カテゴリー取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiFurnitureCategoryGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/api/furnitureCategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 家具オプションの更新を行う
         * @summary 家具オプションの更新
         * @param {AdminApiFurnitureOptionCreatePostRequest} [adminApiFurnitureOptionCreatePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiFurnitureOptionCreatePost: async (adminApiFurnitureOptionCreatePostRequest?: AdminApiFurnitureOptionCreatePostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/api/furnitureOption/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminApiFurnitureOptionCreatePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * furniture_seriesの画像アップロードを行う
         * @summary furniture_series画像アップロード
         * @param {number} furnitureSeriesId 
         * @param {File} imageFile 画像ファイル。形式は&#x60;jpg&#x60;, &#x60;jpeg&#x60;, &#x60;png&#x60;, &#x60;gif&#x60;, &#x60;webp&#x60;のいずれか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiFurnitureSeriesFurnitureSeriesIdImagePost: async (furnitureSeriesId: number, imageFile: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'furnitureSeriesId' is not null or undefined
            assertParamExists('adminApiFurnitureSeriesFurnitureSeriesIdImagePost', 'furnitureSeriesId', furnitureSeriesId)
            // verify required parameter 'imageFile' is not null or undefined
            assertParamExists('adminApiFurnitureSeriesFurnitureSeriesIdImagePost', 'imageFile', imageFile)
            const localVarPath = `/admin/api/furnitureSeries/{furniture_series_id}/image`
                .replace(`{${"furniture_series_id"}}`, encodeURIComponent(String(furnitureSeriesId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication AdminBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (imageFile !== undefined) { 
                localVarFormParams.append('image_file', imageFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * furniture_seriesの情報を更新する
         * @summary furniture_series情報更新
         * @param {number} furnitureSeriesId 
         * @param {AdminApiFurnitureSeriesFurnitureSeriesIdPatchRequest} [adminApiFurnitureSeriesFurnitureSeriesIdPatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiFurnitureSeriesFurnitureSeriesIdPatch: async (furnitureSeriesId: number, adminApiFurnitureSeriesFurnitureSeriesIdPatchRequest?: AdminApiFurnitureSeriesFurnitureSeriesIdPatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'furnitureSeriesId' is not null or undefined
            assertParamExists('adminApiFurnitureSeriesFurnitureSeriesIdPatch', 'furnitureSeriesId', furnitureSeriesId)
            const localVarPath = `/admin/api/furnitureSeries/{furniture_series_id}`
                .replace(`{${"furniture_series_id"}}`, encodeURIComponent(String(furnitureSeriesId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminApiFurnitureSeriesFurnitureSeriesIdPatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * furniture_seriesの一覧を取得する
         * @summary furniture_series一覧取得
         * @param {number} [id] 家具シリーズID
         * @param {number} [manufactureId] メーカーID
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの表示数
         * @param {string} [productSeriesName] 家具シリーズ名
         * @param {AdminApiFurnitureSeriesGetSortTargetEnum} [sortTarget] ソート対象
         * @param {AdminApiFurnitureSeriesGetSortTypeEnum} [sortType] ソート順
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiFurnitureSeriesGet: async (id?: number, manufactureId?: number, page?: number, perPage?: number, productSeriesName?: string, sortTarget?: AdminApiFurnitureSeriesGetSortTargetEnum, sortType?: AdminApiFurnitureSeriesGetSortTypeEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/api/furnitureSeries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (manufactureId !== undefined) {
                localVarQueryParameter['manufactureId'] = manufactureId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (productSeriesName !== undefined) {
                localVarQueryParameter['productSeriesName'] = productSeriesName;
            }

            if (sortTarget !== undefined) {
                localVarQueryParameter['sortTarget'] = sortTarget;
            }

            if (sortType !== undefined) {
                localVarQueryParameter['sortType'] = sortType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary メーカー一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiManufacturesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/api/manufactures`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザーExcelインポート
         * @param {File} file Excelファイル
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiUserExcelImportPost: async (file: File, organizationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('adminApiUserExcelImportPost', 'file', file)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('adminApiUserExcelImportPost', 'organizationId', organizationId)
            const localVarPath = `/admin/api/user/excel/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication AdminBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (organizationId !== undefined) { 
                localVarFormParams.append('organization_id', organizationId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織作成
         * @param {CreateOrganizationRequest} createOrganizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganization: async (createOrganizationRequest: CreateOrganizationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrganizationRequest' is not null or undefined
            assertParamExists('createOrganization', 'createOrganizationRequest', createOrganizationRequest)
            const localVarPath = `/admin/api/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrganizationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザー作成
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (createUserRequest: CreateUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserRequest' is not null or undefined
            assertParamExists('createUser', 'createUserRequest', createUserRequest)
            const localVarPath = `/admin/api/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織削除
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganization: async (organizationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('deleteOrganization', 'organizationId', organizationId)
            const localVarPath = `/admin/api/organizations/{organizationId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザー削除
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUser', 'userId', userId)
            const localVarPath = `/admin/api/user/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 家具オプションの一覧を取得する
         * @summary 家具オプション一覧取得
         * @param {number} [furnitureSeriesId] 
         * @param {number} [pageSize] 
         * @param {string} [sortTarget] 
         * @param {string} [sortType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFurnitureOptions: async (furnitureSeriesId?: number, pageSize?: number, sortTarget?: string, sortType?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/api/furnitureOption`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (furnitureSeriesId !== undefined) {
                localVarQueryParameter['furnitureSeriesId'] = furnitureSeriesId;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortTarget !== undefined) {
                localVarQueryParameter['sortTarget'] = sortTarget;
            }

            if (sortType !== undefined) {
                localVarQueryParameter['sortType'] = sortType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/api/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザー一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/api/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定のシリーズに対象となる未割当のfurnitureを一括割当します。
         * @summary シリーズ未割当のfurnitureにシリーズ割当
         * @param {number} furnitureSeriesId furnitureに割り当てるfurniture_series_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkFurnituresToSeries: async (furnitureSeriesId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'furnitureSeriesId' is not null or undefined
            assertParamExists('linkFurnituresToSeries', 'furnitureSeriesId', furnitureSeriesId)
            const localVarPath = `/admin/api/furnitureSeries/{furniture_series_id}/linkFurnitures`
                .replace(`{${"furniture_series_id"}}`, encodeURIComponent(String(furnitureSeriesId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織更新
         * @param {number} organizationId 
         * @param {CreateOrganizationRequest} createOrganizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganization: async (organizationId: number, createOrganizationRequest: CreateOrganizationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('updateOrganization', 'organizationId', organizationId)
            // verify required parameter 'createOrganizationRequest' is not null or undefined
            assertParamExists('updateOrganization', 'createOrganizationRequest', createOrganizationRequest)
            const localVarPath = `/admin/api/organizations/{organizationId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrganizationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザー更新
         * @param {string} userId 
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (userId: string, updateUserRequest: UpdateUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUser', 'userId', userId)
            // verify required parameter 'updateUserRequest' is not null or undefined
            assertParamExists('updateUser', 'updateUserRequest', updateUserRequest)
            const localVarPath = `/admin/api/user/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DBApi - functional programming interface
 * @export
 */
export const DBApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DBApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary カテゴリー取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminApiFurnitureCategoryGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminApiFurnitureCategoryGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminApiFurnitureCategoryGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DBApi.adminApiFurnitureCategoryGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 家具オプションの更新を行う
         * @summary 家具オプションの更新
         * @param {AdminApiFurnitureOptionCreatePostRequest} [adminApiFurnitureOptionCreatePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminApiFurnitureOptionCreatePost(adminApiFurnitureOptionCreatePostRequest?: AdminApiFurnitureOptionCreatePostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminApiFurnitureOptionCreatePost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminApiFurnitureOptionCreatePost(adminApiFurnitureOptionCreatePostRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DBApi.adminApiFurnitureOptionCreatePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * furniture_seriesの画像アップロードを行う
         * @summary furniture_series画像アップロード
         * @param {number} furnitureSeriesId 
         * @param {File} imageFile 画像ファイル。形式は&#x60;jpg&#x60;, &#x60;jpeg&#x60;, &#x60;png&#x60;, &#x60;gif&#x60;, &#x60;webp&#x60;のいずれか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminApiFurnitureSeriesFurnitureSeriesIdImagePost(furnitureSeriesId: number, imageFile: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminApiFurnitureSeriesFurnitureSeriesIdImagePost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminApiFurnitureSeriesFurnitureSeriesIdImagePost(furnitureSeriesId, imageFile, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DBApi.adminApiFurnitureSeriesFurnitureSeriesIdImagePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * furniture_seriesの情報を更新する
         * @summary furniture_series情報更新
         * @param {number} furnitureSeriesId 
         * @param {AdminApiFurnitureSeriesFurnitureSeriesIdPatchRequest} [adminApiFurnitureSeriesFurnitureSeriesIdPatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminApiFurnitureSeriesFurnitureSeriesIdPatch(furnitureSeriesId: number, adminApiFurnitureSeriesFurnitureSeriesIdPatchRequest?: AdminApiFurnitureSeriesFurnitureSeriesIdPatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminApiFurnitureSeriesFurnitureSeriesIdPatch200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminApiFurnitureSeriesFurnitureSeriesIdPatch(furnitureSeriesId, adminApiFurnitureSeriesFurnitureSeriesIdPatchRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DBApi.adminApiFurnitureSeriesFurnitureSeriesIdPatch']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * furniture_seriesの一覧を取得する
         * @summary furniture_series一覧取得
         * @param {number} [id] 家具シリーズID
         * @param {number} [manufactureId] メーカーID
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの表示数
         * @param {string} [productSeriesName] 家具シリーズ名
         * @param {AdminApiFurnitureSeriesGetSortTargetEnum} [sortTarget] ソート対象
         * @param {AdminApiFurnitureSeriesGetSortTypeEnum} [sortType] ソート順
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminApiFurnitureSeriesGet(id?: number, manufactureId?: number, page?: number, perPage?: number, productSeriesName?: string, sortTarget?: AdminApiFurnitureSeriesGetSortTargetEnum, sortType?: AdminApiFurnitureSeriesGetSortTypeEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminApiFurnitureSeriesGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminApiFurnitureSeriesGet(id, manufactureId, page, perPage, productSeriesName, sortTarget, sortType, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DBApi.adminApiFurnitureSeriesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary メーカー一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminApiManufacturesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOrganizationManufactures200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminApiManufacturesGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DBApi.adminApiManufacturesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary ユーザーExcelインポート
         * @param {File} file Excelファイル
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminApiUserExcelImportPost(file: File, organizationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminApiUserExcelImportPost(file, organizationId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DBApi.adminApiUserExcelImportPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary 組織作成
         * @param {CreateOrganizationRequest} createOrganizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrganization(createOrganizationRequest: CreateOrganizationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOrganization200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrganization(createOrganizationRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DBApi.createOrganization']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary ユーザー作成
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(createUserRequest: CreateUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(createUserRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DBApi.createUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary 組織削除
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOrganization(organizationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOrganization(organizationId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DBApi.deleteOrganization']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary ユーザー削除
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(userId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DBApi.deleteUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 家具オプションの一覧を取得する
         * @summary 家具オプション一覧取得
         * @param {number} [furnitureSeriesId] 
         * @param {number} [pageSize] 
         * @param {string} [sortTarget] 
         * @param {string} [sortType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFurnitureOptions(furnitureSeriesId?: number, pageSize?: number, sortTarget?: string, sortType?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFurnitureOptions200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFurnitureOptions(furnitureSeriesId, pageSize, sortTarget, sortType, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DBApi.getFurnitureOptions']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary 組織一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizations(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOrganizations200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizations(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DBApi.getOrganizations']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary ユーザー一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUsers200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DBApi.getUsers']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 指定のシリーズに対象となる未割当のfurnitureを一括割当します。
         * @summary シリーズ未割当のfurnitureにシリーズ割当
         * @param {number} furnitureSeriesId furnitureに割り当てるfurniture_series_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async linkFurnituresToSeries(furnitureSeriesId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkFurnituresToSeries200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.linkFurnituresToSeries(furnitureSeriesId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DBApi.linkFurnituresToSeries']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary 組織更新
         * @param {number} organizationId 
         * @param {CreateOrganizationRequest} createOrganizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrganization(organizationId: number, createOrganizationRequest: CreateOrganizationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOrganization200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrganization(organizationId, createOrganizationRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DBApi.updateOrganization']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary ユーザー更新
         * @param {string} userId 
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(userId: string, updateUserRequest: UpdateUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(userId, updateUserRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DBApi.updateUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DBApi - factory interface
 * @export
 */
export const DBApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DBApiFp(configuration)
    return {
        /**
         * 
         * @summary カテゴリー取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiFurnitureCategoryGet(options?: any): AxiosPromise<AdminApiFurnitureCategoryGet200Response> {
            return localVarFp.adminApiFurnitureCategoryGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 家具オプションの更新を行う
         * @summary 家具オプションの更新
         * @param {AdminApiFurnitureOptionCreatePostRequest} [adminApiFurnitureOptionCreatePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiFurnitureOptionCreatePost(adminApiFurnitureOptionCreatePostRequest?: AdminApiFurnitureOptionCreatePostRequest, options?: any): AxiosPromise<AdminApiFurnitureOptionCreatePost200Response> {
            return localVarFp.adminApiFurnitureOptionCreatePost(adminApiFurnitureOptionCreatePostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * furniture_seriesの画像アップロードを行う
         * @summary furniture_series画像アップロード
         * @param {number} furnitureSeriesId 
         * @param {File} imageFile 画像ファイル。形式は&#x60;jpg&#x60;, &#x60;jpeg&#x60;, &#x60;png&#x60;, &#x60;gif&#x60;, &#x60;webp&#x60;のいずれか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiFurnitureSeriesFurnitureSeriesIdImagePost(furnitureSeriesId: number, imageFile: File, options?: any): AxiosPromise<AdminApiFurnitureSeriesFurnitureSeriesIdImagePost200Response> {
            return localVarFp.adminApiFurnitureSeriesFurnitureSeriesIdImagePost(furnitureSeriesId, imageFile, options).then((request) => request(axios, basePath));
        },
        /**
         * furniture_seriesの情報を更新する
         * @summary furniture_series情報更新
         * @param {number} furnitureSeriesId 
         * @param {AdminApiFurnitureSeriesFurnitureSeriesIdPatchRequest} [adminApiFurnitureSeriesFurnitureSeriesIdPatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiFurnitureSeriesFurnitureSeriesIdPatch(furnitureSeriesId: number, adminApiFurnitureSeriesFurnitureSeriesIdPatchRequest?: AdminApiFurnitureSeriesFurnitureSeriesIdPatchRequest, options?: any): AxiosPromise<AdminApiFurnitureSeriesFurnitureSeriesIdPatch200Response> {
            return localVarFp.adminApiFurnitureSeriesFurnitureSeriesIdPatch(furnitureSeriesId, adminApiFurnitureSeriesFurnitureSeriesIdPatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * furniture_seriesの一覧を取得する
         * @summary furniture_series一覧取得
         * @param {number} [id] 家具シリーズID
         * @param {number} [manufactureId] メーカーID
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの表示数
         * @param {string} [productSeriesName] 家具シリーズ名
         * @param {AdminApiFurnitureSeriesGetSortTargetEnum} [sortTarget] ソート対象
         * @param {AdminApiFurnitureSeriesGetSortTypeEnum} [sortType] ソート順
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiFurnitureSeriesGet(id?: number, manufactureId?: number, page?: number, perPage?: number, productSeriesName?: string, sortTarget?: AdminApiFurnitureSeriesGetSortTargetEnum, sortType?: AdminApiFurnitureSeriesGetSortTypeEnum, options?: any): AxiosPromise<AdminApiFurnitureSeriesGet200Response> {
            return localVarFp.adminApiFurnitureSeriesGet(id, manufactureId, page, perPage, productSeriesName, sortTarget, sortType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary メーカー一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiManufacturesGet(options?: any): AxiosPromise<GetOrganizationManufactures200Response> {
            return localVarFp.adminApiManufacturesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザーExcelインポート
         * @param {File} file Excelファイル
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiUserExcelImportPost(file: File, organizationId: number, options?: any): AxiosPromise<void> {
            return localVarFp.adminApiUserExcelImportPost(file, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織作成
         * @param {CreateOrganizationRequest} createOrganizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganization(createOrganizationRequest: CreateOrganizationRequest, options?: any): AxiosPromise<CreateOrganization200Response> {
            return localVarFp.createOrganization(createOrganizationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザー作成
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(createUserRequest: CreateUserRequest, options?: any): AxiosPromise<User> {
            return localVarFp.createUser(createUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織削除
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganization(organizationId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOrganization(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザー削除
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 家具オプションの一覧を取得する
         * @summary 家具オプション一覧取得
         * @param {number} [furnitureSeriesId] 
         * @param {number} [pageSize] 
         * @param {string} [sortTarget] 
         * @param {string} [sortType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFurnitureOptions(furnitureSeriesId?: number, pageSize?: number, sortTarget?: string, sortType?: string, options?: any): AxiosPromise<GetFurnitureOptions200Response> {
            return localVarFp.getFurnitureOptions(furnitureSeriesId, pageSize, sortTarget, sortType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizations(options?: any): AxiosPromise<GetOrganizations200Response> {
            return localVarFp.getOrganizations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザー一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(options?: any): AxiosPromise<GetUsers200Response> {
            return localVarFp.getUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 指定のシリーズに対象となる未割当のfurnitureを一括割当します。
         * @summary シリーズ未割当のfurnitureにシリーズ割当
         * @param {number} furnitureSeriesId furnitureに割り当てるfurniture_series_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkFurnituresToSeries(furnitureSeriesId: number, options?: any): AxiosPromise<LinkFurnituresToSeries200Response> {
            return localVarFp.linkFurnituresToSeries(furnitureSeriesId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織更新
         * @param {number} organizationId 
         * @param {CreateOrganizationRequest} createOrganizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganization(organizationId: number, createOrganizationRequest: CreateOrganizationRequest, options?: any): AxiosPromise<CreateOrganization200Response> {
            return localVarFp.updateOrganization(organizationId, createOrganizationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザー更新
         * @param {string} userId 
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(userId: string, updateUserRequest: UpdateUserRequest, options?: any): AxiosPromise<User> {
            return localVarFp.updateUser(userId, updateUserRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DBApi - object-oriented interface
 * @export
 * @class DBApi
 * @extends {BaseAPI}
 */
export class DBApi extends BaseAPI {
    /**
     * 
     * @summary カテゴリー取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DBApi
     */
    public adminApiFurnitureCategoryGet(options?: AxiosRequestConfig) {
        return DBApiFp(this.configuration).adminApiFurnitureCategoryGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 家具オプションの更新を行う
     * @summary 家具オプションの更新
     * @param {AdminApiFurnitureOptionCreatePostRequest} [adminApiFurnitureOptionCreatePostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DBApi
     */
    public adminApiFurnitureOptionCreatePost(adminApiFurnitureOptionCreatePostRequest?: AdminApiFurnitureOptionCreatePostRequest, options?: AxiosRequestConfig) {
        return DBApiFp(this.configuration).adminApiFurnitureOptionCreatePost(adminApiFurnitureOptionCreatePostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * furniture_seriesの画像アップロードを行う
     * @summary furniture_series画像アップロード
     * @param {number} furnitureSeriesId 
     * @param {File} imageFile 画像ファイル。形式は&#x60;jpg&#x60;, &#x60;jpeg&#x60;, &#x60;png&#x60;, &#x60;gif&#x60;, &#x60;webp&#x60;のいずれか
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DBApi
     */
    public adminApiFurnitureSeriesFurnitureSeriesIdImagePost(furnitureSeriesId: number, imageFile: File, options?: AxiosRequestConfig) {
        return DBApiFp(this.configuration).adminApiFurnitureSeriesFurnitureSeriesIdImagePost(furnitureSeriesId, imageFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * furniture_seriesの情報を更新する
     * @summary furniture_series情報更新
     * @param {number} furnitureSeriesId 
     * @param {AdminApiFurnitureSeriesFurnitureSeriesIdPatchRequest} [adminApiFurnitureSeriesFurnitureSeriesIdPatchRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DBApi
     */
    public adminApiFurnitureSeriesFurnitureSeriesIdPatch(furnitureSeriesId: number, adminApiFurnitureSeriesFurnitureSeriesIdPatchRequest?: AdminApiFurnitureSeriesFurnitureSeriesIdPatchRequest, options?: AxiosRequestConfig) {
        return DBApiFp(this.configuration).adminApiFurnitureSeriesFurnitureSeriesIdPatch(furnitureSeriesId, adminApiFurnitureSeriesFurnitureSeriesIdPatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * furniture_seriesの一覧を取得する
     * @summary furniture_series一覧取得
     * @param {number} [id] 家具シリーズID
     * @param {number} [manufactureId] メーカーID
     * @param {number} [page] ページ番号
     * @param {number} [perPage] 1ページあたりの表示数
     * @param {string} [productSeriesName] 家具シリーズ名
     * @param {AdminApiFurnitureSeriesGetSortTargetEnum} [sortTarget] ソート対象
     * @param {AdminApiFurnitureSeriesGetSortTypeEnum} [sortType] ソート順
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DBApi
     */
    public adminApiFurnitureSeriesGet(id?: number, manufactureId?: number, page?: number, perPage?: number, productSeriesName?: string, sortTarget?: AdminApiFurnitureSeriesGetSortTargetEnum, sortType?: AdminApiFurnitureSeriesGetSortTypeEnum, options?: AxiosRequestConfig) {
        return DBApiFp(this.configuration).adminApiFurnitureSeriesGet(id, manufactureId, page, perPage, productSeriesName, sortTarget, sortType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary メーカー一覧取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DBApi
     */
    public adminApiManufacturesGet(options?: AxiosRequestConfig) {
        return DBApiFp(this.configuration).adminApiManufacturesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザーExcelインポート
     * @param {File} file Excelファイル
     * @param {number} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DBApi
     */
    public adminApiUserExcelImportPost(file: File, organizationId: number, options?: AxiosRequestConfig) {
        return DBApiFp(this.configuration).adminApiUserExcelImportPost(file, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織作成
     * @param {CreateOrganizationRequest} createOrganizationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DBApi
     */
    public createOrganization(createOrganizationRequest: CreateOrganizationRequest, options?: AxiosRequestConfig) {
        return DBApiFp(this.configuration).createOrganization(createOrganizationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザー作成
     * @param {CreateUserRequest} createUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DBApi
     */
    public createUser(createUserRequest: CreateUserRequest, options?: AxiosRequestConfig) {
        return DBApiFp(this.configuration).createUser(createUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織削除
     * @param {number} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DBApi
     */
    public deleteOrganization(organizationId: number, options?: AxiosRequestConfig) {
        return DBApiFp(this.configuration).deleteOrganization(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザー削除
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DBApi
     */
    public deleteUser(userId: string, options?: AxiosRequestConfig) {
        return DBApiFp(this.configuration).deleteUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 家具オプションの一覧を取得する
     * @summary 家具オプション一覧取得
     * @param {number} [furnitureSeriesId] 
     * @param {number} [pageSize] 
     * @param {string} [sortTarget] 
     * @param {string} [sortType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DBApi
     */
    public getFurnitureOptions(furnitureSeriesId?: number, pageSize?: number, sortTarget?: string, sortType?: string, options?: AxiosRequestConfig) {
        return DBApiFp(this.configuration).getFurnitureOptions(furnitureSeriesId, pageSize, sortTarget, sortType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織一覧取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DBApi
     */
    public getOrganizations(options?: AxiosRequestConfig) {
        return DBApiFp(this.configuration).getOrganizations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザー一覧取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DBApi
     */
    public getUsers(options?: AxiosRequestConfig) {
        return DBApiFp(this.configuration).getUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定のシリーズに対象となる未割当のfurnitureを一括割当します。
     * @summary シリーズ未割当のfurnitureにシリーズ割当
     * @param {number} furnitureSeriesId furnitureに割り当てるfurniture_series_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DBApi
     */
    public linkFurnituresToSeries(furnitureSeriesId: number, options?: AxiosRequestConfig) {
        return DBApiFp(this.configuration).linkFurnituresToSeries(furnitureSeriesId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織更新
     * @param {number} organizationId 
     * @param {CreateOrganizationRequest} createOrganizationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DBApi
     */
    public updateOrganization(organizationId: number, createOrganizationRequest: CreateOrganizationRequest, options?: AxiosRequestConfig) {
        return DBApiFp(this.configuration).updateOrganization(organizationId, createOrganizationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザー更新
     * @param {string} userId 
     * @param {UpdateUserRequest} updateUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DBApi
     */
    public updateUser(userId: string, updateUserRequest: UpdateUserRequest, options?: AxiosRequestConfig) {
        return DBApiFp(this.configuration).updateUser(userId, updateUserRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const AdminApiFurnitureSeriesGetSortTargetEnum = {
    Id: 'id',
    CreatedAt: 'created_at'
} as const;
export type AdminApiFurnitureSeriesGetSortTargetEnum = typeof AdminApiFurnitureSeriesGetSortTargetEnum[keyof typeof AdminApiFurnitureSeriesGetSortTargetEnum];
/**
 * @export
 */
export const AdminApiFurnitureSeriesGetSortTypeEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type AdminApiFurnitureSeriesGetSortTypeEnum = typeof AdminApiFurnitureSeriesGetSortTypeEnum[keyof typeof AdminApiFurnitureSeriesGetSortTypeEnum];


