var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":""};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";
import getSentryEnvName from "@/getSentryEnvName.js";

const ENV = process.env.NEXT_PUBLIC_APP_ENV;

const replaysOnErrorSampleRate = parseFloat(process.env.NEXT_PUBLIC_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE) || 0;

Sentry.init({
  enabled: process.env.NEXT_PUBLIC_SENTRY_ENABLED === "true",
  environment: getSentryEnvName(ENV),
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  release: process.env.NEXT_PUBLIC_SENTRY_RELEASE,
  enableTracing: process.env.NEXT_PUBLIC_SENTRY_ENABLE_TRACING === "true",
  tracesSampleRate: parseFloat(process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE) || 0,
  replaysOnErrorSampleRate,
  replaysSessionSampleRate: 0,
  integrations: replaysOnErrorSampleRate > 0 ? [Sentry.replayIntegration()] : [],
});
