import { useAuth } from "@/hooks/auth";
import { useCompany } from "@/hooks/useCompany";
import { useUpdateEffect } from "react-use";
import { useEffect } from "react";
import { Fullstar } from "@/@types/fullstar";
import { captureException } from "@sentry/nextjs";

const MAX_WAIT_TIME_MS = 1000;

const ensureFullstarLoad = async () => {
  const startTime = Date.now();

  return await new Promise<Fullstar>((resolve, reject) => {
    const id = setInterval(() => {
      if (window.fs) {
        clearInterval(id);
        resolve(window.fs);
        return;
      }
      // 最大タイムアウトを超えたらrejectする
      if (Date.now() - startTime > MAX_WAIT_TIME_MS) {
        clearInterval(id);
        reject(new Error("fullstar loading timeout"));
      }
    }, 500);
  });
};

export const useFullstar = () => {
  const { user } = useAuth();
  const { company, mutateCompany } = useCompany(user != null);

  // ユーザー情報が取得できたら会社情報を取得
  useEffect(() => {
    if (user?.data.id == null) return;
    mutateCompany();
  }, [mutateCompany, user?.data.id]);

  useUpdateEffect(() => {
    if (!user || !company) return;

    ensureFullstarLoad()
      .then((fs) => {
        fs.clear();
        fs.show(process.env.NEXT_PUBLIC_FULLSTAR_ID, user.data.id, {
          company_name: company.company_name,
          user_name: user.data.display_name,
          user_email: user.data.email,
          plan_name: "",
          tags: "",
        });
        fs.tracking();
        fs.survey();
        fs.guide();
        fs.checklist();
        fs.tooltip();
      })
      .catch((e) => captureException(e, { level: "warning" }));
  }, [company?.id, user?.data.id]);
};
